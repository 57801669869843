<template>
  <div class="bo--container">
    <template>
      <div class="container">
        <loader :loader="loading"/>
        <div>
          <bo-card>
            <template slot="title">
              <h1 class="bo-card--title" v-if="canUserCreateStructure" >
                <a href="#" @click.prevent="cancel">&lt; </a> {{isEdit ? 'Modifier la structure' : "Créer une structure"}}
              </h1>
              <h1 v-else class="bo-card--title">
                <a href="#" @click.prevent="cancel">&lt; </a> Voir la structure
              </h1>
            </template>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <form-input v-model="erpId" label="Code Apologic *"
                            :error="$v.erpId.$error ? 'error' : null"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager">
                  <template v-slot:error v-if="$v.erpId.$error">
                    <small v-if="!$v.erpId.required && $v.erpId.$error" class="input--error">le code Apologic est obligatoire.</small>
                    <small v-if="!$v.erpId.integer && $v.erpId.$error" class="input--error">le code Apologic est une valeur numérique.</small>
                  </template>
                </form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <form-input v-model="name"
                            label="Nom de la structure *"
                            :error="!$v.name.required && $v.name.$error ? 'Le nom est obligatoire' : null"
                            :login-input="true"
                            :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-12 col-md-6">
                  <form-input v-model="address"
                              label="Adresse *"
                              :error="!$v.address.required && $v.address.$error ? 'L\'adresse est obligatoire.' : null"
                              :login-input="true"
                              :disabled="!isUserRoleOperationManager"/>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <form-toggle append="Actif" prepend="Inactif" v-model="status" name="toggle" :disabled="!isUserRoleOperationManager"/>
              </div>
              <div class="col-sm-12 col-md-6" v-if="isEdit">
                  <document-simple
                      title="Logo"
                      v-model="logo"
                      :imageUrl="structure.logo ? logoPath + structure.logo + '?' + Date.now(): ''"
                      mention="50px*50px min"
                      :isLogo="true"
                      @input="uploadLogo"/>
                  <p class="text-danger" v-if="!logo && isSubmitLogo">* La logo est obligatoire</p>
              </div>
            </div>
            <template slot="footer">
              <button v-if="isUserRoleOperationManager" class="btn btn-primary" @click.prevent="submit" :disabled="saveLoading"><font-awesome-icon v-show="saveLoading" :icon="['fas', 'spinner']" spin/> Enregistrer</button>
              <button class="btn btn-outline-primary" @click.prevent="cancel">Annuler</button>
            </template>
          </bo-card>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

import {mapState, mapActions, mapMutations, mapGetters} from "vuex";
import DocumentSimple from "@/components/Form/DocumentSimple";
import { required } from 'vuelidate/lib/validators'
import {API_URL} from "@/components/Util/EnvVariable";

export default {
  name: "BOStructureForm",
  components: {
    DocumentSimple
  },
  data() {
    return {
      logo: null,
      isSubmitLogo: false,
      bo: {
        active: true
      },
      saveLoading: false
    };
  },
  validations() {
    let dataReturn = {
      address: {
        required
      },
      name: {
        required
      },
      erpId: {
        required
      }
    };
    return dataReturn;
  },
  computed: {
    ...mapState('Structure', ['structure', 'error', 'loading']),
    ...mapState('User', ['connectedUser']),
    ...mapGetters('User', ['isUserRoleOperationManager']),
    erpId:{
      get(){
        return this.structure.erpId;
      },
      set(value){
        this.setStructureProperty({
          name: "erpId",
          value: value
        });
      }
    },
    name:{
      get(){
        return this.structure.name;
      },
      set(value){
        this.setStructureProperty({
          name: "name",
          value: value
        });
      }
    },
    address:{
      get(){
        return this.structure.address;
      },
      set(value){
        this.setStructureProperty({
          name: "address",
          value: value
        });
      }
    },
    status:{
      get(){
        return this.structure.status;
      },
      set(value){
        this.setStructureProperty({
          name: "status",
          value: value
        });
      }
    },
    isEdit() {
      return (this.$route.params.id !== undefined && this.$route.params.id !== null)
    },
    logoPath() {
      return API_URL + '/structure_logos/'
    },
    canUserCreateStructure: {
      get() {
        let storedPermissions = sessionStorage.getItem('permissions');
        let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

        return permissionsArray.includes('CAN_MANAGE_STRUCTURES');
      }
        },
  },
  mounted: async function() {
    this.setInitStructure();
    if (this.isEdit) {
      this.getStructure({ id: this.$route.params.id })
        .catch((err) => {
          if (err) {
            this.$notify.error("Impossible d'accéder à cette  structure")
            this.$router.push({name: 'structure-list'});
          }
      })
    }
  },
  methods: {
    ...mapMutations('Structure',['setStructureProperty','setInitStructure']),
    ...mapActions('Structure', ['postStructure', 'putStructure', 'getStructure', 'postStructureLogo']),
    async submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return; 
      }
      this.$confirm('Êtes-vous sur de vouloir réaliser une modification ? Les données modifiées dans DANEAD doivent être modifiées dans le logiciel métier !').then(() => {
        this.afterConfirm();
      }).catch(() => {
      });
    },
    afterConfirm(){
      this.saveLoading = true;
      if (this.isEdit) {
        this.putStructure().then(() => {
          this.$notify.success('La structure a été modifiée');
          this.setInitStructure();
          this.saveLoading = false;
          this.$router.push({name: 'structure-list'});
        }).catch(() => {
          this.saveLoading = false;
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        })
      } else {
        this.postStructure().then(() => {
          this.$notify.success('La structure a été créée');
          this.setInitStructure();
          this.saveLoading = false;
          this.$router.push({name: 'structure-list'});
        }).catch(() => {
          this.saveLoading = false;
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        })
      }
    },
    cancel() {
      this.setInitStructure();
      return this.$router.push({name:'structure-list'})
    },
    uploadLogo() {
      let formData = new FormData();
      formData.append('logo', this.logo);
      this.setStructureProperty({name: "logo", value: formData});
      this.postStructureLogo();
    }
  }
};
</script>