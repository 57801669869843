import { render, staticRenderFns } from "./ResultInfo.vue?vue&type=template&id=0fb3e656&scoped=true"
import script from "./ResultInfo.vue?vue&type=script&lang=js"
export * from "./ResultInfo.vue?vue&type=script&lang=js"
import style0 from "./ResultInfo.vue?vue&type=style&index=0&id=0fb3e656&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fb3e656",
  null
  
)

export default component.exports