import {API_URL} from "@/components/Util/EnvVariable";

export default {
    setCity(state, data){
        state.city = data;
    },
    setCodeInsee(state, data) {
        state.client.codeInsee = data;
    },
    setContactProperty(state, property) {
        if(!state.contact[property.type][property.name]){
            state.contact[property.type][property.name] = "";
        }
        state.contact[property.type][property.name] = property.value;
    },
    setInitContact(state) {
        state.contact = {
            family: {
                lastName: '',
                firstName: '',
                titre: '',
                typeErp: '',
                phone: '',
                address: '',
                city: '',
                postalCode: '',
                type: 'family',
                isReferent: false
            },
            medical: {
                lastName: '',
                firstName: '',
                titre: '',
                typeErp: '',
                phone: '',
                address: '',
                city: '',
                postalCode: '',
                type: 'med',
                isReferent: false
            },
            other: {
                lastName: '',
                firstName: '',
                titre: '',
                typeErp: '',
                phone: '',
                address: '',
                city: '',
                postalCode: '',
                type: 'other',
                isReferent: false
            }
        }
    },
    setClientProperty(state, property) {
        if(!state.client[property.name]){
            state.client[property.name] = "";
        }
        state.client[property.name] = property.value;
    },
    setClientLoading(state) {
        state.loading = true;
        state.error = null;
    },
    setClientSaveLoading(state) {
        state.error = null;
    },
    setClientSearch(state, clientSearch) {
        state.clientSearch = clientSearch
        state.loading = false;
        state.error = null;
    },
    setClientList(state, clients) {
        state.clients = clients
        state.loading = false;
        state.error = null;
    },
    setClientInterventionSuccess(state, interventions) {
        state.clientInterventions = interventions
        state.loading = false;
        state.error = null;
    },
    setTotalClientList(state, total) {
        state.total = total;
    },
    setClientSuccess(state, client) {

        const contactList = [...client.contactList, ...client.familyContact, ...client.medicalContact];
        state.client = {
            ...client,
            placePicture: client.placePicture ? API_URL + client.placePicture : '',
            structureId: client.structure ? client.structure.id : null,
            agencyId: client.agency ? client.agency.id : null,
            contactList: contactList.reduce((acc, n) => {return [...acc, {
                ...n,
                isReferent: client.referent ? n.id === client.referent.id : false
            }]},[])
        };
        state.loading = false;
        state.error = null;
    },
    setInitClient(state) {
        state.client = {
            photo: null,
            structure: {},
            structureId: '',
            agency: {},
            agencyId: '',
            statusSync: '',
            folderNumber: '',
            lastname: '',
            firstname: '',
            birthName: '',
            birthCity: '',
            birthCountry: '',
            email: '',
            sex: '',
            nationality: '',
            address: '',
            city: '',
            postalCode: '',
            sector: '',
            housingAccess: '',
            birthDate: '',
            age: null,
            socialSecurityNumber: '',
            digicode: '',
            sitePicture: '',
            homePhoneNumber: '',
            portablePhoneNumber: '',
            girNumber: '',
            missing: '',
            familyStatus: '',
            csp: '',
            protectiveMeasure: '',
            additionalInformations: '',
            keyPosition: '',
            familyModuleActive: false,
            qrCode: '',
            status: true,
            contactList: [],
            familyContact: [],
            medicalContact: [],
            $interfaceSyncrhonisation: false
        };
        state.error = null;
      },
    setClientError(state, error) {
        state.loading = false;
        state.error = error;
    },
    setPostClientSuccess(state, client) {
        state.client = client;
        state.clients.push(client);
        state.error = null;
        state.total = state.total + 1
    },
    setPutClientSuccess(state, client) {
        const contactList = client.contactList;
        const familyContact = client.familyContact;
        const medicalContact = client.medicalContact;

        const updatedContactList = contactList? contactList.map(n => ({
            ...n,
            isReferent: client.referent? n.id === client.referent.id : false
        })) : [];

        const updatedFamilyContact = familyContact? familyContact.map(n => ({
            ...n,
            isReferent: client.referent? n.id === client.referent.id : false
        })) : [];

        const updatedMedicalContact = medicalContact? medicalContact.map(n => ({
            ...n,
            isReferent: client.referent? n.id === client.referent.id : false
        })) : [];

        state.client = {
            ...client,
            contactList: updatedContactList,
            familyContact: updatedFamilyContact,
            medicalContact: updatedMedicalContact
        };

        state.clients = state.clients.map(clientSearch => {
            if (clientSearch.id === client.id) {
                return client;
            }
            return clientSearch;
        });

        state.error = null;

    },
    setDeleteClientDocumentSuccess(state, client) {
        state.client.documents = client.documents
        state.error = null;
    },
    setDeleteClientSuccess(state, id) {
        state.clients.splice(
            state.clients.findIndex(client => client.id == id),
            1
        );
        state.loading = false;
        state.error = null;
        state.total = state.total - 1
    },
    setPostClientPhoto(state) {
        state.error = null;
    },
    setPostClientPhotoSuccess(state) {
        state.error = null;
    },
    setPostClientPhotoError(state, error) {
        state.error = error;
    },
    setPostClientDocument(state) {
        state.error = null;
    },
    setPostClientDocumentSuccess(state) {
        state.error = null;
    },
    setPostClientDocumentError(state, error) {
        state.error = error;
    },
    setContactList(state, contactList) {
        state.client.contactList = contactList;
    },
    setFamilyContact(state, familyContact){
        state.client.familyContact = familyContact;
    },
    setMedicalContact(state, medicalContact){
        state.client.medicalContact = medicalContact;
    },
    deleteClientDocumentSuccess(state, documentId) {
        state.client.documents.splice(
            state.client.documents.findIndex(doc => doc.id === documentId),
            1
        );
    },
    setPutClientQrCodeSuccess(state, client) {
        state.client.qrCode = client.qrCode;
        state.error = null;
        state.loading = false;
    },
    resetSearchCity(state) {
        state.city = null;
    }
}