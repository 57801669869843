var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar"},[_vm._m(0),_c('img',{staticClass:"logo cursor-pointer",attrs:{"src":require("@/assets/logo.png")},on:{"click":function($event){$event.preventDefault();return _vm.gotToHome.apply(null, arguments)}}}),_c('div',{staticClass:"eval--header-btn-wrap"},[(_vm.accessRollback)?_c('a',{staticClass:"btn",on:{"click":function($event){$event.preventDefault();return _vm.gotToHome.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/bouton-primaire-home.svg")}})]):_vm._e(),(
        _vm.$route.name !== 'cr'
        && _vm.$route.name !== 'devis'
        && _vm.$route.name !== 'login'
        && _vm.$route.name !== 'loginPsc'
        && _vm.connectedUser
        && _vm.accessCr
      )?_c('router-link',{staticClass:"btn",attrs:{"to":{name:'cr'},"data-prefix":"2"}},[_c('img',{attrs:{"src":require("@/assets/bouton-primaire-cr.svg")}})]):_c('router-link',{staticClass:"btn",attrs:{"to":{name:'project-life'},"data-prefix":"2"}},[_c('img',{attrs:{"src":require("@/assets/bouton-primaire-q.svg")}})]),(_vm.accessBackoffice)?_c('router-link',{staticClass:"btn",attrs:{"to":{name:'client-list'},"data-prefix":"2"}},[_c('img',{attrs:{"src":require("@/assets/bouton-primaire-switch.svg")}})]):_vm._e(),_c('a',{staticClass:"btn",on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.show('quit-evaluation')}}},[_c('img',{attrs:{"src":require("@/assets/bouton-primaire-power-off.svg"),"width":"32"}})])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav__content"},[_c('img',{staticClass:"avatar",attrs:{"src":require("@/assets/avatar.png"),"width":"52"}})])
}]

export { render, staticRenderFns }