<template>
    <footer class="footer">
        <div class="footer__container" v-if="path">
            <p class="footer__link"><a href=""  @click.prevent="$router.push('cgu')">Conditions Générales d’Utilisations</a></p>
            <p class="footer__link footer__link--middle"><a href=""  @click.prevent="$router.push('rgpd')">Règlement Générale sur la Protection des Données</a></p>
            <p class="footer__link"><a href=""  @click.prevent="$router.push('mentions')">Mentions Légales</a></p>
        </div>
        <div class="footer__container" v-else>
            <p class="footer__link"><a href=""  @click.prevent="toggleCgu">Conditions Générales d’Utilisations</a></p>
            <p class="footer__link footer__link--middle"><a href=""  @click.prevent="toggleMention">Règlement Générale sur la Protection des Données</a></p>
            <p class="footer__link"><a href=""  @click.prevent="toggleRgpd">Mentions Légales</a></p>
        </div>
    </footer>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
export default {
    name: 'Footer',
    props: {
        path: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ...mapMutations('Cgu', ['toggleCgu', 'toggleMention', 'toggleRgpd'])
    },
    computed: {
        ...mapState('Cgu', ['cgu', 'mention', 'rgpd'])
    }
}
</script>
<style>
 .footer {
    background-color: #E8E7E7;
    z-index: 1;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 135px;
    padding: 0 0 20px 0;
 }

 .footer__container {
    display: flex;
    height: fit-content;
 }

 .footer__link {
    margin: 1rem 0;
    padding: 0 1rem;
 }

 .footer__link--middle {
    border: 1px solid #AAAAAA;
    border-top: 0;
    border-bottom: 0;
 }

</style>