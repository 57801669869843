<template>
    <div class="p-3 mt-5 result__insi">
        <div class="row">
            <h4 class="col-12 text-center font-weight-bold mt-4">Identité Nationale de Santé</h4>
            <div class="col-4 mt-4">
                <form-input
                    v-model="insi.birthName"
                    label="Nom de naissance"
                    :login-input="false"
                    :disabled="true"
                />
            </div>
            <div class="col-4 mt-4">
                <form-input
                    v-model="insi.birthFirstnames"
                    label="Prénom(s)"
                    :login-input="false"
                    :disabled="true"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-4 mt-4">
                <form-input
                    v-model="birthDateFR"
                    label="Date de naissance"
                    :login-input="false"
                    :disabled="true"
                />
            </div>
            <div class="col-4 mt-4">
                <form-input
                    v-model="insi.codeInsee"
                    label="Commune de naissance"
                    :login-input="false"
                    :disabled="true"
                 />
            </div>
            <div class="col-4 mt-4">
                <form-input
                    v-model="insi.sex"
                    label="Sexe"
                    :login-input="false"
                    :disabled="true"
                />
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-3 mt-3">
                <form-input
                    v-model="insi.matriculeIns"
                    label="Matricule INS"
                    :login-input="false"
                    :disabled="true"
                />
            </div>
            <div class="col-3 mt-3">
                <form-input
                    v-model="insi.oid"
                    label="OID"
                    :login-input="false"
                    :disabled="true"
                />
            </div>
            <div v-if="insi.dateBeginValid" class="col-3 mt-3">
                <form-input
                    v-model="insi.dateBeginValid"
                    label="Début de validité"
                    :login-input="false"
                    :disabled="true"
                />
            </div>
            <div v-if="insi.dateEndValid" class="col-3 mt-3">
                <form-input
                    v-model="insi.dateEndValid"
                    label="Fin de validité"
                    :login-input="false"
                    :disabled="true"
                />
            </div>
        </div>
        <div class="text-right">
            <b-button @click.prevent="saveInsiData" v-if="!isValid" class="mt-4" variant="btn col-3 w-auto btn-outline-primary" @mouseover="hover = true" @mouseleave="hover = false">Récupérer les données
                <img :class="{'hovered-image': hover}" src="../../../assets/recuperer_les_donnees.svg" class="ml-3" />
            </b-button>
            <b-button v-else class="mt-4" @click.prevent="close" variant="btn col-3 w-auto btn-outline-primary">Fermer</b-button>
        </div>
    </div>
</template>
<script>
    import Moment from "moment";
    import {mapActions} from "vuex";
    export default {
        name: "Result",
        data() {
            return {
                hover: false,
            };
        },
        props: {
            insi: {
                type: Object,
                default: null
            },
            id: {
                type: Number,
                default: null
            },
            close: {
                type: Function,
                required: true
            }
        },
        methods: {
            ...mapActions('Insi', ['saveInsi']),
            saveInsiData() {        
                this.saveInsi({
                    id: this.id,
                    payload: {
                        matriculeIns: this.insi.matriculeIns,
                        oid: this.insi.oid,
                        cle: this.insi.cle
                    }
                }).then((res) => {
                    this.$notify.success('L\'information a été sauvegardée');
                    window.location.reload();
                    
                    return res;
                }
                ).catch((err) => {
                    this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
                    throw err;
                });
            }
        },
        computed: {
            birthDateFR() {
                const formatedDate = Moment(this.insi.birthDate).format('DD/MM/YYYY');

                if (formatedDate == "Invalid date") {
                    const [year, month, day] = this.insi.birthDate.split('-');
                    const date = `${day}/${month}/${year}`;

                    return date;
                }

                return this.insi.birthDate
                    ? formatedDate
                    : '';
            },
            isValid() {
                return this.insi.birthName === null || this.insi.birthName === "" ||
                this.insi.birthFirstnames === null || this.insi.birthFirstnames === "" ||
                this.birthDateFR === null || this.birthDateFR === "" ||
                this.birthDateFR.startsWith("00") ||
                (this.birthDateFR.length >= 5 && this.birthDateFR.substring(3, 5) === "00");
            }
        }
    }
</script>
<style scoped>
    .hovered-image {
        filter: brightness(0) invert(1);
    }
    .result__insi {
        box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
        background-color: #F5F5F5;
        border-radius: 22px;
    }
</style>