import { API_URL } from "@/components/Util/EnvVariable";
import { getAsApplicationJson, postAsApplicationJson, deleteRequest, putRequest } from '@/api/Fetch';

export const saveClientWithIdentityDocument = async ({ commit },{ id, payload }) => {
    try {
        const response = await postAsApplicationJson(`${API_URL}/save-identity-document/${id}`, payload);
        const data = await response;
        commit("saveClientWithIdentityDocument", data);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getIdentityDocuments = async ({ commit }) => {
    try {
        const response = await getAsApplicationJson(`${API_URL}/identity-documents`);
        const data = await response.json();
        commit("getIdentityDocuments", data);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const postIdentityDocuments = async ({ commit }, { payload }) => {
    try {
        const response = await postAsApplicationJson(`${API_URL}/identity-document`, payload);
        const data = await response.json();
        commit("getIdentityDocuments", data);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const putIdentityDocuments = async ({ commit },{ id, payload }) => {
    try {
        await putRequest(`${API_URL}/identity-document/${id}`, payload);
        commit("documentUpdated", true);
    } catch (err) {
        commit("documentUpdated", false);
        console.error(err);
        throw err;
    }
};

export const deleteIdentityDocuments = async ({ commit }, { id }) => {
    try {
        const response = await deleteRequest(`${API_URL}/identity-document/${id}`);
        if (response.status === 200) {
            commit("deleteIdentityDocuments", id);
        }
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export default {
    saveClientWithIdentityDocument,
    getIdentityDocuments,
    postIdentityDocuments,
    putIdentityDocuments,
    deleteIdentityDocuments
};
