<template>
    <cgu-wrap v-if="showHeaders">
        <div class="container cgu">
            <h1 class="cgu__title">Conditions Générales d'Utilisation</h1>
            <p>
                Les présentes Conditions Générales d'Utilisation (CGU) ont pour 
                objet de régir la relation entre le Prestataire, d'une part, et le
                Client et les Utilisateurs finaux d'autre part (ci-après «l'Utilisateur»)
                quant aux conditions d'utilisation des Applications désignés dans le Devis par le Client.
                Les CGU peuvent être complétées par des conditions générales d'utilisation spécifiques en fonction
                de l'Application choisie. Il est entendu que le Client reste entièrement responsable du respect
                des CGU par les Utilisateurs et s'engage à veiller au respect des CGU par ces derniers.
            </p>
            <h3 class="cgu__subtitle">1. Accès à l'Application</h3>
            <p>
                Les Utilisateurs devront s'identifier pour se connecter à l'Application au moyen des Informations
                de connexion qui sont personnelles et confidentielles. Elles ne peuvent être changées que sur demande
                du Client ou à l'initiative du Prestataire sous réserve d'en informer préalablement le Client.
                Le Client s'engage à mettre tout en œuvre pour conserver secrètes les informations de connexion
                le concernant et à ne pas les divulguer sous quelque forme que ce soit. En tout état de cause,
                l'utilisation des informations de connexion vaut preuve vis-à-vis du Client, de l'utilisation par
                les Utilisateurs des ressources mises à disposition par le Prestataire ; le Client étant seul
                responsable de l'utilisation et de la garde des informations de connexion. En aucun cas, le Prestataire
                ne pourra être tenu responsable des dommages causés par les Utilisateurs, y compris les personnes non autorisées
                par le Client à avoir accès aux Applications. De manière générale, le Client s'engage à aviser immédiatement le
                Prestataire de tout incident de sécurité et/ou de toute utilisation frauduleuse constatée ou suspectée.
                En cas de perte ou de vol d'une des informations de connexion, l'Utilisateur s'engage à contacter
                le Prestataire afin que ce dernier lui indique la procédure applicable pour récupérer ses informations
                de connexion selon une procédure sécurisée.
                Par mesure de sécurité, le Prestataire pourra 
                suspendre l'accès aux Applications pendant cette procédure.
                Connexion avec le service Pro Santé Connect:
                Identification électronique par Pro Santé Connect Pro Santé Connect est un téléservice mis en œuvre
                par l’Agence du Numérique en Santé (ANS) contribuant à simplifier l’identification électronique des
                professionnels intervenant en santé. L’utilisateur peut se connecter grâce à son application mobile e-CPS 
                ou sa carte CPS, avec un lecteur de cartes et les composants nécessaires.
                Consulter les conditions générales d'utilisation de Pro Santé Connect sur le site <a href="https://integrateurs-cps.asipsante.fr/pages/prosanteconnect/cgu">https://integrateurs-cps.asipsante.fr/pages/prosanteconnect/cgu</a>.
            </p>
            <h3 class="cgu__subtitle">2. Utilisation</h3>
            <p>
                Le Prestataire accorde à l'Utilisateur à des Fins Internes une licence mondiale, non exclusive, incessible,
                non transférable et non susceptible d'octroi de sous-licence pour: (i) accéder aux Applications depuis des
                serveurs tiers ; et (ii) utiliser les Applications afin de traiter les Données appartenant au Client,
                utilisées sous licence ou contrôlées par le Client ou l'Utilisateur.
                «Fins Internes» désigne l'utilisation des Applications uniquement pour les besoins internes du Client,
                à l'exclusion de toute activité de revente, de sous-licence ou de services à des tiers utilisant les Applications.
            </p>
            <h3 class="cgu__subtitle">3. Droits de propriété intellectuelle du Prestataire</h3>
            <p>
                Le Prestataire est le titulaire de l'ensemble des droits de propriété intellectuelle
                sur les marques et contenus présents sur les Applications à l'exception des contenus
                publiés par les Utilisateurs. Aucune stipulation des CGU ne confère à l'Utilisateur
                un droit de propriété ou d'exploitation sur les marques ou autres éléments protégés
                par des droits de propriété intellectuelle du Prestataire. L'Utilisateur ne peut donc
                utiliser aucun visuel, logo, support de communication du Prestataire sans son accord express et écrit.
                L'Utilisateur s'engage à faire connaître au Prestataire toute utilisation frauduleuse des marques,
                de son logo, des visuels, dont il aurait connaissance, et assurera au Prestataire son assistance pour
                la préservation de ses droits. Les suggestions ou les commentaires faits par l'Utilisateur au Prestataire
                concernant les Applications deviendront la propriété du Prestataire et l'Utilisateur consent à la cession des suggestions et commentaires au Prestataire.
            </p>
            <h3 class="cgu__subtitle">4. Responsabilité</h3>
            <p>
                L'Utilisateur est seul responsable de toutes les conséquences résultant de son utilisation des Applications et
                plus généralement de tout contenu publié sur les Applications. La responsabilité du Prestataire ne peut,
                en aucune manière, être engagée quant au contenu publié par les Utilisateurs sur les Applications ou aux
                conséquences pouvant résulter de leur utilisation ou interprétation. Le Prestataire s'engage à retirer
                promptement tout contenu manifestement illicite dès qu'il en aura connaissance. Les Utilisateurs reconnaissent
                que le Prestataire ne garantit ni la validité, ni la complétude, la licéité des contenus publiés sur les Applications
                par les Utilisateurs. Il est entendu que le Prestataire se réserve le droit de retirer tout contenu publié qu'il
                jugerait illicite sans droit d'indemnisation pour l'Utilisateur. Il appartient aux Utilisateurs des Applications de
                prendre toutes les mesures appropriées de façon à protéger leurs propres données et/ou logiciels de la contamination
                par des éventuels virus circulant sur Internet.
                Le Prestataire ne pourra pas être tenu responsable de tout dommage indirect, y compris toute perte de contenus
                ou dommages pouvant survenir de l'utilisation ou au contraire de l'impossibilité d'accéder aux Applications.
                Les Applications sont fournies « en l'état ». L'Utilisateur est entièrement responsable de tout résultat obtenu
                de l'utilisation des Applications. Le Prestataire ne garantit pas que les Applications sont exemptes de bogues
                ou d'erreurs et que l'utilisation sera ininterrompue. Le Prestataire ne fournit aucune garantie et n'assume aucune
                responsabilité quant aux conséquences d'une indisponibilité des Applications résultant, en tout ou partie,
                d'un ou plusieurs des cas suivants: (i) dysfonctionnement ou défaillance des équipements matériels ou logiciels
                de l'Utilisateur, (ii) interruption, défaillance ou ralentissement du réseau de communications électroniques de
                l'Utilisateur, incluant sans limitation incapacité d'accès aux Applications en raison de son réseau de communications,
                connexion internet, (iii) utilisation des Applications par l'Utilisateur de manière non conforme aux termes des CGU,
                (iv) temps d'arrêt ou dégradation attribuable à l'entretien, support ou maintenance régulière ou d'urgence du Prestataire,
                (v) temps d'arrêt ou de dégradation résultant de bogues dans un logiciel ou un service tierce non causé par le Prestataire,
                (vi) incapacité d'accéder aux Applications en raison d'une modification individuelle des appareils, systèmes d'exploitation
                ou de mises à jour hors du contrôle raisonnable du Prestataire, (vii) incapacité d'accéder aux Applications en raison d'appareil(s),
                version(s), système d'exploitation et/ou navigateur(s) incompatible(s) ou non-supporté(s) (viii) force majeure incluant défaillances
                des systèmes d'hébergement, de livraison de tiers ou autres causes hors du contrôle raisonnable du Prestataire, ou (ix) manquement
                de l'Utilisateur à ses obligations découlant des CGU. Les données téléchargées sur les Applications et toute base de données,
                tout logiciel, matériel ou service de tiers se connectant depuis ou vers les Applications (collectivement, les « Matériels Tiers »)
                ne sont pas sous la responsabilité du Prestataire et le Prestataire ne fait aucune garantie ou promesse concernant ces Matériels Tiers.
            </p>
            <h3 class="cgu__subtitle">5. Suspension et résiliation</h3>
            <p>
                Le Prestataire se réserve le droit, sans préavis, de suspendre immédiatement l'accès aux Applications en
                cas de suspicion d'utilisation frauduleuse des Applications ou en cas de manquement de l'Utilisateur aux CGU.
                En cas de manquement de l'Utilisateur à l'une quelconque des stipulations des CGU, le Prestataire se réserve
                le droit, sans préavis, de résilier son accès aux Applications. Cette résiliation sera notifiée paremailà l'Utilisateur concerné.
                L'accès aux Applications sera automatiquement résilié lors de la fin de l'abonnement souscrit par le Client
            </p>
            <h3 class="cgu__subtitle">6. Liens hypertexte</h3>
            <p>
                Les Applications peuvent contenir des liens hypertextes donnant accès à des sites web de tiers.
                L'Utilisateur est formellement informé que les sites auxquels ils peuvent accéder par l'intermédiaire des liens hypertextes n'appartiennent pas au Prestataire.
                Le Prestataire décline toute responsabilité quant au contenu des informations fournies sur ces sites au titre de l'activation du lien hypertexte
                et quant à la politique de confidentialité de ces sites. L'Utilisateur ne peut invoquer la responsabilité du Prestataire en cas de perte ou de dommage
                de quelque sorte que ce soit du fait de l'activation de ces liens hypertextes.
            </p>
            <h3 class="cgu__subtitle">7. Données personnelles</h3>
            <p>
                Pour la gestion de la relation entre le Prestataire et l'Utilisateur, le Prestataire pourra être amené à
                collecter des données personnelles des Utilisateurs (les «Données Personnelles») en qualité de responsable
                de traitement. Les Données Personnelles seront conservées pendant la durée de la relation, pouvant être augmentée des durées de prescription applicables.
                L'Utilisateur peut prendre connaissance de la Politique de Protection des Données Personnelles du Prestataire qui lui permettra de comprendre comment sont collectées,
                traitées et conservées ses données personnelles par le Prestataire.
                La Politique de Protection des Données Personnelles est accessible à<a href="https://www.arche-mc2.fr">https://www.arche-mc2.fr</a>.
            </p>
            <h3 class="cgu__subtitle">8. Confidentialité</h3>
            <p>
                L'Utilisateur s'engage à maintenir strictement confidentielles toutes les informations tant écrites qu'orales,
                communiquées par le Prestataire ou dont il aura connaissance dans le cadre de l'utilisation des Applications.
                L'Utilisateur s'engage à ne pas utiliser lesdites informations, directement ou indirectement, pour son propre compte
                ou pour celui d'un tiers, sauf dans le cadre et en application des CGU.
            </p>
            <h3 class="cgu__subtitle">9. Force majeure</h3>
            <p>
                Le Prestataire ne pourra être tenue pour responsable ou être considéré en manquement des CGU, pour tout retard
                ou inexécution, lorsque la cause du retard ou de l'inexécution est liée à un cas de force majeure telle qu'elle
                est définie par la jurisprudence des cours et tribunaux français y compris notamment en cas d'attaque de pirates
                informatiques, d'indisponibilité de matériels, fournitures, pièces détachées, équipements personnels ou autres;
                et d'interruption, de suspension, de réduction ou des dérangements de l'électricité ou autres ou toutes interruptions
                de réseaux de communications électroniques.
            </p>
            <p>Dernière mise à jour le: 30/05/2024</p>
        </div>
    </cgu-wrap>
    <div class="container cgu" v-else>
            <h1 class="cgu__title">Conditions Générales d'Utilisation</h1>
            <p>
                Les présentes Conditions Générales d'Utilisation (CGU) ont pour 
                objet de régir la relation entre le Prestataire, d'une part, et le
                Client et les Utilisateurs finaux d'autre part (ci-après «l'Utilisateur»)
                quant aux conditions d'utilisation des Applications désignés dans le Devis par le Client.
                Les CGU peuvent être complétées par des conditions générales d'utilisation spécifiques en fonction
                de l'Application choisie. Il est entendu que le Client reste entièrement responsable du respect
                des CGU par les Utilisateurs et s'engage à veiller au respect des CGU par ces derniers.
            </p>
            <h3 class="cgu__subtitle">1. Accès à l'Application</h3>
            <p>
                Les Utilisateurs devront s'identifier pour se connecter à l'Application au moyen des Informations
                de connexion qui sont personnelles et confidentielles. Elles ne peuvent être changées que sur demande
                du Client ou à l'initiative du Prestataire sous réserve d'en informer préalablement le Client.
                Le Client s'engage à mettre tout en œuvre pour conserver secrètes les informations de connexion
                le concernant et à ne pas les divulguer sous quelque forme que ce soit. En tout état de cause,
                l'utilisation des informations de connexion vaut preuve vis-à-vis du Client, de l'utilisation par
                les Utilisateurs des ressources mises à disposition par le Prestataire ; le Client étant seul
                responsable de l'utilisation et de la garde des informations de connexion. En aucun cas, le Prestataire
                ne pourra être tenu responsable des dommages causés par les Utilisateurs, y compris les personnes non autorisées
                par le Client à avoir accès aux Applications. De manière générale, le Client s'engage à aviser immédiatement le
                Prestataire de tout incident de sécurité et/ou de toute utilisation frauduleuse constatée ou suspectée.
                En cas de perte ou de vol d'une des informations de connexion, l'Utilisateur s'engage à contacter
                le Prestataire afin que ce dernier lui indique la procédure applicable pour récupérer ses informations
                de connexion selon une procédure sécurisée.
                Par mesure de sécurité, le Prestataire pourra 
                suspendre l'accès aux Applications pendant cette procédure.
                Connexion avec le service Pro Santé Connect:
                Identification électronique par Pro Santé Connect Pro Santé Connect est un téléservice mis en œuvre
                par l’Agence du Numérique en Santé (ANS) contribuant à simplifier l’identification électronique des
                professionnels intervenant en santé. L’utilisateur peut se connecter grâce à son application mobile e-CPS 
                ou sa carte CPS, avec un lecteur de cartes et les composants nécessaires.
                Consulter les conditions générales d'utilisation de Pro Santé Connect sur le site <a href="https://integrateurs-cps.asipsante.fr/pages/prosanteconnect/cgu">https://integrateurs-cps.asipsante.fr/pages/prosanteconnect/cgu</a>.
            </p>
            <h3 class="cgu__subtitle">2. Utilisation</h3>
            <p>
                Le Prestataire accorde à l'Utilisateur à des Fins Internes une licence mondiale, non exclusive, incessible,
                non transférable et non susceptible d'octroi de sous-licence pour: (i) accéder aux Applications depuis des
                serveurs tiers ; et (ii) utiliser les Applications afin de traiter les Données appartenant au Client,
                utilisées sous licence ou contrôlées par le Client ou l'Utilisateur.
                «Fins Internes» désigne l'utilisation des Applications uniquement pour les besoins internes du Client,
                à l'exclusion de toute activité de revente, de sous-licence ou de services à des tiers utilisant les Applications.
            </p>
            <h3 class="cgu__subtitle">3. Droits de propriété intellectuelle du Prestataire</h3>
            <p>
                Le Prestataire est le titulaire de l'ensemble des droits de propriété intellectuelle
                sur les marques et contenus présents sur les Applications à l'exception des contenus
                publiés par les Utilisateurs. Aucune stipulation des CGU ne confère à l'Utilisateur
                un droit de propriété ou d'exploitation sur les marques ou autres éléments protégés
                par des droits de propriété intellectuelle du Prestataire. L'Utilisateur ne peut donc
                utiliser aucun visuel, logo, support de communication du Prestataire sans son accord express et écrit.
                L'Utilisateur s'engage à faire connaître au Prestataire toute utilisation frauduleuse des marques,
                de son logo, des visuels, dont il aurait connaissance, et assurera au Prestataire son assistance pour
                la préservation de ses droits. Les suggestions ou les commentaires faits par l'Utilisateur au Prestataire
                concernant les Applications deviendront la propriété du Prestataire et l'Utilisateur consent à la cession des suggestions et commentaires au Prestataire.
            </p>
            <h3 class="cgu__subtitle">4. Responsabilité</h3>
            <p>
                L'Utilisateur est seul responsable de toutes les conséquences résultant de son utilisation des Applications et
                plus généralement de tout contenu publié sur les Applications. La responsabilité du Prestataire ne peut,
                en aucune manière, être engagée quant au contenu publié par les Utilisateurs sur les Applications ou aux
                conséquences pouvant résulter de leur utilisation ou interprétation. Le Prestataire s'engage à retirer
                promptement tout contenu manifestement illicite dès qu'il en aura connaissance. Les Utilisateurs reconnaissent
                que le Prestataire ne garantit ni la validité, ni la complétude, la licéité des contenus publiés sur les Applications
                par les Utilisateurs. Il est entendu que le Prestataire se réserve le droit de retirer tout contenu publié qu'il
                jugerait illicite sans droit d'indemnisation pour l'Utilisateur. Il appartient aux Utilisateurs des Applications de
                prendre toutes les mesures appropriées de façon à protéger leurs propres données et/ou logiciels de la contamination
                par des éventuels virus circulant sur Internet.
                Le Prestataire ne pourra pas être tenu responsable de tout dommage indirect, y compris toute perte de contenus
                ou dommages pouvant survenir de l'utilisation ou au contraire de l'impossibilité d'accéder aux Applications.
                Les Applications sont fournies « en l'état ». L'Utilisateur est entièrement responsable de tout résultat obtenu
                de l'utilisation des Applications. Le Prestataire ne garantit pas que les Applications sont exemptes de bogues
                ou d'erreurs et que l'utilisation sera ininterrompue. Le Prestataire ne fournit aucune garantie et n'assume aucune
                responsabilité quant aux conséquences d'une indisponibilité des Applications résultant, en tout ou partie,
                d'un ou plusieurs des cas suivants: (i) dysfonctionnement ou défaillance des équipements matériels ou logiciels
                de l'Utilisateur, (ii) interruption, défaillance ou ralentissement du réseau de communications électroniques de
                l'Utilisateur, incluant sans limitation incapacité d'accès aux Applications en raison de son réseau de communications,
                connexion internet, (iii) utilisation des Applications par l'Utilisateur de manière non conforme aux termes des CGU,
                (iv) temps d'arrêt ou dégradation attribuable à l'entretien, support ou maintenance régulière ou d'urgence du Prestataire,
                (v) temps d'arrêt ou de dégradation résultant de bogues dans un logiciel ou un service tierce non causé par le Prestataire,
                (vi) incapacité d'accéder aux Applications en raison d'une modification individuelle des appareils, systèmes d'exploitation
                ou de mises à jour hors du contrôle raisonnable du Prestataire, (vii) incapacité d'accéder aux Applications en raison d'appareil(s),
                version(s), système d'exploitation et/ou navigateur(s) incompatible(s) ou non-supporté(s) (viii) force majeure incluant défaillances
                des systèmes d'hébergement, de livraison de tiers ou autres causes hors du contrôle raisonnable du Prestataire, ou (ix) manquement
                de l'Utilisateur à ses obligations découlant des CGU. Les données téléchargées sur les Applications et toute base de données,
                tout logiciel, matériel ou service de tiers se connectant depuis ou vers les Applications (collectivement, les « Matériels Tiers »)
                ne sont pas sous la responsabilité du Prestataire et le Prestataire ne fait aucune garantie ou promesse concernant ces Matériels Tiers.
            </p>
            <h3 class="cgu__subtitle">5. Suspension et résiliation</h3>
            <p>
                Le Prestataire se réserve le droit, sans préavis, de suspendre immédiatement l'accès aux Applications en
                cas de suspicion d'utilisation frauduleuse des Applications ou en cas de manquement de l'Utilisateur aux CGU.
                En cas de manquement de l'Utilisateur à l'une quelconque des stipulations des CGU, le Prestataire se réserve
                le droit, sans préavis, de résilier son accès aux Applications. Cette résiliation sera notifiée paremailà l'Utilisateur concerné.
                L'accès aux Applications sera automatiquement résilié lors de la fin de l'abonnement souscrit par le Client
            </p>
            <h3 class="cgu__subtitle">6. Liens hypertexte</h3>
            <p>
                Les Applications peuvent contenir des liens hypertextes donnant accès à des sites web de tiers.
                L'Utilisateur est formellement informé que les sites auxquels ils peuvent accéder par l'intermédiaire des liens hypertextes n'appartiennent pas au Prestataire.
                Le Prestataire décline toute responsabilité quant au contenu des informations fournies sur ces sites au titre de l'activation du lien hypertexte
                et quant à la politique de confidentialité de ces sites. L'Utilisateur ne peut invoquer la responsabilité du Prestataire en cas de perte ou de dommage
                de quelque sorte que ce soit du fait de l'activation de ces liens hypertextes.
            </p>
            <h3 class="cgu__subtitle">7. Données personnelles</h3>
            <p>
                Pour la gestion de la relation entre le Prestataire et l'Utilisateur, le Prestataire pourra être amené à
                collecter des données personnelles des Utilisateurs (les «Données Personnelles») en qualité de responsable
                de traitement. Les Données Personnelles seront conservées pendant la durée de la relation, pouvant être augmentée des durées de prescription applicables.
                L'Utilisateur peut prendre connaissance de la Politique de Protection des Données Personnelles du Prestataire qui lui permettra de comprendre comment sont collectées,
                traitées et conservées ses données personnelles par le Prestataire.
                La Politique de Protection des Données Personnelles est accessible à<a href="https://www.arche-mc2.fr">https://www.arche-mc2.fr</a>.
            </p>
            <h3 class="cgu__subtitle">8. Confidentialité</h3>
            <p>
                L'Utilisateur s'engage à maintenir strictement confidentielles toutes les informations tant écrites qu'orales,
                communiquées par le Prestataire ou dont il aura connaissance dans le cadre de l'utilisation des Applications.
                L'Utilisateur s'engage à ne pas utiliser lesdites informations, directement ou indirectement, pour son propre compte
                ou pour celui d'un tiers, sauf dans le cadre et en application des CGU.
            </p>
            <h3 class="cgu__subtitle">9. Force majeure</h3>
            <p>
                Le Prestataire ne pourra être tenue pour responsable ou être considéré en manquement des CGU, pour tout retard
                ou inexécution, lorsque la cause du retard ou de l'inexécution est liée à un cas de force majeure telle qu'elle
                est définie par la jurisprudence des cours et tribunaux français y compris notamment en cas d'attaque de pirates
                informatiques, d'indisponibilité de matériels, fournitures, pièces détachées, équipements personnels ou autres;
                et d'interruption, de suspension, de réduction ou des dérangements de l'électricité ou autres ou toutes interruptions
                de réseaux de communications électroniques.
            </p>
            <p>Dernière mise à jour le: 30/05/2024</p>
        </div>
</template>
<script>
export default {
    name: "Cgd",
    props: {
        showHeaders: {
            type: Boolean,
            default: true
        }
    }
}
</script>
<style>
.cgu__title {
    font: normal 20px/17px "ProximaNova", sans-serif;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
}

.cgu__subtitle {
    font: 700 16px/28px "ProximaNova", sans-serif;
}
.cgu  {
    font: normal 14px/28px "ProximaNova", sans-serif;
    margin-top: 2rem;
}
</style>