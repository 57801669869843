<script>
  import EvalCardCheck from "@/components/Eval/EvalCardCheck";
  import FormDatePicker from "@/components/Form/DatePicker";
  import {mapGetters, mapState} from "vuex";

  export default {
    props:{
      id: String
    },
    data: () => ({
      active: false,
      dataScrollTop: null
    }),
    components:{
      EvalCardCheck,
      FormDatePicker
    },
    methods: {
      toggleActive(){
        this.active = !this.active;
        this.emitActive();
      },
      setActiveElement(){
        this.active = true;
        this.emitActive();
      },
      emitActive(){
        this.$emit('active', this.active);
      },
      refToggleActive(){
        this.active = !this.active;
      }
    },
    computed: {
      ...mapState('Client', ['client']),
      ...mapGetters('Client', ['getSexStr']),
      getPeopleStr(){
        return `${this.getSexStr} ${this.client.firstname} ${this.client.lastname}`;
      }
    },
    created(){
      if(this.dataScrollTop){
        clearTimeout(this.dataScrollTop);
      }
      this.dataScrollTop = setTimeout(() => {
        const elementScrollData = document.getElementById(this.id);
        if(!elementScrollData) {
          return;
        }
        const constToScroll = {
          top: 0,
          left: elementScrollData ? elementScrollData.offsetLeft : 0,
          behavior: 'smooth'
        };
        window.scroll(constToScroll);
        let element = document.getElementById('soin-content-list');
        if (element) {
          element.scrollTo(constToScroll);
        }

        this.active = true;
        this.$emit('active', this.active);
      }, 10);
    }
  };
</script>