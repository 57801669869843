<template>
    <custom-modal :active="active" :id="'custom-modal-materials'" @close="close">
        <h4 class="history__title">
            Historique des actions sur l’usager {{ getClientSex === 'f' ? 'Mme.' : 'Mr.' }} {{ getClientLastname }} {{ getClientFirstname }}
        </h4>
        <div class="history__tab">
            <button @click="setActiveTab('update')" class="history__button" :class="{ 'history__button--actif': actifTabs === 'update' }">Modification</button>
            <button @click="setActiveTab('insi')" class="history__button" :class="{ 'history__button--actif': actifTabs === 'insi' }">Appel Téléservice INSi</button>
            <button @click="setActiveTab('identity')" class="history__button" :class="{ 'history__button--actif': actifTabs === 'identity' }">Statut identité</button>
        </div>
        <div class="history__content" @scroll="handleScroll">
            <table class="history__table">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Utilisateur</th>
                        <th v-if="this.actifTabs != 'insi'" scope="col">Valeur avant</th>
                        <th v-if="this.actifTabs != 'insi'" scope="col">Valeur après</th>
                        <th v-if="this.actifTabs == 'insi'" scope="col">Type</th>
                        <th v-if="this.actifTabs == 'insi'" scope="col">Retour INSI</th>
                        <th v-if="this.actifTabs == 'insi'" scope="col">Appel INSi</th>
                        <th v-if="this.actifTabs == 'insi'" scope="col">Contenu</th>                        
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="data in list" :key="data.id + data.client">
                        <td colspan="1">{{ transformDate(data.createdAt) }}</td>
                        <td colspan="1">{{ data.client }}</td>
                        <td v-if="!data.clientInformations" colspan="1" class="history__row">{{ data.previousData }}</td>
                        <td v-if="!data.clientInformations" colspan="1" class="history__row">{{ data.newData }}</td>
                        <td v-if="data.clientInformations" colspan="1" class="history__row">{{ data.type }}</td>
                        <td v-if="data.clientInformations" colspan="1" class="history__row">{{ data.response }}</td>
                        <td v-if="data.clientInformations" colspan="1" class="history__row">{{ data.clientInformations }}</td>
                        <td v-if="data.clientInformations" colspan="1" class="history__row">{{ data.responseInformations }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </custom-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import 'moment/locale/fr';
import CustomModal from "@/components/Util/Modal";

export default {
    components: {
        CustomModal
    },
    name: "HistoryModal",
    data() {
        return {
            actifTabs: 'update',
            list: [],
            offset: 0,
            reload: false,
            clientData: {
                lastName: "",
                firstName: "",
                sex: "f"
            },
            actif: this.active
        };
    },
    props: {
        active: {
            type: Boolean
        }
    },
    watch: {
        active(newVal) {
            if (newVal) {
                this.loadHistory();
            }
        },
        clientHistory: 'updateList',
        clientStatusHistory: 'updateList',
        insiHistory: 'updateList'
    },
    methods: {
        ...mapActions("History", ["getClientHistory", "getClientStatusHistory", "getInsiHistory"]),
        close() {
            this.$emit("close");
        },
        setActiveTab(value) {
            this.actifTabs = value;
            this.offset = 0;
            this.loadHistory();
            this.updateList();
        },
        updateList() {
            if (this.actifTabs === 'update') {
                this.list = this.clientHistory;
            } else if (this.actifTabs === 'insi') {
                this.list = this.insiHistory;
            } else if (this.actifTabs === 'identity') {
                this.list = this.clientStatusHistory;
            }
        },
        loadHistory() {
            if (this.actifTabs === 'update' && this.getReloadClientHistory) {
                this.getClientHistory({ id: this.getIdClient, offset: this.offset });
            } else if (this.actifTabs === 'insi' && this.getReloadInsiHistory) {
                this.getInsiHistory({ id: this.getIdClient, offset: this.offset });
            } else if (this.actifTabs === 'identity' && this.getReloadClientStatusHistory) {
                this.getClientStatusHistory({ id: this.getIdClient, offset: this.offset });
            }
        },
        transformDate(date) {
            moment.locale('fr');
            return moment(date).format('dddd D MMMM YYYY h:mm', 'fr');
        },
        handleScroll(event) {
            const scrollTop = event.target.scrollTop;
            const scrollHeight = event.target.scrollHeight;
            const clientHeight = event.target.clientHeight;

            if (scrollTop + clientHeight >= scrollHeight) {
                this.offset += 10;
                this.loadHistory();
            }
        },
    },
    computed: {
        ...mapState("History", [
            "clientHistory",
            "clientStatusHistory",
            "insiHistory",
            "reloadClientHistory",
            "reloadClientStatusHistory",
            "reloadInsiHistory"
        ]),
        ...mapState("Evaluation", ["evaluation"]),
        ...mapState('Client', ['client']),
        getIdClient() {
            return this.evaluation.clientId;
        },
        getReloadClientHistory() {
            return this.reloadClientHistory;
        },
        getReloadClientStatusHistory() {
            return this.reloadClientStatusHistory;
        },
        getReloadInsiHistory() {
            return this.reloadInsiHistory;
        },
        getClientLastname() {
            return this.client.lastname;
        },
        getClientFirstname() {
            return this.client.firstname;
        },
        getClientSex() {
            return this.client.sex;
        },
    }
};
</script>
<style scoped>
td, th {
    padding: 10px;
}
thead {
    border-bottom: 1px solid;
}
table thead,
table tbody {
    display: contents;
}
tbody > tr:nth-of-type(odd) {
  background-color: #F5F5F5;
}
th {
    border-bottom: 1px solid;
}
::-webkit-scrollbar {
  width: 10px;
  height: 700px;
}
::-webkit-scrollbar-track {
  background: #D9D9D9;
}
::-webkit-scrollbar-thumb {
  background: #4164E3;
}
::-webkit-scrollbar-thumb:hover {
  background: #4164e3b3;
}
</style>
