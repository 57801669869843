export default {
    getRoles(state) {
        return state.roles;
    },
    getRole(state) {
        return state.role;
    },
    getRights(state) {
        return state.rights;
    },
    getCount(state) {
        return state.roles.length;
    },
    getSuccess(state) {
        return state.success;
    }
};