import { API_URL } from "@/components/Util/EnvVariable";
import { post, postAsApplicationJson } from '@/api/Fetch';

const options = {
    cache: 'no-cache',
    credentials: 'include',
    method: "post"
}

export const getInsi = async ({ commit },{ id }) => {
    try {
        const response = await post(`${API_URL}/devbox/${id}`, options);
        const data = await response.json();
        commit("setInsi", data);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const saveInsi = async ({ commit },{ id, payload }) => {
    try {
        const response = await postAsApplicationJson(`${API_URL}/devbox-save-insi/${id}`, payload);
        const data = await response;
        commit("saveInsi", data);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const verifyInsi = async ({ commit },{ id, payload }) => {
    try {
        const response = await postAsApplicationJson(`${API_URL}/devbox-verify-insi/${id}`, payload);
        const data = await response.json();
        commit("verifyInsi", data);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export default {
    getInsi,
    saveInsi,
    verifyInsi,
};
