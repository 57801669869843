<template>
    <div class="result mt-5 d-flex justify-content-between">
        <div class="result__content">
            <img src="../../../assets/warning.svg" width="51" height="47"/>
            <p v-if="res == '02'">3 résultats disponibles, <br />veuillez affiner votre recherche</p>
            <p v-if="res == '01'">Aucun résultat disponible, <br />veuillez affiner votre recherche</p>
        </div>
        <b-button class="mt-4" variant="btn col-3 w-auto btn-outline-primary">Fermer</b-button>
    </div>
</template>
<script>
    export default {
        name: "ResultInfo",
        props: {
            res: {
                type: String,
                default: null
            },
        }
    }
</script>
<style scoped>
    p {
        margin-bottom: 0;
        font-size: 24px;
    }
    .result {
        padding: 17px;
        align-items: flex-start;
        background-color: #E8EDFF;
        font-size: 20px;
        font-weight: bold;
        border-radius: 22px;
        height: auto !important;
    }
    .result__content {
        display: flex;
        align-items: center;
        gap: 1rem;
        box-shadow: none !important;
    }
</style>