<template>
    <div class="bo--prestations row mx-0 justify-content-center">
        <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
            <div class="profil__container my-5">
                <h1 class="bo--profil-title">Liste des profils</h1>
                <span class="profil__count">{{ getCount }} {{ getCount > 1 ? 'éléments' : 'élément'}}</span>
            </div>
            <div class="text-right mb-3">
              <button class="btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1 px-3" :icon="['fas', 'sync-alt']"/></button>
              <button @click="createRole" class="primary btn btn-primary mt-1 ml-4">Créer un profil</button>
            </div>
            <div>
                <table class="profil__table">
                    <thead>
                        <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Profil</th>
                            <th scope="col">Actions</th>                    
                        </tr>
                    </thead>
                    <tbody >
                        <tr v-for="data in getRoles" :key="data.id">
                            <td class="text-uppercase font-weight-bold" colspan="1">{{ data.label }}</td>
                            <td colspan="1"><span class="font-weight-bold">{{ data.realLabel }}</span> <br/> {{ data.description }}</td>
                            <td class="d-flex pt-4">
                              <button class="profil__button" @click.prevent="editRole(data.id)"><img src="@/assets/edit_blue.svg"/></button>
                            </td>
                        </tr>
                    </tbody>
            </table>
            </div>
        </div>
    </div>
  </template>
  
  <script>
    import { mapState, mapActions, mapGetters } from 'vuex'
  
    export default {
      name: "ProfilList",
      data() {
        return {
        };
      },
      computed: {
        ...mapState("User", ["connectedUser"]),
        ...mapGetters("Roles", ["getRoles", "getCount"]),
      },
      mounted: function () {
        this.dataLoading()
     },
      methods: {
        ...mapActions("Roles", ["getAllRoles", "getRole"]),
        dataLoading() {
            this.getAllRoles();
        },
        createRole() {
          this.$router.push({name: 'profil-form'});
        },
        editRole(id) {
          if (id != null) {
            this.getRole({ id: id });
            this.$router.push({ name: 'edit-form', params: { id: id } });
          }
        },
        refresh() {
          this.getAllRoles();
      },
      },
    };
  </script>
  <style scoped>
  .bo--prestations {
    margin-bottom: 7rem;
  }

  .bo--profil-title {
    font-size: 40px;
    color: #000;
  }
  .bo--client-title-count {
    font: normal italic 16px "Open Sans", sans-serif
  }
  .profil__container {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  td, th {
      padding: 10px;
  }
  thead {
      border-bottom: 1px solid;
  }
  thead, table tbody {
    display: contents;
    background-color: white;
  }
  tbody > tr:nth-of-type(odd) {
    background-color: #F5F5F5;
  }
  th {
      border-bottom: 1px solid;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 700px;
  }
  ::-webkit-scrollbar-track {
    background: #D9D9D9;
  }
  ::-webkit-scrollbar-thumb {
    background: #4164E3;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #4164e3b3;
  }
  .profil__table {
    padding: 10px;
    border: 1px solid;
    word-break: break-all;
    border-radius: 22px 22px 8px 8px;
    border-collapse: inherit;
    border-spacing: 0;
    background-color: white;
  }
  .profil__button {
    background-color: transparent;
    border: 0px;
  }
  </style>