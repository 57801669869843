<template>
    <FormAutocomplete
        id="birthCity"
        :placeholder="defaultValue"
        :forCity="true"
        v-model="selectedCity"
        label="Commune de naissance :"
        :login-input="false"
        :list="getAllCity"
        :disabled="disabled"
        @focus="onFocusSelect"
        @onSelect="onSelectCity"
        @onInput="getDataCity"
    />
</template>

<script>
import FormAutocomplete from "@/components/Form/Autocomplete";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: 'city',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        selectedCity: {
            type: String,
            default: null
        },
        defaultValue: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            codeInsee: null
        };
    },
    components: {
        FormAutocomplete
    },
    computed: {
        ...mapState("Client", ["city"]),
        getAllCity() {
            return this.city;
        },
    },
    mounted(){
        this.resetSearchCity();
    },
    methods: {
        ...mapActions('Client', ['getCity']),
        ...mapMutations("Client", ["setCodeInsee", "resetSearchCity"]),
        getDataCity(name) {
            this.getCity({ name: name });
        },
        onFocusSelect() {
            const elementByKey = document.getElementsByClassName("wrapper--auth");
            if (Array.isArray(elementByKey)) {
                elementByKey.forEach((element) => {
                    element.scroll({
                        top: 500,
                        left: 0,
                        behavior: "smooth",
                    });
                });
            }
        },
        onSelectCity(value) {
            const index = this.city.findIndex((element) => element.nom == value);
            const found = this.city[index];
            this.$emit('codeInsee', found);
        }
    }
}
</script>
