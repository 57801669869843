export default {
    toggleCgu(state) {
        state.cgu = !state.cgu;
        state.mention = false;
        state.rgpd = false;
    },
    toggleMention(state) {
        state.mention = !state.mention;
        state.cgu = false;
        state.rgpd = false;
    },
    toggleRgpd(state) {
        state.rgpd = !state.rgpd;
        state.mention = false;
        state.cgu = false;
    }
};