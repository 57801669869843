export default {
    code: null,
    insi: {
        matriculeIns: '',
        oid: '',
        birthName: '',
        lastname: '',
        birthFirstname : '',
        birthFirstnames: '',
        firstname: '',
        birthDate: null,
        sex: '',
        codeInsee: '',
        dateBeginValid: null,
        dateEndinValid: null
    }
};