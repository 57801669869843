<template>
 <div class="result mt-5 d-flex justify-content-between align-items-center">
    <div class="result__content d-flex align-items-center">
      <img v-if="res == 'OK'" src="../../../assets/check.png" width="41" height="37"/>
      <img v-if="res == 'KO' || res == 'ERROR'" src="../../../assets/warning.svg" width="51" height="47"/>
      <p v-if="res == 'OK'">Vérification validée par INSi</p>
      <p v-if="res == 'KO'">Vérification non validée par INSi</p>
      <p v-if="res == 'ERROR'">Les champs suivants sont obligatoires pour effectuer une recherche : "Nom de naissance", "1er prenom de naissance", "Date de naissance", "Sexe" et "Code lieu de naissance". Veuillez les remplir dans la page identité.</p>
    </div>
    <b-button @click.prevent="close" class="center-button" variant="btn col-3 w-auto btn-outline-primary">Fermer</b-button>
  </div>
</template>
<script>
    export default {
        name: "VerificationIns",
        props: {
            res: {
                type: String,
                default: null
            },
        },
        methods: {
            close() {
                this.$emit('close');
            }
        }
    }
</script>
<style scoped>
    p {
        margin-bottom: 0;
        font-size: 24px;
    }
    .result {
        padding: 30px;
        align-items: flex-start;
        background-color: #E8EDFF;
        font-size: 20px;
        font-weight: bold;
        border-radius: 22px;
        height: auto !important;
    }
    .result__content {
        display: flex;
        align-items: center;
        gap: 1rem;
        box-shadow: none !important;
        margin-right: 10px;
    }  
    .center-button {
        margin-top: 0 !important;
        width: auto !important;
    }
</style>