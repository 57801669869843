import Vue from 'vue'
import VuePaginate from 'vue-paginate'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from "vuelidate";
import ElementUI from "element-ui";
import PerfectScrollbar from 'vue2-perfect-scrollbar';

import App from './App.vue';

import router from "./router";
import store from "./store";

import './layouts';
import './components';
import './sass/app.scss';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

import "element-ui/lib/theme-chalk/index.css";
import lang from "element-ui/lib/locale/lang/fr";
import locale from 'element-ui/lib/locale';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faPencilAlt, faTrash, faDownload, faSyncAlt, faExclamationCircle, faExclamationTriangle, faCheckCircle, faEye, faChevronDown, faChevronUp, faStethoscope, faStreetView, faFrown, faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faSpinner, faPencilAlt, faTrash, faDownload, faSyncAlt, faExclamationCircle, faExclamationTriangle, faCheckCircle, faEye, faChevronDown, faChevronUp, faStethoscope, faStreetView, faFrown, faHistory)

Vue.use(VuePaginate)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate);
locale.use(lang);
Vue.use(ElementUI);
Vue.use(PerfectScrollbar);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
