<template>
    <div class="id--section mt-5 pb-0">
      <div class="id--section-content">
        <div class="id--section-content text-uppercase">
            <div class="row">
                    <div class="col-12">
                        <p><span class="detail__label">Matricule INS :</span> {{ ins }}</p>
                        <p><span class="detail__label">OID :</span> {{ oid }}</p>
                    </div>
                    <div class="col-6">
                        <p><span class="detail__label">Nom de naissance : </span> {{ birthName }}</p>
                        <p><span class="detail__label">Prénom(s) de naissance : </span> {{ birthFirstnames }}</p>
                        <p><span class="detail__label">Pays de naissance : </span> {{ birthCountry }}</p>
                        <p><span class="detail__label">Commune de naissance : </span> {{ birthCity }}</p>
                        <p><span class="detail__label">Code lieu de naissance (code INSEE) :</span> {{ codeInsee }}</p>
                        <p><span class="detail__label">Date de naissance :</span> {{ birthDateFR }} {{ age }}, ANS</p>
                    </div>
                    <div class="col-6">
                        <p><span class="detail__label">1er prénom de naissance :</span> {{ birthFirstname }}</p>
                        <p><span class="detail__label">Prénom utilisé :</span> {{ firstname }}</p>
                        <p><span class="detail__label">Nom utilisé :</span> {{ lastname }}</p>
                        <p><span class="detail__label">Sexe :</span> {{ sex == 'M' ? 'Masculin' : 'Féminin' }}</p>
                        <p><span class="detail__label">Numéro de sécurité sociale :</span> {{ socialSecurityNumber }}</p>
                        <p><span class="detail__label">Nationalité :</span> {{ nationality }}</p>
                    </div>
                    <div class="col-12 id--section-container-state text-capitalize">
                        <div :class="statusClasses">{{ identificationStatus }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Detail",
    props: {
    ins: {
      type: String,
      default: null
    },
    oid: {
      type: String,
      default: null
    },
    birthName: {
      type: String,
      default: null
    },
    birthFirstnames: {
      type: String,
      default: null
    },
    birthFirstname: {
      type: String,
      default: null
    },
    birthCountry: {
      type: String,
      default: null
    },
    birthCity: {
      type: String,
      default: null
    },
    codeInsee: {
      type: String,
      default: null
    },
    birthDateFR: {
      type: String,
      default: null
    },
    firstname: {
      type: String,
      default: null
    },
    lastname: {
      type: String,
      default: null
    },
    sex: {
      type: String,
      default: null
    },
    socialSecurityNumber: {
      type: String,
      default: null
    },
    nationality: {
      type: String,
      default: null
    },
    identificationStatus: {
      type: String,
      default: null
    },
    age: {
      type: Number,
      default: null
    }
  },
  computed: {
    statusClasses() {
      return {
        'id--section-state-provisoire': this.identificationStatus === 'identité provisoire',
        'id--section-state-recuperee': this.identificationStatus === 'identité récupérée',
        'id--section-state-validee': this.identificationStatus === 'identité validée',
        'id--section-state-qualifiee': this.identificationStatus === 'identité qualifiée',
      };
    }
  }
}
</script>
<style scoped>
.detail__label {
  text-transform: initial;
}
</style>