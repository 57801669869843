<template>
  <div class="form-group custom-form"
       :class="{
      'required' : required,
      'disabled':disabled,
      'has-bg': bgIcon != null,
      'has-label': label!=null,
      'has-error': hasError,
      'active-focus-label': activeFocusLabel,
      'active-value-label': activeValueLabel,
      'input--login': loginInput,
    }">
    <label v-if="label" :for="givenId" :style="extraRows>1 ? 'bottom:' + ((extraRows-1) * 24 + 60) + 'px' : ''">{{ label }}</label>
    <textarea :id="givenId"
              :rows="extraRows"
              v-model="val"
              :type="type"
              class="form-control"
              :style="background"
              :class="{customClass,'form-control-sm' : size=='lg', 'form-control-lg':size=='lg'}"
              :name="name"
              :aria-describedby="helpId"
              :required="required"
              :disabled="disabled"
              :placeholder="placeholder"
              @blur="onBlur"
              @focus="onFocus"/>
    <template v-if="error && loginInput">
      <img class="input--error-icon" src="@/assets/error.svg" alt="">
    </template>
    <slot name="append">
    </slot>
    <slot name="error">
      <small :id="helpId" class="input--error">{{ error }}</small>
    </slot>
  </div>
</template>
<script>
export default {
  name: "FormTextarea",
  props: {
    type: {
      type: String,
      default: "text"
    },

    placeholder: {
      type: String,
      default: null
    },

    rows: {
      type: Number,
      default: 3
    },

    required: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loginInput: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: null
    },

    size: {
      type: String,
      default: null
    },

    error: {
      type: String,
      default: null
    },

    name: {
      type: String,
      default: null
    },

    customClass: {
      type: String,
      default: null
    },

    value: {
      type: [String, Number],
      default: ""
    },

    id: {
      type: String,
      default: null
    },

    bgIcon: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      val: this.value,
      focus: false
    };
  },
  computed: {
    extraRows() {
      return this.val ? this.val.split(/\r\n|\r|\n/).length : this.rows
    },
    hasError(){
      return this.error && this.error !== null
    },
    activeFocusLabel(){
      return this.focus;
    },
    activeValueLabel(){
      return this.val !== null && this.val !== "";
    },
    background() {
      if (this.bgIcon) {
        return `background-image:url('/images/svg/${this.bgIcon}.svg')`;
      } else {
        return null;
      }
    },
    status() {
      return {};
    },
    givenId() {
      if (this.id != null) {
        return this.id;
      }
      var chars =
              "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()_-+={}[]><.,?/~|",
          id = [];
      for (let index = 0; index < 5; index++) {
        var randomIndex = Number(Math.random() * chars.length).toFixed(0);
        id.push(chars[randomIndex]);
      }
      var identifier = this.$route.name
          ? this.$route.name + "-" + this.type
          : this.type;
      id.push(`-${identifier}`);
      id = id.join("");

      return id;
    },
    helpId() {
      return this.generatedId;
    }
  },
  watch: {
    val(value) {
      this.$emit("input", value);
    },
    value(v) {
      this.val = v;
    }
  },
  methods: {
    onBlur() {
      this.focus = false;
      this.$emit("blur");
    },
    onFocus() {
      this.focus = true;
      this.$emit("focus");
    }
  }
};
</script>
<style>
label {
  font-size: 14px;
}
  .form-control  {
    font-family: 'ProximaNova';
    border-radius: 0 !important;
  }

  .form-control::placeholder{
    color: #000 !important;
  }
</style>