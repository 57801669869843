export default {
    setInsi(state, data) {
        state.code = data.code;
        state.insi = data.data;
    },
    saveInsi(state, data) {
        state.response = data.response
    },
    verifyInsi(state, data) {
        state.response = data.response
    }
};