import { API_URL } from "@/components/Util/EnvVariable";
import { getAsApplicationJson, postAsApplicationJson, putRequest } from '@/api/Fetch';

export const getAllRoles = async ({ commit }) => {
    try {
        const response = await getAsApplicationJson(`${API_URL}/roles`);
        const data = await response.json();
        commit("getRoles", data);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getRole = async ({ commit }, { id: id }) => {
    try {
        const response = await getAsApplicationJson(`${API_URL}/roles/${id}`);
        const data = await response.json();
        commit("getRole", data);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};


export const getAllRights = async ({ commit }) => {
    try {
        const response = await getAsApplicationJson(`${API_URL}/right`);
        const data = await response.json();
        commit("getRights", data);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const postRoles = async ({ commit }, { payload }) => {
    commit("setPostRolesLoading");
    try {
        const response = await postAsApplicationJson(`${API_URL}/roles`, payload);
        const data = await response.json();
        commit("setPostRolesSuccess");
        return data;
    } catch (error) {
        commit("setPostRolesError", error);
        console.error(error);
        throw error;
    }
};


export const putRoles = async ({ commit }, { id, payload }) => {
    commit("setPostRolesLoading");
    try {
        const response = await putRequest(`${API_URL}/roles/${id}`, payload);
        const data = await response.json();
        commit("setPostRolesSuccess");
        return data;
    } catch (error) {
        commit("setPostRolesError", error);
        console.error(error);
        throw error;
    }
};

export default {
    getAllRoles,
    getAllRights,
    postRoles,
    getRole,
    putRoles
};