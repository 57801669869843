<template>
    <div class="bo--container">
        <template>
            <div class="container">
                <loader :loader="loading"/>
                <bo-card :title="isEdit ? '&lt; Modifier l\'usager' : ''">
                    <template slot="title">
                        <h1 class="bo-card--title">
                            <a href="#" @click.prevent="cancel">&lt; </a> Modifier l'usager
                            <el-tag :type="client.statusSync === 'not_synchronised' ? 'danger' :''" v-if="client.statusSync === 'not_synchronised'">
                              Donnée non syncrhonisée dans l'erp
                            </el-tag>
                            <el-tag v-for="(struct, index) in structure" :key="`structure-name-${index}`">
                                {{struct.name}}
                            </el-tag>
                        </h1>
                    </template>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-lg-12">
                            <div class="row">
                                <div class="col-sm-4 col-md-4">
                                    <form-radio title="Civilité"
                                                v-model="sex"
                                                :label="['Mr','Ms']"
                                                :values="['male','female']">
                                    </form-radio>
                                </div>
                                <div class="col-sm-4 col-md-4 mt-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="lastname" label="Nom" :value="lastname" :login-input="true"
                                                        :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 mt-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="firstname" label="Prénom" :value="firstname"
                                                        :login-input="true" :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4 col-md-4">
                                    <form-toggle
                                        append="Actif"
                                        prepend="Inactif"
                                        v-model="status"
                                        name="toggle"
                                        :disabled="isUserRoleIntervenant"/>
                                </div>
                                <div class="col-sm-4 col-md-4 mt-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="birthName" label="Nom de naissance" :value="birthName"
                                                        :login-input="true" :disable="true" :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4 mt-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="birthDate" label="Date de naissance" :value="birthDate"
                                                        :login-input="true" :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-md-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="address" label="Adresse" :value="address"
                                                        :login-input="true" :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="city" label="Ville" :value="city" :login-input="true"
                                                        :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-12">
                            <div class="row">
                                <div class="col-sm-4 col-md-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="folderNumber" label="Dossier"
                                                        :value="folderNumber" :login-input="true" :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="sector" label="Secteur"
                                                        :value="sector" :login-input="true" :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="postalCode" label="Code postal"
                                                        :value="postalCode" :login-input="true" :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-12">
                            <div class="row">
                                <div class="col-sm-4 col-md-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-if="age && age > 0" v-model="age" label="Age"
                                                        :value="age" :login-input="true" :disabled="true"/>
                                            <form-input v-else label="Age" :value="ageByDateToBirth"
                                                        :login-input="true" :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="socialSecurityNumber" label="Numéro de sécurité sociale"
                                                        :value="socialSecurityNumber" :login-input="true" :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="row" v-if="familyStatus">
                                        <div class="col-12">
                                            <form-input v-model="familyStatus" label="Situation familiale"
                                                        :value="familyStatus" :login-input="true" :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-12">
                            <div class="row">
                                <div class="col-sm-4 col-md-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="portablePhoneNumber" label="Téléphone portable"
                                                        :error="!$v.portablePhoneNumber.required && $v.portablePhoneNumber.$error ? 'le téléphone portable est obligatoire' : null"
                                                        :value="portablePhoneNumber" :login-input="true" :disabled="isUserRoleIntervenant"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="homePhoneNumber" label="Téléphone domicile"
                                                        :error="!$v.homePhoneNumber.required && $v.homePhoneNumber.$error ? 'le téléphone domicile est obligatoire' : null"
                                                        :value="homePhoneNumber" :login-input="true" :disabled="isUserRoleIntervenant"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="digicode" label="Digicode"
                                                        :error="!$v.digicode.required && $v.digicode.$error ? 'Le Digicode est obligatoire.' : null"
                                                        :value="digicode" :login-input="true" :disabled="isUserRoleIntervenant"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-12">
                            <div class="row">
                                <div class="col-sm-6 col-md-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="csp" label="CSP"
                                                        :error="!$v.csp.required && $v.csp.$error ? 'Le csp est obligatoire.' : null"
                                                        :value="csp" :login-input="true" :disabled="isUserRoleIntervenant"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-input v-model="protectiveMeasure" label="Mesure de protection"
                                                        :error="!$v.protectiveMeasure.required && $v.protectiveMeasure.$error ? 'la mesure de protection est obligatoire' : null"
                                                        :value="protectiveMeasure" :login-input="true" :disabled="isUserRoleIntervenant"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-12">
                          <div class="row">
                            <div class="col-sm col-md">
                              <div class="row">
                                <div class="col-12">
                                  <form-textarea v-model="housingAccess"
                                                 label="Accès au logement"
                                                 :error="!$v.housingAccess.required && $v.housingAccess.$error ? `l'accès au logement est obligatoire` : null"
                                                 :value="housingAccess" :login-input="true" :disabled="isUserRoleIntervenant"
                                                 row="3"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-12">
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-textarea v-model="additionalInformations"
                                                           label="Informations complémentaires"
                                                           :error="!$v.additionalInformations.required && $v.additionalInformations.$error ? 'les informations complémentaires sont obligatoires' : null"
                                                           :value="additionalInformations" :login-input="true" :disabled="isUserRoleIntervenant"
                                                           row="3"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-12">
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <form-textarea v-model="keyPosition"
                                                           label="Position des clés"
                                                           :value="keyPosition" :login-input="true" :disabled="isUserRoleIntervenant"
                                                           row="3"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <form-toggle
                                        append="Module famille"
                                        prepend=""
                                        v-model="familyModuleActive"
                                        name="toggle"
                                        :disabled="isUserRoleIntervenant"/>
                                </div>
                                <div class="col-sm-12 col-md-12 mt-3">
                                    <div class="row mt-3">
                                        <div class="col-12 d-flex align-items-baseline" v-if="qrCode">
                                            <div> 
                                              <vue-qrcode :value="qrCode" ref="qrcode" :width="200" />
                                              <i class="el-icon-download cursor-pointer h4 font-weight-bold text-danger" @click="downloadQR" />
                                              <canvas class="d-none" id="imageCanvas" width=220px height=230px></canvas>
                                            </div>
                                        </div>
                                        <button class="btn btn-info ml-5 w-25" @click.prevent="generateQR" v-if="!qrCode">Générer un QR code</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-12 mt-3">
                            <GridLight
                                    label="Liste des intervenants affectés"
                                    :columns="userColumns"
                                    :dataList="intervenantList"
                                    :disabled="isUserRoleIntervenant"
                                    :loading="loading"
                                    :assignable="true"
                                    @cliked="goToIntervenant"
                                    @clickAssign="showAssignUser"/>
                        </div>
                        <!--<div class="col-md-12 col-sm-12 col-lg-12 mt-3">
                            <GridLight
                                    label="Liste des prestations en cours"
                                    :columns="prestationColumns"
                                    :dataList="prestationList"
                                    :loading="prestationLoading"/>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-12 mt-3">
                            <GridLight
                                    label="Liste des contrats"
                                    :columns="contractColumns"
                                    :dataList="contractList"
                                    :loading="loading"/>
                        </div>-->
                        <div class="col-md-12 col-sm-12 col-lg-12 mt-3">
                            <GridLight
                                label="Contacts familiaux"
                                :columns="contactColumns"
                                :dataList="client.familyContact"
                                :loading="loading"/>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-12 mt-3">
                            <GridLight
                                label="Contacts médicaux"
                                :columns="contactColumns"
                                :dataList="client.medicalContact"
                                :loading="loading"/>
                        </div>
                        <div class="col-md-12 col-sm-12 col-lg-12 mt-3">
                            <GridDocument
                                label="Liste des documents"
                                :columns="contactColumns"
                                :dataList="client.documents"
                                :loading="loading"
                                :viewTable="true"
                                :disabled="isUserRoleIntervenant"
                                @remove="removeDocument"
                                @clickAdd="clickAddDocument"/>
                        </div>
                    </div>
                    <template slot="footer">
                        <button class="btn btn-primary" @click.prevent="submit" :disabled="saveLoading" v-if="!isUserRoleIntervenant">
                            <font-awesome-icon v-show="saveLoading" :icon="['fas', 'spinner']" spin/>
                            Enregistrer
                        </button>
                        <button class="btn btn-outline-primary" @click.prevent="cancel">Annuler</button>
                        <button class="btn btn-warning" v-if="client.statusSync === 'not_synchronised'" @click.prevent="$bvModal.show('client_report')">Modifier les données de l'ERP</button>
                    </template>
                </bo-card>
            </div>
        </template>
        <b-modal id="intervenant_assign" title="Intervenant's assign" :centered="true" size="lg">
            <template v-slot:modal-title>
                Affecter une aide à domicile
            </template>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <form-autocomplete v-model="userId"
                                    :list="userList"
                                    label="Liste des AAD *"
                                    :error="!userId && isSubmitAssign ? 'l\'intervenant est obligatoire' : null"
                                    :login-input="true"
                                    @onSelect="onSelectUser"
                                    @onInput="onInputUser"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <form-date-picker v-model="startDate"
                                          @change="updateDateStart"
                                          label="Date de debut *"
                                          :error="!startDate && isSubmitAssign ? 'Date de debut est obligatoire' : null"
                                          :login-input="true"
                                          typePicker="datetime"
                                          format="dd/MM/yyyy HH:mm"/>
                    </div>
                    <div class="col-sm-6">
                        <form-date-picker v-model="endDate"
                                          label="Date de fin *"
                                          :login-input="true"
                                          typePicker="datetime"
                                          format="dd/MM/yyyy HH:mm"/>
                    </div>
                </div>
            </div>
            <template v-slot:modal-footer>
                <b-button class="mt-2" variant="btn offset-6 col-3 btn-outline-primary" @click.prevent="submitAssignUser">Affecter AAD</b-button>
                <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('intervenant_assign')">Annuler</b-button>
            </template>
        </b-modal>
        <b-modal id="upload_picture" title="Ajouter une photo" :centered="true" size="lg">
            <template v-slot:modal-title>
                Ajouter une photo
            </template>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="row">
                    <div class="col-sm-5">
                        <document-simple
                            title="Photo du lieu"
                            v-model="photo"
                            :imageUrl="client.placePicture ? client.placePicture + '?' + Date.now(): ''"
                            mention="100px*100px min"
                            @input="filesPicture"/>
                        <p class="text-danger" v-if="!photo && isSubmitPicture">* La photo est obligatoire</p>
                    </div>
                    <div class="col-sm-7">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <form-input v-model="photoTitle" label="Titre"
                                        :value="photoTitle" :login-input="true" :disabled="false"/>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <form-input v-model="photoDescription" label="Description"
                                        :value="photoDescription" :login-input="true" :disabled="false"/>
                        </div>
                    </div>
                </div>
            </div>
            <template v-slot:modal-footer>
                <b-button class="mt-2" variant="btn offset-6 col-3 btn-outline-primary" @click.prevent="submitUploadPicture">Enregistrer</b-button>
                <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('upload_picture')">Annuler</b-button>
            </template>
        </b-modal>
        <b-modal id="upload_document" title="Ajouter un document" :centered="true" size="lg">
            <template v-slot:modal-title>
                + Ajouter un document
            </template>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="row">
                    <div class="col-sm-7">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <form-select v-model="documentType"
                                :options="documentTypeList"
                                label="Type de document"
                                :disabled="false"
                                :error="!documentType && isSubmitDocument ? 'La type est obligatoire.' : null"
                                :login-input="true">
                                <template v-slot:first>
                                    <option value="" selected disabled></option>
                                </template>
                            </form-select>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <form-textarea v-model="documentDescription" label="Description" :rows="3"
                                        :value="documentDescription" :login-input="true" :disabled="false"/>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <document-simple
                            title="Photo du lieu"
                            v-model="document"
                            imageUrl=""
                            mention="100px*100px min"
                            @input="filesDocument"/>
                        <p class="text-danger" v-if="!document && isSubmitDocument">* La document est obligatoire</p>
                    </div>
                </div>
            </div>
            <template v-slot:modal-footer>
                <b-button class="mt-2" variant="btn offset-6 col-3 btn-outline-primary" @click.prevent="submitUploadDocument">Enregistrer</b-button>
                <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('upload_document')">Annuler</b-button>
            </template>
        </b-modal>
        <b-modal id="client_report" title="Report" :centered="true" size="lg">
            <template v-slot:modal-title>
                Report
            </template>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="row">
                    <div class="col-lg-6">
                        <p class="mb-0"><span class="text-black font-weight-bold">Prénom: </span><span :class="hasKeyInConflict('firstname') && conflict.firstname !== client.firstname ? 'text-danger' : ''">{{client.firstname}}</span></p>
                        <p><span class="font-italic" v-if="hasKeyInConflict('firstname') && conflict.firstname !== client.firstname">{{showConflictData(conflict.firstname)}}</span></p>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb-0"><span class="text-black font-weight-bold">Nom: </span><span :class="hasKeyInConflict('lastname') && conflict.lastname !== client.lastname ? 'text-danger' : ''">{{client.lastname}}</span></p>
                        <p><span class="font-italic" v-if="hasKeyInConflict('lastname') && conflict.lastname !== client.lastname">{{showConflictData(conflict.lastname)}}</span></p>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-6">
                        <p class="mb-0"><span class="text-black font-weight-bold">Nationalité: </span><span :class="hasKeyInConflict('nationality') && conflict.nationality !== client.nationality ? 'text-danger' : ''">{{client.nationality}}</span></p>
                        <p><span class="font-italic" v-if="hasKeyInConflict('nationality') && conflict.nationality !== client.nationality">{{showConflictData(conflict.nationality)}}</span></p>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb-0"><span class="text-black font-weight-bold">Email: </span><span :class="hasKeyInConflict('email') && conflict.email !== client.email ? 'text-danger' : ''">{{client.email}}</span></p>
                        <p><span class="font-italic" v-if="hasKeyInConflict('email') && conflict.email !== client.email">{{showConflictData(conflict.email)}}</span></p>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-6">
                        <p class="mb-0"><span class="text-black font-weight-bold">Commune de naissance: </span><span :class="hasKeyInConflict('birthCity') && conflict.birthCity !== client.birthCity ? 'text-danger' : ''">{{client.birthCity}}</span></p>
                        <p><span class="font-italic" v-if="hasKeyInConflict('birthCity') && conflict.birthCity !== client.birthCity">{{showConflictData(conflict.birthCity)}}</span></p>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb-0"><span class="text-black font-weight-bold">Numéro de sécurité sociale: </span><span :class="hasKeyInConflict('socialSecurityNumber') && conflict.socialSecurityNumber !== client.socialSecurityNumber ? 'text-danger' : ''">{{client.socialSecurityNumber}}</span></p>
                        <p><span class="font-italic" v-if="hasKeyInConflict('socialSecurityNumber') && conflict.socialSecurityNumber !== client.socialSecurityNumber">{{showConflictData(conflict.socialSecurityNumber)}}</span></p>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-6">
                        <p class="mb-0"><span class="text-black font-weight-bold">Téléphone port: </span><span :class="hasKeyInConflict('portablePhoneNumber') && conflict.portablePhoneNumber !== client.portablePhoneNumber ? 'text-danger' : ''">{{client.portablePhoneNumber}}</span></p>
                        <p><span class="font-italic" v-if="hasKeyInConflict('portablePhoneNumber') && conflict.portablePhoneNumber !== client.portablePhoneNumber">{{showConflictData(conflict.portablePhoneNumber)}}</span></p>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb-0"><span class="text-black font-weight-bold">Téléphone fixe: </span><span :class="hasKeyInConflict('homePhoneNumber') && conflict.homePhoneNumber !== client.homePhoneNumber ? 'text-danger' : ''">{{client.homePhoneNumber}}</span></p>
                        <p><span class="font-italic" v-if="hasKeyInConflict('homePhoneNumber') && conflict.homePhoneNumber !== client.homePhoneNumber">{{showConflictData(conflict.homePhoneNumber)}}</span></p>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-6">
                        <p class="mb-0"><span class="text-black font-weight-bold">Adresse: </span><span :class="hasKeyInConflict('address') && conflict.address !== client.address ? 'text-danger' : ''">{{client.address}}</span></p>
                        <p><span class="font-italic" v-if="hasKeyInConflict('address') && conflict.address !== client.address">{{showConflictData(conflict.address)}}</span></p>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb-0"><span class="text-black font-weight-bold">Code postal: </span><span :class="hasKeyInConflict('postalCode') && conflict.postalCode !== client.postalCode ? 'text-danger' : ''">{{client.postalCode}}</span></p>
                        <p><span class="font-italic" v-if="hasKeyInConflict('postalCode') && conflict.postalCode !== client.postalCode">{{showConflictData(conflict.postalCode)}}</span></p>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-6">
                        <p class="mb-0"><span class="text-black font-weight-bold">Ville: </span><span :class="hasKeyInConflict('city') && conflict.city !== client.city ? 'text-danger' : ''">{{client.city}}</span></p>
                        <p><span class="font-italic" v-if="hasKeyInConflict('city') && conflict.city!==client.city">{{showConflictData(conflict.city)}}</span></p>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb-0"><span class="text-black font-weight-bold">Accès au logement: </span><span :class="hasKeyInConflict('housingAccess') && conflict.housingAccess !== client.housingAccess ? 'text-danger' : ''">{{client.housingAccess}}</span></p>
                        <p><span class="font-italic" v-if="hasKeyInConflict('housingAccess') && conflict.housingAccess!==client.housingAccess">{{showConflictData(conflict.housingAccess)}}</span></p>
                    </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-6">
                    <p class="mb-0"><span class="text-black font-weight-bold">Téléphone domicile: </span><span :class="hasKeyInConflict('homePhoneNumber') && conflict.homePhoneNumber !== client.homePhoneNumber ? 'text-danger' : ''">{{client.homePhoneNumber}}</span></p>
                    <p><span class="font-italic" v-if="hasKeyInConflict('homePhoneNumber') && conflict.homePhoneNumber!==client.homePhoneNumber">{{showConflictData(conflict.homePhoneNumber)}}</span></p>
                  </div>
                  <div class="col-lg-6">
                    <p class="mb-0"><span class="text-black font-weight-bold">Digicode: </span><span :class="hasKeyInConflict('digicode') && conflict.digicode !== client.digicode ? 'text-danger' : ''">{{client.digicode}}</span></p>
                    <p><span class="font-italic" v-if="hasKeyInConflict('digicode') && conflict.digicode!==client.digicode">{{showConflictData(conflict.digicode)}}</span></p>
                  </div>
                </div>
            </div>
            <template v-slot:modal-footer>
                <b-button class="mt-2" variant="btn offset-6 col-3 btn-outline-primary" @click.prevent="reportClient">Sauvegarder</b-button>
                <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('client_report')">Annuler</b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {mapState, mapActions, mapMutations, mapGetters} from "vuex";
    import {required} from 'vuelidate/lib/validators';
    import FormInput from "@/components/Form/Input";
    import FormTextarea from "@/components/Form/Textarea";
    import FormRadio from "@/components/Form/Radio";
    import FormAutocomplete from "@/components/Form/Autocomplete";
    import FormDatePicker from "@/components/Form/DatePicker";
    import DocumentSimple from "@/components/Form/DocumentSimple";
    //import FormCarousel from "@/components/Form/Carousel";
    import GridLight from "@/components/Grid/GridLight";
    import GridDocument from "@/components/Grid/GridDocument";
    import VueQrcode from 'vue-qrcode'
    import moment from 'moment';
    import { saveAs } from "file-saver";

    export default {
      name: "BOClientForm",
      components: {
        FormInput,
        FormTextarea,
        DocumentSimple,
        FormRadio,
        FormAutocomplete,
        FormDatePicker,
        VueQrcode,
        //FormCarousel,
        GridLight,
        GridDocument
      },
      data() {
        return {
          bo: {
            active: false
          },
          showTable: true,
          userId: '',
          interventionId: null,
          startDate: null,
          endDate: null,
          isSubmitAssign: false,
          isSubmitPicture: false,
          isSubmitDocument: false,
          intervenantTypeList: [
            {value: 'Médical', text: 'Médical'},
            {value: 'Aidant professionnel', text: 'Aidant professionnel'}
          ],
          photo: null,
          photoTitle: '',
          photoDescription: '',
          document: null,
          documentMainType: '',
          documentType: '',
          documentDescription: '',
          documentTypeList: [],
          contactColumns: [
            {name: "Nom", value: 'lastName'},
            {name: "Prénom", value: 'firstName'},
            {name: "Titre", value: 'titre'},
            {name: "Téléphone", value: 'phone'},
          ],
          prestationColumns: [
            {name: "Structure", value: 'structureName'},
            {name: "Nom", value: 'name'},
            {name: "Type", value: 'nameType'},
            {name: "Horaire", value: 'schedule'},
            {name: "Durée (Heure)", value: 'time'},
            {name: "Prix", value: 'price'}
          ],
          contractColumns: [
            {name: "Client", value: 'client'},
            {name: "Agence", value: 'agency'},
            {name: "Date de début", value: 'dateStart'},
            {name: "Date de fin", value: 'dateEnd'},
            {name: "Date de modification", value: 'updatedDate'},
            {name: "Modifié par", value: 'updatedUser'},
          ],
          userColumns: [
            {name: "Agence", value: 'companyName'},
            {name: "Email", value: 'email'},
            {name: "Nom", value: 'lastName', clickable: true},
            {name: "Prénom", value: 'firstName', clickable: true},
            {name: "Date de début", value: 'startDate'},
            {name: "Date de fin", value: 'endDate'},
            {name: "Téléphone", value: 'portableProfesionnalPhoneNumber'}
          ],
          userSupportColumns: [
            {name: "Financeur", value: 'financer'},
            {name: "Financement", value: 'financerPart'},
            {name: "Part de l'usager", value: 'clientPart'},
            {name: "Type", value: 'type'},
            {name: "Date de début", value: 'dateStart'},
            {name: "Date de fin", value: 'dateEnd'},
          ],
        };
      },
      validations() {
        return {
          portablePhoneNumber: {
            required
          },
          homePhoneNumber: {
            required
          },
          digicode: {
            required
          },
          housingAccess: {
            required
          },
          csp: {
            required
          },
          protectiveMeasure: {
            required
          },
          additionalInformations: {
            required
          }
        };
      },
      computed: {
        ...mapState('Client', ['client', 'error', 'loading', 'saveLoading', 'typesLieux', 'typesDocument']),
        ...mapState("Prestation", {prestationLoading: "loading", prestations: "prestations"}),
        ...mapState('User', ['users', 'connectedUser']),
        ...mapGetters('User', ['isUserRoleIntervenant']),
        agency: {
          get() {
            return this.client.agency
          }, set() {
          }
        },
        structure: {
          get() {
            return this.client.structure
          }, set() {
          }
        },
        /*agencyName() {
                return this.agency && this.agency.length > 0 ? this.agency.reduce((text, c) => {
                    if (!c.companyName) {
                        return text;
                    }
                    if (!text || text === "") {
                        return c.companyName;
                    }
                    text = `${text} - ${c.companyName}`;
                    return text;
                }, null) : null;
            },
            structureName() {
                return this.structure && this.structure.length > 0 ? this.structure.reduce((text, c) => {
                    if (!c.name) {
                        return text;
                    }
                    if (!text || text === "") {
                        return c.name;
                    }
                    text = `${text} - ${c.name}`;
                    return text;
                }, null) : null;
            },*/
        lastname: {
          get() {
            return this.client.lastname
          }, set() {
          }
        },
        firstname: {
          get() {
            return this.client.firstname
          }, set() {
          }
        },
        sex: {
          get() {
            return this.client.sex
          }, set() {
          }
        },
        birthName: {
          get() {
            return this.client.birthName
          }, set() {
          }
        },
        birthDate: {
          get() {
            return this.client.birthDate ? moment(this.client.birthDate).format("DD/MM/YYYY") : ''
          }, set() {
          }
        },
        address: {
          get() {
            return this.client.address
          }, set() {
          }
        },
        city: {
          get() {
            return this.client.city
          }, set() {
          }
        },
        portablePhoneNumber: {
          get() {
            return this.client.portablePhoneNumber
          },
          set(value) {
            this.setClientProperty({
              name: "portablePhoneNumber",
              value: value
            });
          }
        },
        homePhoneNumber: {
          get() {
            return this.client.homePhoneNumber
          },
          set(value) {
            this.setClientProperty({
              name: "homePhoneNumber",
              value: value
            });
          }
        },
        digicode: {
          get() {
            return this.client.digicode
          },
          set(value) {
            this.setClientProperty({
              name: "digicode",
              value: value
            });
          }
        },
        folderNumber: {
          get() {
            return this.client.folderNumber
          }, set() {
          }
        },
        sector: {
          get() {
            return this.client.sector
          }, set() {
          }
        },
        postalCode: {
          get() {
            return this.client.postalCode
          }, set() {
          }
        },
        age: {
          get() {
            return this.client.age
          }, set() {
          }
        },
        socialSecurityNumber: {
          get() {
            return this.client.socialSecurityNumber
          }, set() {
          }
        },
        familyStatus: {
          get() {
            return this.client.familyStatus
          }, set() {
          }
        },
        housingAccess: {
          get() {
            return this.client.housingAccess
          },
          set(value) {
            this.setClientProperty({
              name: "housingAccess",
              value: value
            });
          }
        },
        csp: {
          get() {
            return this.client.csp
          },
          set(value) {
            this.setClientProperty({
              name: "csp",
              value: value
            });
          }
        },
        protectiveMeasure: {
          get() {
            return this.client.protectiveMeasure
          },
          set(value) {
            this.setClientProperty({
              name: "protectiveMeasure",
              value: value
            });
          }
        },
        additionalInformations: {
          get() {
            return this.client.additionalInformations
          },
          set(value) {
            this.setClientProperty({
              name: "additionalInformations",
              value: value
            });
          }
        },
        keyPosition: {
          get() {
            return this.client.keyPosition
          },
          set(value) {
            this.setClientProperty({
              name: "keyPosition",
              value: value
            });
          }
        },
        familyModuleActive: {
          get() {
            return this.client.familyModuleActive
          },
          set(value) {
            this.setClientProperty({
              name: "familyModuleActive",
              value: value
            });
          }
        },
        qrCode: {
          get() {
            return this.client.qrCode
          },
          set() {}
        },
        status: {
          get() {
            return this.client.status
          },
          set(value) {
            this.setClientProperty({
              name: "status",
              value: value
            });
          }
        },
        isEdit() {
          return (this.$route.params.id !== undefined && this.$route.params.id !== null)
        },
        prestationList() {
          return this.prestations ? this.prestations.map(function (item) {
            return {
              ...item,
              recurrency: item.recurrency ? item.recurrency.join(',') : null,
              structureName: item.structure ? item.structure.name : ''
            }
          }) : [];
        },
        contractList() {
          return this.client.contractList ? this.client.contractList.map(function (item) {
            return {
              ...item,
              agency: item.agency ? item.agency.companyName : '',
              client: item.client ? item.client.firstname + ' ' + item.client.lastname : null,
              updatedUser: item.updatedBy ? item.updatedBy.firstName + ' ' + item.updatedBy.lastName : null,
              createdDate: item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY HH:mm') : null,
              updatedDate: item.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY HH:mm') : null,
              dateStart: item.dateStart ? moment(item.dateStart).format('DD/MM/YYYY HH:mm') : null,
              dateEnd: item.dateEnd ? moment(item.dateEnd).format('DD/MM/YYYY HH:mm') : null,
              status: item.status ? 'Actif' : 'Inactif'
            }
          }) : [];
        },
        intervenantList() {
          return this.client.intervenantList ? this.client.intervenantList.map(function (item) {
            return {
              ...item,
              userId: item.intervenant ? item.intervenant.id : null,
              firstName: item.intervenant ? item.intervenant.firstName : null,
              lastName: item.intervenant ? item.intervenant.lastName : null,
              email: item.intervenant ? item.intervenant.email : null,
              companyName: item.intervenant.agency ? item.intervenant.agency.companyName : null,
              startDate: item.dateStart ? moment(item.dateStart).utcOffset('+0000').format('DD/MM/YYYY HH:mm') : null,
              endDate: item.dateEnd ? moment(item.dateEnd).utcOffset('+0000').format('DD/MM/YYYY HH:mm') : null,
              portableProfesionnalPhoneNumber: item.intervenant ? item.intervenant.portableProfesionnalPhoneNumber : null
            }
          }) : [];
        },
        userSupportList() {
          return this.client.clientSupportList ? this.client.clientSupportList.map(function (item) {
            return {
              ...item,
              dateStart: item.dateStart ? moment(item.dateStart).format('DD/MM/YYYY HH:mm') : null,
              dateEnd: item.dateEnd ? moment(item.dateEnd).format('DD/MM/YYYY HH:mm') : null,
            }
          }) : [];
        },
        userList() {
          if (this.users.length === 0) {
            return [];
          }
          //let assignedUsers = this.intervenantList.map(item => { return item.userId });
          return this.users.reduce((list, user) => {
            /*if (assignedUsers.indexOf(user.id)>-1) {
                        return list;
                    } else {
                        list.push({value: user.id, label: `${user.firstName} ${user.lastName}`});
                        return list;
                    }*/
            list.push({value: user.id, label: `${user.firstName} ${user.lastName}`});
            return list;
          }, []);
        },
        conflict() {
          return this.client.conflictSync ? JSON.parse(this.client.conflictSync) : {}
        },
        ageByDateToBirth(){
          if(!this.client.birthDate){
            return "";
          }
          return moment(moment()).diff(this.client.birthDate, 'year');
        }
      },
      mounted: async function () {
        this.setInitClient();
        if (this.isEdit) {
          this.getClient({id: this.$route.params.id})
              .catch((err) => {
                if (err) {
                  this.$notify.error("Impossible d'accéder à ce client")
                  this.$router.push({name: 'client-list'})
                }
              })
        }
        this.getPrestationList({});
      },
      methods: {
        ...mapMutations('Client', ['setClientProperty', 'setInitClient']),
        ...mapActions('Client', ['putClient', 'putClientReport', 'getClient', 'postAssignUser', 'getClientInterventions', 'postClientPhoto', 'postClientDocument', 'deleteClientDocument', 'putGenerateQrCode']),
        ...mapActions("Prestation", ["getPrestationList", "deletePrestation"]),
        ...mapActions("User", ["getUserList"]),
        async submit() {
          if (this.$v.$invalid) {
            this.$v.$touch();
            return;
          } else {
            await this.putClient('bo')
            if (this.error === null) {
              this.setInitClient();
              this.$notify.success(this.isEdit ? 'L\'usager a été modifié' : 'L\'usager  a été créé')
              this.$router.push({name: 'client-list'});
              return;
            }
            this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
          }
        },
        cancel() {
          this.setInitClient();
          return this.$router.push({name: 'client-list'})
        },
        filesPicture() {
          let formData = new FormData();
          formData.append('photo', this.photo);
          this.setClientProperty({name: "photo", value: formData});
        },
        showAssignUser() {
          this.initAssignModalContent({});
          this.getUserList({});
          this.$bvModal.show('intervenant_assign');
        },
        goToIntervenant(event) {
          this.$router.push({name: 'user-form', params: {id: event.intervenant.id}});
        },
        setInterventionAndUpdate(intervention) {
          this.getUserList({
            filters: [
              JSON.stringify({column: 'fullname', val: intervention.lastName}),
            ]
          }).then((res) => {
            this.initAssignModalContent(intervention);
            this.$bvModal.show('intervenant_assign');
            return res;
          });
        },
        async submitAssignUser() {
          this.isSubmitAssign = true;
          if (!this.userId || !this.startDate) {
            return;
          }
          this.setClientProperty({name: "assignClientIid", value: this.client.id});
          this.setClientProperty({name: "assignUserId", value: this.userId});
          this.setClientProperty({name: "assignId", value: this.interventionId});
          this.setClientProperty({name: "startDate", value: this.startDate});
          this.setClientProperty({name: "endDate", value: this.endDate});
          await this.postAssignUser();
          if (this.error === null) {
            this.$notify.success("L'intervenant a été modifié");
            this.$bvModal.hide('intervenant_assign');
            return;
          }
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        },
        initAssignModalContent({id, userId, dateStart, dateEnd}) {
          this.isSubmitAssign = false;
          this.interventionId = id || null;
          this.userId = userId || '';
          this.startDate = dateStart || null;
          this.endDate = dateEnd || null;
        },
        onSelectUser(id) {
          this.userId = id;
        },
        onInputUser(query) {
          this.getUserList({filters: ['{"column" : "fullname", "val" : "' + query + '"}']});
        },
        showUploadModal() {
          this.photo = null;
          this.photoTitle = '';
          this.photoDescription = '';
          this.isSubmitPicture = false;
          this.$bvModal.show('upload_picture');
        },
        async submitUploadPicture() {
          this.isSubmitPicture = true;
          if (!this.photo) {
            return;
          }
          let formData = new FormData();
          formData.append('photo', this.photo);
          formData.append('title', this.photoTitle);
          formData.append('description', this.photoDescription);
          this.setClientProperty({name: "photo", value: formData});
          await this.postClientPhoto();
          if (this.error === null) {
            await this.getClient({id: this.$route.params.id});
            this.$notify.success('Le document a été enregistré');
            this.$bvModal.hide('upload_picture');
            return;
          } else {
            this.$notify.error("Impossible d'enregistrer le document");
          }
        },
        updateDateStart(date) {
          this.endDate = date;
        },
        filesDocument() {
          let formData = new FormData();
          formData.append('document', this.document);
          this.setClientProperty({name: "document", value: formData});
        },
        removeDocument(documentId) {
          this.deleteClientDocument({id: documentId}).then((res) => {
            this.getClient({id: this.$route.params.id});
            this.$bvModal.hide('preview_document')
            this.$notify.success('Document supprimé.');
            return res;
          }).catch((err) => {
            this.$notify.error("Failed.")
            throw err;
          });
        },
        clickAddDocument(mainType) {
          this.documentType = '';
          this.documentDescription = '';
          this.document = null;
          this.documentMainType = mainType;
          if (mainType === 'lieux') {
            this.documentTypeList = this.typesLieux;
            this.documentType = 'Lieu';
          } else {
            this.documentTypeList = this.typesDocument;
          }
          this.$bvModal.show('upload_document');
        },
        async submitUploadDocument() {
          this.isSubmitDocument = true;
          if (!this.document || !this.documentType) {
            return;
          }
          let formData = new FormData();
          formData.append('document', this.document);
          formData.append('main_type', this.documentMainType);
          formData.append('type', this.documentType);
          formData.append('description', this.documentDescription);
          this.setClientProperty({name: "document", value: formData});
          await this.postClientDocument();
          if (this.error === null) {
            await this.getClient({id: this.$route.params.id});
            this.$notify.success('Document téléchargé avec succès.');
            this.$bvModal.hide('upload_document');
            this.isSubmitDocument = false;
            return;
          } else {
            this.$notify.error("Impossible de télécharger le document.");
          }
        },
        async reportClient() {
          await this.putClientReport()
          if (this.error === null) {
            this.$notify.success('L\'usager a été modifié')
            this.$bvModal.hide('client_report')
            return;
          }
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
        },
        showConflictData(value = null) {
          if (!value) {
            return "(Pas de donnée précédente)";
          }
          return value;
        },
        hasKeyInConflict(key){
          return Object.keys(this.conflict).includes(key);
        },
        async generateQR() {
          if (!this.client.socialSecurityNumber) {
            this.$notify.error('Le numéro de sécurité social n\'a pas été renseigné');
            return;
          }
          await this.putGenerateQrCode();

          if (this.error === null) {
            this.$notify.success('L\'image QR Code a été générée.')
            return;
          }
          this.$notify.error("Erreur lors de la génération du QR Code")
        },
        downloadQR() {
          const clienName = this.firstname + ' ' + this.lastname;
          const canvas = document.getElementById('imageCanvas');
          let ctx = canvas.getContext('2d');
          let img = new Image();
          img.crossOrigin= 'anonymous';
          img.src = this.$refs.qrcode.dataUrl;
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 10, 0);
          ctx.fillStyle = 'black';
          ctx.textBaseline = 'top';
          ctx.textAlign = "center";
          ctx.fillText(clienName, 110, 200);
          
          const qr = canvas.toDataURL('image/png');
          fetch(qr)
            .then((response) => response.blob())
            .then((blob) => {
               saveAs(blob, clienName + '.png');
          });
        }
      }
    };
</script>