export default {
    getClient(state){
        return state.client;
    },
    getSexStr(state){
        if(!state.client.sex){
            return "";
        }
        return state.client.sex && state.client.sex === 'M' ? 'Monsieur' : 'Madame';
    }
};