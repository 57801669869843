<template>
  <div class="bo--container">
    <div class="container">
      <div>
        <h1 class="profil__title">{{ this.$route.params.id !== undefined && this.$route.params.id !== null ? "Modifier un profil" : "Créer un profil" }}</h1>
        <h3 class="profil__subtitle">Informations du profil</h3>
        <div class="row">
          <div class="col-6">
            <form-input 
              label="Nom du profil *"
              :login-input="true"
              :disabled="false"
              v-model="label"
              :error="$v.label.$error ? 'Le champs label est obligatoire' : null"
            />
          </div>
          <div class="col-6">
            <form-input 
              label="Type de profil *"
              :login-input="true"
              :disabled="false"
              v-model="type"
              :error="$v.type.$error ? 'Le champs type est obligatoire' : null"
            />
          </div>
          <div class="col-12">
            <label>Description du profil</label>
            <form-textarea :placeholder="'Quels sont les droits du profil...'" :rows="3" class="w-100" :login-input="false" :disabled="false" v-model="description"/>
            <div class="profil__description">
              <img src="@/assets/info.svg" />
              <p class="ml-3 ">
                Pour rappel, vous devez impérativement rédiger des commentaires objectifs, pertinents et adéquats à l’objectif et finalités du traitement. Ils ne doivent
                jamais être excessifs ou insuffisants à l’exclusion de toute données considérée comme sensible (origine raciale ou ethnique, opinions politiques,
                philosophiques ou religieuses, appartenance syndicale, données relatives à la santé ou à la vie sexuelle, infractions, condamnations, mesure de sureté...) En cas
                de doute, vous pouvez contactez le correspondant CNIL de votre organisme qui vous indiquera ce qu’il est possible de rédiger pour ne pas porter atteinte aux droits
                de vos usagers. Pour information, sachez que cet espace peut faire l’objet d’un contrôle afin de supprimer toute donnée interdite et que les usagers peuvent
                accéder sur simple demande aux commentaires le concernant.
              </p>
            </div>
            <div class="profil__button">
              <button class="btn btn-outline-primary mt-1 ml-1" @click.prevent="submit()">Sauvegarder</button>
              <button class="primary btn btn-primary mt-1 ml-1" @click.prevent="returnProfil">Annuler</button>
            </div>
            <div>
              <h3 class="profil__subtitle">Droits</h3>
              <div v-for="(right, key) in getRights" :key="key" class="row mb-5">
                <div class="col-4">
                  <label class="custom-checkbox">
                    <input type="checkbox" v-model="mainCheckboxes[key]" @change="toggleAll(key, right)">
                    <span class="checkmark"></span>
                    {{ key }}
                  </label>
                </div>
                <div class="col-8">
                  <div v-for="item in right" :key="item.id">
                    <label class="custom-checkbox">
                      <input type="checkbox" :id="item.id" v-model="childCheckboxes[key]" :value="item.id">
                      <span class="checkmark"></span>
                      {{ item.value }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { required } from 'vuelidate/lib/validators';

export default {
  name: "BOProfilForm",
  data() {
    return {
      mainCheckboxes: {},
      childCheckboxes: {},
      label: null,
      type: null,
      id: null,
      description: null
    };
  },
  validations() {
    return {
      label: { required },
      type: { required }
    };
  },
  computed: {
    ...mapState('Structure', ['structures']),
    ...mapState('User', ['connectedUser']),
    ...mapGetters("Roles", ["getRights", 'getSuccess', 'getRole']),
    ...mapState("Roles", ["role"]),
    concatenatedChildCheckboxes() {
      return Object.values(this.childCheckboxes).flat();
    },
    allChildCheckboxesChecked() {
      return key => {
        const rights = this.getRights[key];
        const children = this.childCheckboxes[key];
        if (!rights || !children) {
          return false;
        }
        return rights.every(item => children.includes(item.id));
      };
    }
  },
  mounted() {
    this.dataLoading();
  },
  methods: {
    ...mapActions("Roles", ["getAllRights", "postRoles", "getRoleById", "putRoles"]),
    dataLoading() {
      this.getAllRights().then(() => {
        this.initializeCheckboxes();
      });
    },
    initializeCheckboxes() {
      for (const key in this.getRights) {
        this.$set(this.mainCheckboxes, key, false);
        this.$set(this.childCheckboxes, key, []);
      }

      if (this.getRole && this.$route.params.id) {
        this.id = this.getRole.id;
        this.label = this.getRole.label;
        this.type = this.getRole.type;
        this.description = this.getRole.description;
        this.getRole.rights.forEach(element => {
          if (this.childCheckboxes[element.parent]) {
            this.childCheckboxes[element.parent].push(element.right_id);
          }
        });
      }
    },
    toggleAll(key, rights) {
      if (this.mainCheckboxes[key]) {
        this.childCheckboxes[key] = rights.map(item => item.id);
      } else {
        this.childCheckboxes[key] = [];
      }
    },
    submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return; 
      }

      const payload = {
        label: this.label,
        real_label: this.type,
        description: this.description,
        rights: this.concatenatedChildCheckboxes
      };

      if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
        this.updateRoles(payload);
      } else {
        this.createProfil(payload);
      }
    },
    createProfil(payload) {
      this.postRoles({ payload });
    },
    updateRoles(payload) {
      this.putRoles({ id: this.id, payload });
    },
    returnProfil() {
      this.$router.push({ name: 'profil-list' });
    },
  },
  watch: {
    getRights: {
      immediate: true,
      handler(newRights) {
        if (newRights) {
          this.initializeCheckboxes();
        }
      }
    },
    getSuccess: {
      handler(newValue) {
        if (newValue) {
          this.$notify.success(`Le profil ${this.label} a été enregistré`);
          this.returnProfil();
        }
      }
    },
    childCheckboxes: {
      deep: true,
      handler(newVal) {
        for (const key in newVal) {
          const rights = this.getRights[key];
          if (rights) {
            const allChecked = rights.every(item => newVal[key].includes(item.id));
            this.mainCheckboxes[key] = allChecked;
          }
        }
      }
    }
  }
};
</script>
<style>

.custom {
  display: block;
  position: relative;
  padding: 0;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px
}

.custom input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom {
  height: 17px;
  width: 17px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid #4164E3;
}
.custom label {
  display: flex;
  width: max-content;
  margin-left: 2rem;
}

.custom:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom input:checked ~ .checkmark:after {
  display: block;
}

.custom .checkmark:after {
  top: 2px;
  left: 2px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #4164E3;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox {
    display: block;
    font-size: 14px;
}

.custom-checkbox .checkmark {
  position: relative;
  height: 14px;
  width: 14px;
  background-color: #eee;
  border: 2px solid #4164E3;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  cursor: pointer;
}

.custom-checkbox .checkmark:hover {
  background-color: #ddd;
}

.custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    left: 2px;
    top: 0px;
    width: 6px;
    height: 8px;
    border: solid #4164E3;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  border-color: #4164E3;
}

.profil__title {
  font-size: 40px;
  margin-bottom: 40px;
}
.profil__subtitle {
  text-transform: uppercase;;
  font-size: 22px;
  margin-bottom: 30px;
}
.profil__description {
  display: flex;
  align-items: start;
  font-size: 14px;
}
.profil__button {
  display: flex;
  justify-content: center;
  gap: 17px;
}
.checkbox {
  display: flex;
  gap: 125px;
}
</style>