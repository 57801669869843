let options = {
    cache: 'no-cache',
    credentials: 'include',
    headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
}

let optionsJson = {
    cache: 'no-cache',
    credentials: 'include',
    headers: {
        'Content-type': 'application/json'
    }
}

export async function post(url, body){
    options.method = "post";
    options.body = body;
    return fetch(url, options)
}

export async function putRequest(url, body){
    optionsJson.method = "put";
    optionsJson.body = JSON.stringify({ body });
    return fetch(url, optionsJson)
}

export async function deleteRequest(url) {
    options.method = "delete";
    options.body = null;
    return fetch(url, options)
}

export async function postAsApplicationJson(url, body){
    optionsJson.method = "post";
    optionsJson.body = JSON.stringify({body});
    return fetch(url, optionsJson)
}

export async function postUpload(url, body){
    return fetch(url, {method: 'post', body: body})
}

export async function get(url){
    options.method = "get";
    options.body = null;
    return fetch(url, options)
}

export async function getAsApplicationJson(url){
    optionsJson.method = "get";
    optionsJson.body = null;
    return fetch(url, optionsJson)
}

