<template>
  <auth-wrap class="login">
    <div class="login--content" v-if="!cgu && !mention && !rgpd">
      <h2 class="login--title-one">Bienvenue sur OBY</h2>
      <h3 class="login--title-two" v-text="appTitle"></h3>
      <form class="login--form">
        <div class="mt-auto">
          <div class="p-2 form-group custom-form custom-form--input input--login" v-if="loginError">
            <slot name="error">
              <small class="login--error">{{ loginError }}</small>
            </slot>
          </div>
          <div class="p-2 custom-form--input active-value-label input--login">
            <form-input
              :error="errors.email"
              label="Login"
              :login-input="false"
              v-model="email"
              type="email"
              :required="true"
              @input="inputEmail"
            />
          </div>
          <div class="p-2 custom-form--input mt-1 active-value-label input--login">
            <form-input
              :error="errors.password"
              label="Mot de passe"
              :login-input="false"
              v-model="password"
              type="password"
              :required="true"
              @input="inputPassword"
            />
          </div>
        </div>
        <div class="mt-auto d-flex justify-content-center">
          <button class="login__btn"
                  style="min-width: 187px;"
                  :disabled="disabled"
                  @click.prevent="handlerLogin">
            <font-awesome-icon v-show="loading" :icon="['fas', 'spinner']" spin/> Connexion</button>
        </div>
        <div class="d-flex justify-content-center login__deconexion">
          <a href="#" @click.prevent="$router.push({name: 'forgotPassword'})">Mot de passe oublié</a>
        </div>
      </form>
      <div hidden="hidden" class="login__container">
        <hr class="login__divider"/>
        <p class="login__text">ou</p>
        <hr class="login__divider"/>
      </div>
      <button hidden="hidden" class="login__button" @click.prevent="$router.push({name: 'loginPsc'})"><img src="@/assets/pro_sante_connect.svg" /></button>
    </div>
    <Cgu  v-show="cgu"/>
    <Mention  v-show="mention"/>
    <Rgpd  v-show="rgpd"/>
  </auth-wrap>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import Cgu from '@/views/CGU/Cgd';
  import Mention from '@/views/CGU/Mention';
  import Rgpd from '@/views/CGU/Rgpd';

  export default {
    name: "LoginPage",
    components: {
      Cgu,
      Mention,
      Rgpd
    },
    data() {
      return {
        email: null,
        password: null,
        errors: {
          email: "",
          password: "",
        },
        loginError: null,
        loading: false
      };
    },
    mounted: function () {
      this.getUserConnected().then( () => {
        if (this.connectedUser) {
          this.$router.push({name: 'client-list'});
        }
      })
    },
    computed: {
      ...mapState('User', ['connectedUser']),
      ...mapState('Cgu', ['cgu', 'mention', 'rgpd']),
      userNotEmpty() {
        return this.email !== null && this.email !== "" && this.password !== null && this.password !== "";
      },
      disabled() {
        return !this.userNotEmpty || this.loading;
      },
      appTitle() {
        if (this.$route.path.includes('evaluation')) {
          return 'Votre outil d\'évaluation'
        } else {
          return 'Back Office d’administration'
        }
      }
    },
    methods: {
      ...mapActions('User', ["loginUser", 'getUserConnected']),
      handlerLogin() {
        this.loading = true;
        this.loginUser({email: this.email, password: this.password}).then((res) => {
          if (this.$route.path.includes('evaluation')) {
            this.$router.push({name: 'accueil'});
          } else {
            this.$router.push({name: 'client-list'});
          }
          this.loading = false;
          this.errors = {};
          this.loginError = null;
          return res;
        }).catch((err) => {
          this.loading = false;
          this.errors.email = ' ';
          this.errors.password = ' ';
          this.loginError = '* Login et mot de passe incorrects';
          throw err;
        });
      },
      inputEmail() {
        this.errors.email = '';
        this.loginError = null;
      },
      inputPassword() {
        this.errors.password = '';
        this.loginError = null;
      },
    }
  };
</script>