<template>
  <div class="custom-modal modal" :class="{'is-active':active}" :id="id" >
    <div :class="isTablet ? 'custom-modal--dialog--tablet' : 'custom-modal--dialog'"
         :style="`width:${dialogWidth}`">
        <div :class="[bgDark ? 'custom-modal--card-dark' : 'custom-modal--card']">
        <div class="custom-modal--close">
          <button class="btn " @click="close()">
            <img src="@/assets/icon-plein-close.svg" alt/>
          </button>
        </div>
        <div class="custom-modal--content">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomModal",
  data() {
    return {
      isTablet: document.documentElement.clientWidth < 1025
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null
    },
    bgDark: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.bgDark {
  background-color: red !important;
}
</style>