<template>
    <div class="container pt-4">
      <h5 class="eval-item--title">Identité</h5>
      <div class="eval-item--card cr--identite">
        <div class="d-flex align-items-center">
          <div class="cr--avatar" v-if="profilPicture" :style="{ backgroundImage: `url(${profilPicture ? API_URL + profilPicture.path : ''})` }"/>
          <div class="cr--user">
            <h4>{{client.sex === 'M' ? 'Monsieur' : 'Madame'}} {{client.lastname}} {{client.firstname}}</h4>
            <p class="mb-0">né.e le {{client.birthDate ? `${ageInfos.date} (${ageInfos.age} ${ageInfos.age > 1 ? 'ans' : 'an'})` : ''}}</p>
            <p>Adresse: {{client.address ? client.address + ',' : ''}} {{client.postalCode ? client.postalCode + ',' : ''}} {{client.city ? `${client.city},` : ''}} {{client.sector ? client.sector : ''}}</p>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="mb-0">Fixe: {{client.homePhoneNumber ? client.homePhoneNumber : DEFAULT_VALUE}}</p>
                <p>Mobile: {{client.portablePhoneNumber ? client.portablePhoneNumber : DEFAULT_VALUE}}</p>
              </div>
              <div class="col-sm-12 col-md-6">
                <p>Email: {{client.email ? client.email : DEFAULT_VALUE}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <p class="mb-0">Modalités d'accès au logement :</p>
                <p class="mb-0">Depuis la rue: {{client.housingAccess}}</p>
                <p class="mb-0">Logement: {{client.digicode}}</p>
                <br>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <p class="mb-0 font-weight-bold">Commentaires de l’évaluateur sur les informations du bénéficiaire :</p>
                <form-textarea
                    v-model="clientComment"
                    :rows="3"
                    class="w-100"
                    :login-input="true"
                    :disabled="false"
                    @blur="changeCommentaryValue()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

import {mapState} from "vuex";
import moment from "moment";
import CRComponent from "@/views/Evaluation/CR/CRComponent";
import {API_URL} from '@/components/Util/EnvVariable';

export default {
  name: "CR_Identity",
  extends: CRComponent,
  data() {
    return {
      API_URL: API_URL,
    }
  },
  methods: {
    exportSynthesis() {
      let agency = this.evaluation.agency;
      if (!agency) {
        agency = this.client.agency.length > 0 ? this.client.agency[0] : null;
      }
      let structure = null;
      if(agency) {
        structure = agency.structure;
      }

      return {
        peopleStr: this.getPeopleStr,
        lastname: this.client.lastname,
        firstname: this.client.firstname,
        sex: this.client.sex,
        birthdate: this.ageInfos.date ? this.ageInfos.date : this.DEFAULT_VALUE,
        age: this.ageInfos.age ? this.ageInfos.age + " ans" : this.DEFAULT_VALUE,
        adress: this.client.address ? this.client.address : this.DEFAULT_VALUE,
        city: this.client.city ? this.client.city : this.DEFAULT_VALUE,
        postalCode: this.client.postalCode ? this.client.postalCode : this.DEFAULT_VALUE,
        sector: this.client.sector ? this.client.sector : this.DEFAULT_VALUE,
        socialSecurityNumber: this.client.socialSecurityNumber ? this.client.socialSecurityNumber : this.DEFAULT_VALUE,
        homePhoneNumber: this.client.homePhoneNumber ? this.client.homePhoneNumber : this.DEFAULT_VALUE,
        portablePhoneNumber: this.client.portablePhoneNumber ? this.client.portablePhoneNumber : this.DEFAULT_VALUE,
        email: this.client.email ? this.client.email : this.DEFAULT_VALUE,
        housingAccess: this.client.housingAccess ? this.client.housingAccess : this.DEFAULT_VALUE,
        digicode: this.client.digicode ? this.client.digicode : this.DEFAULT_VALUE,
        evaluatorFirstname: this.evaluation.evaluateBy ? this.evaluation.evaluateBy.firstName : '',
        evaluatorLastname: this.evaluation.evaluateBy ? this.evaluation.evaluateBy.lastName : '',
        evaluatorPortablePhone: this.evaluation.evaluateBy && this.evaluation.evaluateBy.portableProfesionnalPhoneNumber ? this.evaluation.evaluateBy.portableProfesionnalPhoneNumber : '',
        evaluatorHomePhone: this.evaluation.evaluateBy && this.evaluation.evaluateBy.homeProfesionnalPhoneNumber ? this.evaluation.evaluateBy.homeProfesionnalPhoneNumber : '',
        agencyName: agency ? agency.companyName.toLowerCase() : '',
        structureName: structure ? structure.name.toLowerCase() : '',
        logo: structure ? structure.logo : '',
        clientComment: this.clientComment
      }
    }
  },
  created() {
    this.DEFAULT_VALUE = '--';
  },
  computed: {
    ...mapState('Client', ['client']),
    ...mapState("Evaluation", ["evaluation", "generalQuestions"]),
    ageInfos() {
      return {
        date: this.client.birthDate ? moment(this.client.birthDate).format("DD/MM/YYYY") : null,
        age: this.client.birthDate ? moment().diff(this.client.birthDate, 'years') : null
      }
    },
    profilPicture() {
      const docs = this.client.documents ? this.client.documents.filter(item => item.mainType === "profil") : []
      if(docs.length > 0) {
        return docs[0];
      }
      return null;
    },
    clientComment: {
      get() {
        return this.evaluation.evaluatorComments ? this.evaluation.evaluatorComments.clientComment : "";
      },
      set(value) {
        this.evaluation.evaluatorComments.clientComment = value;
      }
    },
  }
}
</script>