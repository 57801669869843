<template>
  <div class="soin-item">
    <div class="soin-item--title" @click.prevent="toggleActive">
      <h1>Organes des sens</h1>
      <button class="btn" :class="{ inverted: !active }">
        <img src="@/assets/chevron-up.svg" alt />
      </button>
    </div>
    <div class="soin-item--content">
      <eval-card-check
        :showContentSlot="true"
        :checked="sos01 !== null && sos01 !== ''"
        title="Vue : portez-vous des lunettes ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select
                  class="form-control"
                  v-model="sos01"
                  name="sos01"
                  id="select-sos01"
                >
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
        :showContentSlot="true"
        :checked="sos02 !== null && sos02 !== ''"
        title="Vue : avez-vous des problèmes oculaires, des difficultés pour voir de loin ou pour lire ? Des maladies oculaires ? Du diabète ? De l'HTA ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sos02" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
        :showMainSlot="true"
        :has-check="false"
        :checked="false"
        class="theme-red"
        v-show="resultIcopeSos02"
        title="RÉSULTAT ICOPE VUE"
      >
        <template v-slot:main>
          {{ resultIcopeSos02 }}
        </template>
      </eval-card-check>

      <eval-card-check
        :showContentSlot="true"
        :checked="sos05 !== null && sos05 !== ''"
        title="Avez-vous l'impression que votre audition a baissé au cours des 4 derniers mois ou depuis votre dernière évaluation ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sos05" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
        :showContentSlot="true"
        :checked="sos06 !== null && sos06 !== ''"
        title="Si oui, avez-vous un appareil auditif ?"
        v-if="(sos05 === 'r1' || sos05 === 'r3') && active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sos06" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
        :showContentSlot="true"
        :checked="sos07 !== null && sos07 !== ''"
        title="Test du chuchotement Oreille droite (se mettre derrière le sujet à un bras tendu environ, lui demander de boucher l'oreille gauche, chuchoter un mot avec 2 syllabes en direction de l'oreille droite, puis lui demander de répéter)."
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sos07" name id>
                  <option value="r1">Répète correctement</option>
                  <option value="r2">
                    Répète incorrectement ou ne répète pas
                 </option>
                  <option value="r3">NSP</option>
                  <option value="r4">test non réalisé</option>
                  <option value="r5">test non réalisable</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
        :showContentSlot="true"
        :checked="sos08 !== null && sos08 !== ''"
        title="Test du chuchotement Oreille Gauche (se mettre derrière le sujet à un bras tendu environ, lui demander de boucher l'oreille droite, chuchoter un autre mot avec 2 syllabes en direction de l'oreille gauche, puis lui demander de répéter).."
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sos08" name id>
                  <option value="r1">Répète correctement.</option>
                  <option value="r2">
                    Répète incorrectement ou ne répète pas.
                 </option>
                  <option value="r3">NSP</option>
                  <option value="r4">Test non réalisé</option>
                  <option value="r5">Test non-réalisable</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
        :showMainSlot="true"
        :has-check="false"
        :checked="false"
        class="theme-red"
        v-show="resultIcopeSos07Sos8"
        title="RÉSULTAT ICOPE AUDITION"
      >
        <template v-slot:main>
          {{ resultIcopeSos07Sos8 }}
        </template>
      </eval-card-check>

      <eval-card-check
        :showContentSlot="true"
        :checked="sos04 !== null && sos04 !== ''"
        title="Avez-vous des troubles de la sensibilité au toucher (lorsque vous touchez ou que l'on vous touche) ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sos04" name id>
                  <option value="r1">Perte de sensibilité</option>
                  <option value="r2">Sensation douloureuse</option>
                  <option value="r3">Les deux</option>
                  <option value="r4">
                    Aucune perte de sensibilité ni présence de sensation
                    douloureuse au toucher
                 </option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
        :showContentSlot="true"
        :checked="sos03 !== null && sos03 !== ''"
        title="Depuis combien de temps ?"
        v-if="general && (hasSsg04ResponseR6 || hasSsg04ResponseR7) && active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sos03" name id>
                  <option value="r1">Depuis moins d'une semaine</option>
                  <option value="r2">Depuis moins d'un mois</option>
                  <option value="r3">Depuis moins de 2 mois</option>
                  <option value="r4">Depuis plus de 1 an</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
        :showMainSlot="true"
        :has-check="false"
        :checked="false"
        class="theme-red"
        v-show="general && (hasSsg04ResponseR6 || hasSsg04ResponseR7)"
        title="RÉSULTAT GOÛT ET ODORAT"
      >
        <template v-slot:main>
          {{
            (hasSsg04ResponseR6 ? "Perte de goût. " : "") +
            (hasSsg04ResponseR7 ? "Perte d'odorat. " : "")
          }}
        </template>
      </eval-card-check>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SubQuestionExtend from "@/views/Evaluation/Soin/SubQuestionExtend";
import { getICOPEAudition } from "@/components/Eval/SoinCalcul";

export default {
  extends: SubQuestionExtend,
  methods: {
    ...mapMutations("Evaluation", ["setTreatmentProperty"]),
    ...mapActions("Evaluation", ["saveTreatment"]),
  },
  computed: {
    hasSsg04ResponseR6() {
      if (!this.general || !this.general.sg04 || !this.general.sg04.response) {
        return false;
      }
      return this.general.sg04.response.split(",").includes("r6");
    },
    hasSsg04ResponseR7() {
      if (!this.general || !this.general.sg04 || !this.general.sg04.response) {
        return false;
      }
      return (
        this.general &&
        this.general.sg04 &&
        this.general.sg04.response.split(",").includes("r7")
      );
    },
    resultIcopeSos02() {
      if (this.sos02 === "r1") {
        return "Une évaluation complète de la vue est recommandée.";
      }
      if (this.sos02 === "r2") {
        return "Pas d'anomalie visuelle déclarée.";
      }
      if (this.sos02 === "r3") {
        return "La fonction visuelle n'a pas pu être explorée.";
      }
      return null;
    },
    resultIcopeSos07Sos8() {
      return getICOPEAudition(this.sos05, this.sos07, this.sos08);
    },
    ...mapState("Evaluation", ["sense", "general"]),
    sos01: {
      get() {
        return this.sense.sos01.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sos01",
          value: value,
        });
        this.saveTreatment({
          id: "sense",
          name: "sos01",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sos02: {
      get() {
        return this.sense.sos02.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sos02",
          value: value,
        });
        this.saveTreatment({
          id: "sense",
          name: "sos02",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sos03: {
      get() {
        return this.sense.sos03.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sos03",
          value: value,
        });
        this.saveTreatment({
          id: "sense",
          name: "sos03",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sos04: {
      get() {
        return this.sense.sos04.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sos04",
          value: value,
        });
        this.saveTreatment({
          id: "sense",
          name: "sos04",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sos05: {
      get() {
        return this.sense.sos05.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sos05",
          value: value,
        });
        this.saveTreatment({
          id: "sense",
          name: "sos05",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sos06: {
      get() {
        return this.sense.sos06.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sos06",
          value: value,
        });
        this.saveTreatment({
          id: "sense",
          name: "sos06",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sos07: {
      get() {
        return this.sense.sos07.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sos07",
          value: value,
        });
        this.saveTreatment({
          id: "sense",
          name: "sos07",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sos08: {
      get() {
        return this.sense.sos08.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sos08",
          value: value,
        });
        this.saveTreatment({
          id: "sense",
          name: "sos08",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
  },
};
</script>
