<template>
  <div class="container pt-4">
    <h5 class="eval-item--title">Plan d'objectifs et d'actions</h5>
    <div class="eval-item--card cr--context">
      <table class="table goals-table">
        <thead>
        <tr>
          <th class="goals-table-category"></th>
          <th class="goals-table-active">Active</th>
          <th class="goals-table-problem">Problème repéré</th>
          <th class="goals-table-goal">Objectif</th>
          <th class="goals-table-selection">Actions à mettre en œuvre</th>
          <th class="goals-table-affectation">Par</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in ruptureEvents" :key="index" :style="{opacity: row.active ? 1 : 0.5}">
          <td class="goals-table-category" :rowspan="ruptureEvents.length" v-if="index === 0">ÉVENEMENTS ET SITUATION DE RUPTURE</td>
          <td class="goals-table-active">
            <div class="eval-form--check">
              <div class="id-check--option">
                <input :id="`${index}`"
                       type="checkbox"
                       class="id-check--input"
                       v-model="row.active"
                       @change="changeGoalActive(row)">
                <label :for="`${index}`"><span class="id-check--indicator"></span></label>
              </div>
            </div>
          </td>
          <td class="goals-table-problem">
            <GoalsFreeText
                v-if="row.problemEditable"
                :inputData="row"
                category="ruptureEvent"
                field="problem"
                @updateFreeText="saveGoalsActions"
            />
            <p v-else>{{row.problem}}</p>
          </td>
          <td class="goals-table-goal">
            <GoalsFreeText
                :inputData="row"
                category="ruptureEvent"
                field="goal"
                @updateFreeText="saveGoalsActions"
            />
          </td>
          <td class="goals-table-selection">
            <GoalsSelection
                :inputData="row"
                v-if="row.actionsType === 'select'"
                category="ruptureEvent"
                field="actions"
                @updateGoals="saveGoalsActions"
            />
            <GoalsFreeText
                :inputData="row"
                v-else
                category="ruptureEvent"
                field="actions"
                @updateFreeText="saveGoalsActions"
            />
          </td>
          <td class="goals-table-affectation">
            <GoalsAffectation
                :inputData="row"
                category="ruptureEvent"
                @updateAffectation="saveGoalsActions"
            />
          </td>
        </tr>

        <tr v-for="(row, index) in logement" :key="`logement-${index}`" :style="{opacity: row.active ? 1 : 0.5}">
          <td class="goals-table-category" :rowspan="logement.length" v-if="index === 0">ADAPTATION DU LOGEMENT</td>
          <td class="goals-table-active">
            <div class="eval-form--check">
              <div class="id-check--option">
                <input :id="`logement-${index}`"
                       type="checkbox"
                       class="id-check--input"
                       v-model="row.active"
                       @change="changeGoalActive(row)">
                <label :for="`logement-${index}`"><span class="id-check--indicator"></span></label>
              </div>
            </div>
          </td>
          <td class="goals-table-problem">{{row.problem}}</td>
          <td class="goals-table-goal">
            <GoalsFreeText
                :inputData="row"
                category="logement"
                field="goal"
                @updateFreeText="saveGoalsActions"
            />
          </td>
          <td class="goals-table-selection">
            <GoalsSelection
                :inputData="row"
                category="logementAdaptation"
                field="actions"
                @updateGoals="saveGoalsActions"
            />
          </td>
          <td class="goals-table-affectation">
            <GoalsAffectation
                :inputData="row"
                category="logementAdaptation"
                @updateAffectation="saveGoalsActions"
            />
          </td>
        </tr>

        <tr v-for="(row, index) in technical"
            :key="`technicalHelp-${index}`"
            :style="{opacity: row.active ? 1 : 0.5}">
          <td class="goals-table-category" :rowspan="technical.length" v-if="index === 0">AIDES TECHNIQUES</td>
          <td class="goals-table-active">
            <div class="eval-form--check">
              <div class="id-check--option">
                <input :id="`technicalHelp-${index}`"
                       type="checkbox"
                       class="id-check--input"
                       v-model="row.active"
                       @change="changeGoalActive(row)">
                <label :for="`technicalHelp-${index}`"><span class="id-check--indicator"></span></label>
              </div>
            </div>
          </td>
          <td class="goals-table-problem">{{row.problem}}</td>
          <td class="goals-table-goal">
            <GoalsFreeText
                :inputData="row"
                category="technicalHelp"
                field="goal"
                @updateFreeText="saveGoalsActions"
            />
          </td>
          <td class="goals-table-selection">
            <p v-if="row.type === 'noMaterials'">{{row.actions}}</p>
            <GoalsSelection
                v-else
                :inputData="row"
                category="technicalHelp"
                field="actions"
                @updateGoals="saveGoalsActions"
            />
          </td>
          <td class="goals-table-affectation">
            <GoalsAffectation
                :inputData="row"
                category="technicalHelp"
                @updateAffectation="saveGoalsActions"
            />
          </td>
        </tr>

        <tr v-for="(row, index) in protection" :key="`protectionMeasure-${index}`" :style="{opacity: row.active ? 1 : 0.5}">
          <td class="goals-table-category" :rowspan="protection.length" v-if="index === 0">MESURES DE PROTECTION</td>
          <td class="goals-table-active">
            <div class="eval-form--check">
              <div class="id-check--option">
                <input :id="`protectionMeasure-${index}`"
                       type="checkbox"
                       class="id-check--input"
                       v-model="row.active"
                       @change="changeGoalActive(row)">
                <label :for="`protectionMeasure-${index}`"><span class="id-check--indicator"></span></label>
              </div>
            </div>
          </td>
          <td class="goals-table-problem">
            {{row.problem}}
          </td>
          <td class="goals-table-goal">
            <GoalsFreeText
                :inputData="row"
                category="protectionMeasure"
                field="goal"
                @updateFreeText="saveGoalsActions"
            />
          </td>
          <td class="goals-table-selection">
            <GoalsSelection
                :inputData="row"
                category="protectionMeasure"
                field="actions"
                @updateGoals="saveGoalsActions"
            />
          </td>
          <td class="goals-table-affectation">
            <GoalsAffectation
                :inputData="row"
                category="protectionMeasure"
                @updateAffectation="saveGoalsActions"
            />
          </td>
        </tr>

        <tr v-for="(row, index) in pecRows" :key="`pec-${index}`" :style="{opacity: row.active ? 1 : 0.5}">
          <td class="goals-table-category" :rowspan="pecRows.length"  v-if="index === 0">PRISE EN CHARGE</td>
          <td class="goals-table-active">
            <div class="eval-form--check">
              <div class="id-check--option">
                <input :id="`pec-${index}`"
                       type="checkbox"
                       class="id-check--input"
                       v-model="row.active"
                       @change="changeGoalActive(row)">
                <label :for="`pec-${index}`"><span class="id-check--indicator"></span></label>
              </div>
            </div>
          </td>
          <td class="goals-table-problem">
            <div v-if="row.type === 'pec'">
              <p>Prise en charge actuelle :</p>
              <p v-for="(problemRow, id) in row.problem" :key="id"  class="mb-0" >{{problemRow}}</p>
            </div>
            <p v-else>{{row.problem}}</p>
          </td>
          <td class="goals-table-goal">
            <GoalsFreeText
                :inputData="row"
                category="pec"
                field="goal"
                @updateFreeText="saveGoalsActions"
            />
          </td>
          <td class="goals-table-selection">
            <GoalsSelection
                :inputData="row"
                category="pec"
                field="actions"
                @updateGoals="saveGoalsActions"
            />
          </td>
          <td class="goals-table-affectation">
            <GoalsAffectation
                :inputData="row"
                category="pec"
                @updateAffectation="saveGoalsActions"
            />
          </td>
        </tr>

        <tr v-for="(row, index) in social" :key="`social-${index}`" :style="{opacity: row.active ? 1 : 0.5}">
          <td class="goals-table-category" :rowspan="social.length" v-if="index === 0">INTERACTIONS SOCIALES</td>
          <td class="goals-table-active">
            <div class="eval-form--check">
              <div class="id-check--option">
                <input :id="`social-${index}`"
                       type="checkbox"
                       class="id-check--input"
                       v-model="row.active"
                       @change="changeGoalActive(row)">
                <label :for="`social-${index}`"><span class="id-check--indicator"></span></label>
              </div>
            </div>
          </td>
          <td class="goals-table-problem">{{row.problem}}</td>
          <td class="goals-table-goal">
            <GoalsFreeText
                :inputData="row"
                category="social"
                field="goal"
                @updateFreeText="saveGoalsActions"
            />
          </td>
          <td class="goals-table-selection">
            <GoalsSelection
                :inputData="row"
                category="social"
                field="actions"
                @updateGoals="saveGoalsActions"
            />
          </td>
          <td class="goals-table-affectation">
            <GoalsAffectation
                :inputData="row"
                category="social"
                @updateAffectation="saveGoalsActions"
            />
          </td>
        </tr>

        <tr v-if="entourageRow" :style="{opacity: entourageRow.active ? 1 : 0.5}">
          <td class="goals-table-category">ENTOURAGE</td>
          <td class="goals-table-active">
            <div class="eval-form--check">
              <div class="id-check--option">
                <input :id="`entourage`"
                       type="checkbox"
                       class="id-check--input"
                       v-model="entourageRow.active"
                       @change="changeGoalActive(row)">
                <label :for="`entourage`"><span class="id-check--indicator"></span></label>
              </div>
            </div>
          </td>
          <td class="goals-table-problem">{{entourageRow.problem}}</td>
          <td class="goals-table-goal">
            <GoalsFreeText
                :inputData="entourageRow"
                category="entourage"
                field="goal"
                @updateFreeText="saveGoalsActions"
            />
          </td>
          <td class="goals-table-selection">
            <GoalsSelection
                :inputData="entourageRow"
                category="entourage"
                field="actions"
                @updateGoals="saveGoalsActions"
            />
          </td>
          <td class="goals-table-affectation">
            <GoalsAffectation
                :inputData="entourageRow"
                category="social"
                @updateAffectation="saveGoalsActions"
            />
          </td>
        </tr>

        <tr v-for="(row, index) in weak" :key="`weakness-${index}`" :style="{opacity: row.active ? 1 : 0.5}">
          <td class="goals-table-category" :rowspan="weak.length" v-if="index === 0">FRAGILITES</td>
          <td class="goals-table-active">
            <div class="eval-form--check">
              <div class="id-check--option">
                <input :id="`weakness-${index}`"
                       type="checkbox"
                       class="id-check--input"
                       v-model="row.active"
                       @change="changeGoalActive(row)">
                <label :for="`weakness-${index}`"><span class="id-check--indicator"></span></label>
              </div>
            </div>
          </td>
          <td class="goals-table-problem">
            <p v-for="(probRow, id) in row.problem" :key="id">{{probRow}}</p>
          </td>
          <td class="goals-table-goal">
            <GoalsFreeText
                :inputData="row"
                category="weakness"
                field="goal"
                @updateFreeText="saveGoalsActions"
            />
          </td>
          <td class="goals-table-selection">
            <GoalsSelection
                :inputData="row"
                category="weakness"
                field="actions"
                @updateGoals="saveGoalsActions"
            />
          </td>

          <td class="goals-table-affectation">
            <GoalsAffectation
                :inputData="row"
                category="weakness"
                @updateAffectation="saveGoalsActions"
            />
          </td>
        </tr>

        <tr v-for="(row, index) in risksRows" :key="`risks-${index}`" :style="{opacity: row.active ? 1 : 1}">
          <td class="goals-table-category" :rowspan="risksRows.length" v-if="index === 0">AUTRES RISQUES SIGNALES</td>
          <td class="goals-table-active">
            <div class="eval-form--check">
              <div class="id-check--option">
                <input :id="`risks-${index}`"
                       type="checkbox"
                       class="id-check--input"
                       v-model="row.active"
                       @change="changeGoalActive(row)">
                <label :for="`risks-${index}`"><span class="id-check--indicator"></span></label>
              </div>
            </div>
          </td>
          <td class="goals-table-problem">
            {{row.problem}}
          </td>
          <td class="goals-table-goal">
            <GoalsFreeText
                :inputData="row"
                category="risks"
                field="goal"
                @updateFreeText="saveGoalsActions"
            />
          </td>
          <td class="goals-table-selection">
            <GoalsFreeText
                :inputData="row"
                category="risks"
                field="actions"
                @updateFreeText="saveGoalsActions"
            />
          </td>

          <td class="goals-table-affectation">
            <GoalsAffectation
                :inputData="row"
                category="risks"
                @updateAffectation="saveGoalsActions"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import CRComponent from "./CRComponent";
import GoalsSelection from "@/components/Eval/GoalsSelection";
import GoalsFreeText from "@/components/Eval/GoalsFreeText";
import GoalsAffectation from "@/components/Eval/GoalsAffectation";
import {imcCalcul, mnaCalcul, nortonCalcul, miniCogCalcul, miniGdsCalcul, getICOPEAudition, getICOPECognition, getRSMD05, balance} from "@/components/Eval/SoinCalcul";

export default {
  name: "CR_Objectives_Actions",
  extends: CRComponent,
  components: {
    GoalsSelection,
    GoalsAffectation,
    GoalsFreeText
  },
  data() {
    return {
    }
  },
  created() {
    this.DEFAULT_VALUE = 'non renseigné';
  },
  methods: {
    ...mapMutations('Evaluation', ['setSyntheseGoalProperty']),
    ...mapActions('Evaluation', ['saveSyntheseGoals']),
    exportSynthesis() {
      const icopeDepression = getRSMD05(this.brain.smd04.response, this.brain.smd05.response)

      return {
        icopeCognition: getICOPECognition(this.brain.smd03.response, this.getScoreQuestionAggirOrientationTemps, this.getScoreQuestionAggirOrientationEspace),
        icopeDepression: icopeDepression ? icopeDepression : 'Le test n\'a pas pu être réalisé',
        ruptureEventsTable: JSON.stringify(this.covertDataToTable(this.ruptureEvents.filter((el) => el.active), 'ruptureEvents')),
        logementTable: JSON.stringify(this.covertDataToTable(this.logement.filter((el) => el.active), 'logement')),
        technicalTable: JSON.stringify(this.covertDataToTable(this.technical.filter((el) => el.active), 'technical')),
        protectionTable: JSON.stringify(this.covertDataToTable(this.protection.filter((el) => el.active), 'protection')),
        pecRowsTable: JSON.stringify(this.covertDataToTable(this.pecRows.filter((el) => el.active), 'pecRows')),
        socialTable: JSON.stringify(this.covertDataToTable(this.social.filter((el) => el.active), 'social')),
        entourageRowTable: JSON.stringify(this.covertDataToTable(this.entourageRow.active ? [this.entourageRow] :[], 'entourageRow')),
        weakTable: JSON.stringify(this.covertDataToTable(this.weak.filter((el) => el.active), 'weak')),
        risksRowsTable: JSON.stringify(this.covertDataToTable(this.risksRows.filter((el) => el.active), 'risksRows')),
      }

    },
    covertDataToTable(data, key) {
      return data ? data.map(item=>{
        const problem = Array.isArray(item.problem) ? item.problem.join(', ') : (item.problem || '');
        const goal = Array.isArray(item.goal) ? item.goal.join(', ') : (item.goal || '');
        const actions = typeof item.actions === 'object' ? item.actions.choices.filter(e=>item.actions.selected.includes(e.value)).map(e=>{return e.label}).join(', ') : item.actions
        const affectation = typeof item.affectation === 'object' ? item.affectation.choices.filter(e=>item.affectation.selected.includes(e.value)).map(e=>{return e.text}).join(', ') : item.affectation
        let response = {};
        response[key + 'Problem'] = problem;
        response[key + 'Goal'] = goal;
        response[key + 'Actions'] = actions || '';
        response[key + 'Affectation'] = affectation || '';
        return response;
      }) : []
    },
    saveGoalsActions(data) {
      let itemInfos = data.item.split('-');
      const category = itemInfos[0];
      let subItem = null;
      if(itemInfos.length === 3) {
        subItem = itemInfos[1];
      }
      const name = itemInfos[itemInfos.length - 1];

      this.setSyntheseGoalProperty({
        item: data.item,
        problem: data.problem,
        goal: data.goal,
        actions: data.actions,
        affectation: data.affectation,
        active: data.active
      });
      this.saveSyntheseGoals({
        name: name,
        category: category,
        subItem: subItem
      });
    },
    changeGoalActive(row) {
      this.saveGoalsActions(row);
    },
    getAggirAnswerScore(questionId) {
      const answer = this.evaluation.answerList ? this.evaluation.answerList.find(ans => ans.question.id === questionId) : null;
      if (answer) {
        return answer.itemScore
      }
      return null;
    }
  },
  computed: {
    ...mapGetters('AggirQuestion', [
      'getScoreQuestionAggirMoveOut',
      'getScoreQuestionAggirMoveIn',
      'getScoreQuestionAggirTransfert',
      'getScoreQuestionAggirOrientationTemps',
      'getScoreQuestionAggirOrientationEspace',
      'getScoreQuestionAggirCoherenceComportement',
      'getScoreQuestionAggirCoherenceCommunication',
      'getScoreQuestionAggirEliminationFecale',
      'getScoreQuestionAggirEliminationUrinaire'
    ]),
    ...mapState("Evaluation", ["evaluation", "ruptureEvent", "logementAdaptation", "protectionMeasure", "pec", "socialInteraction", "weakness", "nutrition", "sense", "breathing", "technicalHelp", "entourage", "elimination", "motor","skin", "pain", "brain","treatment","risks","general", "technicalDefault"]),
    ruptureEvents() {
      const ruptureEventsAnswer = this.getQuestionAnswers(3).responsesIds;
      if(!ruptureEventsAnswer) {
        return [];
      }

      const data = [];
      for(let id of ruptureEventsAnswer) {
        if(this.ruptureEvent['r'+(id+1)]) {
          if(('r'+(id+1)) === "r8") {
            const actions = typeof this.ruptureEvent['r'+(id+1)].actions === "string" ? this.ruptureEvent['r'+(id+1)].actions : "";
            data.push({
              ...this.ruptureEvent['r'+(id+1)],
              actions: actions,
              actionsType: "input"
            });
            continue;
          }
          data.push({
            ...this.ruptureEvent['r'+(id+1)],
            actionsType: "select"
          });
        }
      }
      data.push({
        ...this.ruptureEvent.other,
        actionsType: "input",
        problemEditable: true
      });
      return data;
    },

    logement() {
      const data = [];
      const logementAccessAnswer = this.getQuestionAnswers(13).responsesIds ? this.getQuestionAnswers(13).responsesIds : [];
      for(let id of logementAccessAnswer) {
        if(this.logementAdaptation.logement['r'+(id+1)]) {
          data.push({
            ...this.logementAdaptation.logement['r'+(id+1)],
            subItem: 'logement'
          })
        }
      }

      const bedroomAccessAnswer = this.getQuestionAnswers(16).responsesIds ? this.getQuestionAnswers(16).responsesIds : [];
      for(let id of bedroomAccessAnswer) {
        if(this.logementAdaptation.bedroom['r'+(id+1)]) {
          data.push({
            ...this.logementAdaptation.bedroom['r'+(id+1)],
            subItem: 'bedroom'
          })
        }
      }

      const habitatAccessAnswer = this.getQuestionAnswers(17).responsesIds ? this.getQuestionAnswers(17).responsesIds : [];
      for(let id of habitatAccessAnswer) {
        if(this.logementAdaptation.habitat['r'+(id+1)]) {
          data.push({
            ...this.logementAdaptation.habitat['r'+(id+1)],
            subItem: 'habitat'
          })
        }
      }

      const bathroomAccessAnswer = this.getQuestionAnswers(19).responsesIds ? this.getQuestionAnswers(19).responsesIds : [];
      for(let id of bathroomAccessAnswer) {
        if(this.logementAdaptation.bathroom['r'+(id+1)]) {
          data.push({
            ...this.logementAdaptation.bathroom['r'+(id+1)],
            subItem: 'bathroom'
          })
        }
      }

      const toiletAccessAnswer = this.getQuestionAnswers(21).responsesIds ? this.getQuestionAnswers(21).responsesIds : [];
      for(let id of toiletAccessAnswer) {
        if(this.logementAdaptation.toilet['r'+(id+1)]) {
          data.push({
            ...this.logementAdaptation.toilet['r'+(id+1)],
            subItem: 'toilet'
          })
        }
      }

      return data;
    },

    technical() {
      if(!this.evaluation.materials) {
        return [];
      }
      const requiredMaterialList = this.evaluation.materials.filter((link) => link.required).map((link) => link.material);

      const data = [];
      for(let material of requiredMaterialList) {
        const existingValue = this.technicalHelp.find((el) => el.item === ("technicalHelp-" + material.id));
        if(existingValue) {
          data.push({
            ...existingValue,
            type: "materials",
            problem: material.name + " à prévoir"
          })
        } else {
          data.push({
            item: "technicalHelp-"+material.id,
            active: true,
            type: "materials",
            problem: material.name + " à prévoir",
            goal: "Améliorer l'autonomie et la,prévention des TMS par des aides techniques appropriées",
            actions: {
              choices: [
                {value:'r1', label: "Refus de l'aide technique"},
                {value:'r2', label: "Demander l'avis et/ou l'ordonnance du médecin"},
                {value:'r3', label: "Commander l'aide technique"},
                {value:'r4', label: "Contacter un ergothérapeute"}
              ],
              selected: []
            },
            affectation: {
              choices: [
                {value: 'c1', text: "Personne elle-même"},
                {value: 'c2', text: "Conjoint/Famille"},
                {value: 'c3', text: "Responsable secteur SAAD"},
                {value: 'c4', text: "Aide à domicile"},
                {value: 'c5', text: "Psychologue"},
                {value: 'c6', text: "Travailleur social"},
                {value: 'c7', text: "Tuteur/curateur"},
                {value: 'c8', text: "Care Manager"},
              ],
              selected: []
            }
          })
        }
      }

      if(requiredMaterialList.length === 0) {
        data.push(this.technicalDefault.default);
      }

      return data;
    },

    protection() {
      const protectionAnswer = this.getQuestionAnswers(27) ? this.getQuestionAnswers(27) : [];
      const data = [];

      data.push(this.protectionMeasure.r1);
      if(protectionAnswer && protectionAnswer.responsesIds && protectionAnswer.responsesIds[0] !== 0) {
        data.push({
          ...this.protectionMeasure.other,
          problem: protectionAnswer.list[0]
        });
      }

      return data;
    },

    pecRows() {
      const pecAnswer = this.getQuestionAnswers(37) ? this.getQuestionAnswers(37) : [];
      const data = [];
      if(pecAnswer && pecAnswer.responsesIds && pecAnswer.responsesIds[0] === 3) {
        data.push({
          ...this.pec.r4,
          type: 'noPec'
        });
      }

      if(pecAnswer && pecAnswer.responsesIds && pecAnswer.responsesIds[0] !== 3) {
        const problemValues = [];
        problemValues.push("Plan d'aide: " + pecAnswer.list[0]);
        const actualGirAnswer = this.getQuestionAnswers(43) ? this.getQuestionAnswers(43).list : "";
        problemValues.push("GIR du plan d'aide actuel: " + actualGirAnswer[0]);

        const prestationType = this.getQuestionAnswers(45) ? this.getQuestionAnswers(45).list : [];
        problemValues.push("Type de prestaire: " + prestationType.join(', '));

        data.push({
          ...this.pec.other,
          type: 'pec',
          problem: problemValues
        });
      }

      return data;
    },

    social() {
      const eps11Answer = this.getQuestionAnswers(35).responsesIds ? this.getQuestionAnswers(35).responsesIds : [];
      const eps06Answer = this.getQuestionAnswers(30) ? this.getQuestionAnswers(30) : [];
      const eps12Answer = this.getQuestionAnswers(36) ? this.getQuestionAnswers(36) : [];
      const data = [];

      if(eps11Answer && eps11Answer[0] === 0) {
        data.push({
          ...this.socialInteraction.eps11,
          problem: "Souhait de la personne d'autres activités sociales: " + (eps12Answer && eps12Answer.list[0] ? eps12Answer.list[0] : "")
        })
      }

      if(eps06Answer && eps06Answer.list[0] === 0) {
        data.push({
          ...this.socialInteraction.eps06,
          problem: "Précautions a prendre vis-à-vis de l'animal de compagnie: " + eps06Answer.list[0]
        })
      }

      return data;
    },

    entourageRow() {
      return this.entourage.default;
    },

    weak() {
      return [
        ...this.nutritionRows,
        ...this.senseRows,
        ...this.breathingRows,
        ...this.eliminationRows,
        ...this.motorRows,
        ...this.skinRows,
        ...this.painRows,
        ...this.brainRows,
        ...this.treatmentRows
      ];
    },
    nutritionRows () {
      const size = this.nutrition && this.nutrition.sn01size.response ? this.nutrition.sn01size.response : null;
      const weight = this.nutrition && this.nutrition.sn01weight.response ? this.nutrition.sn01weight.response : null;
      const imc = imcCalcul(this.nutrition.sn01weight.response, this.nutrition.sn01size.response);
      const coherence = this.getAggirAnswerScore(23);
      const mna = mnaCalcul(this.getPeopleStr, imc, this.nutrition.sn03.response, this.nutrition.sn04.response, this.nutrition.sn05.response, this.nutrition.sn06.response, this.getScoreQuestionAggirMoveIn, coherence);
      const sn03 = this.nutrition && this.nutrition.sn03.response ? this.nutrition.sn03.response : null;
      const sn04 = this.nutrition && this.nutrition.sn04.response ? this.nutrition.sn04.response : null;
      const sn05 = this.nutrition && this.nutrition.sn05.response ? this.nutrition.sn05.response : null;
      const sn07 = this.nutrition && this.nutrition.sn07.response ? this.nutrition.sn07.response : null;
      const sn09 = this.nutrition && this.nutrition.sn09.response ? this.nutrition.sn09.response : null;
      const sn11 = this.nutrition && this.nutrition.sn11.response ? this.nutrition.sn11.response : null;


      const data = [];

      let interpretation = '' ;

      if(size && weight) {
        interpretation = imc && imc <= 16 ? "est en dénutrition sévère" : (imc > 16 && imc <= 18 ? "est en dénutrition modérée" : (imc > 18 && imc <= 25 ? "est de poids normal" : (imc > 25 && imc <= 30 ? "est en surpoids" : (imc > 30 && imc <= 40 ? "présente une obésité modérée" : (imc > 40 ? "présente une obésité sévère" : "")))));
      }

      let sn15 = '';
      let sn16 = '';
      let sn17 = '';

      if(sn03 && sn03 === "r1") {
        sn15 = this.nutrition && this.nutrition.sn15.response ? (this.nutrition.sn15.response === "r1" ? "Sonde naso-gastrique/naso-jejunale" : "Sonde de gastrostomie/jejunostomie") : "non renseigné";
        sn16 = this.nutrition && this.nutrition.sn16.response ? (this.nutrition.sn16.response === "r1" ? "Oui" : (this.nutrition.sn16.response === "r2" ? "Non" : "NSP")): "non renseigné";

        const sn17List = [{value:'r1', label:'Personne elle-même'},
          {value:'r2', label:'Conjoint/famille'},
          {value:'r3', label:'Infirmière/service de soin'},
          {value:'r4', label:'NSP'}];
        const findValue = this.nutrition && this.nutrition.sn17.response ? sn17List.find((el) => el.value === this.nutrition.sn17.response) : null;
        sn17 = findValue ? findValue.label : this.DEFAULT_VALUE;
      }

      let sn04Result = '';
      let sn05Responses = [];
      let sn05Result = '';

      if((sn04 && sn04 !== "r3") || (sn05 && sn05 !== "r4")) {
        sn04Result = (sn04 === "r1" ? "Baisse sévère des prises alimentaires" : "Legère baisse des prises alimentaires");
        sn05Responses = [
          {value:'r1', label:'Perte de poids > 3 kg'},
          {value:'r2', label:'Ne sait pas'},
          {value:'r3', label:'Perte de poids entre 1 et 3 kg'}
        ];

        let sn05ResponsesFind = sn05Responses.find((el) => el.value === sn05)
        sn05Result = sn05ResponsesFind ? sn05ResponsesFind.label : '';
      }

      let responses = ''
      if(sn07 && sn07 === 'r1') {
        const sn08 = this.nutrition && this.nutrition.sn08.response ? this.nutrition.sn08.response : null;
        const responsesList = [
          {value:'r1', label:'Anti cholestérol'},
          {value:'r2', label:'Anti triglycérides'},
          {value:'r3', label:'Anti diarrhéique'},
          {value:'r4', label:'Diabétique'},
          {value:'r5', label:'Épargne colique, constipation'},
          {value:'r6', label:'Hypo protidique'},
          {value:'r7', label:'Hyper protidique'},
          {value:'r8', label:'Sans sel'},
          {value:'r9', label:'Hypo sodé'}];

        responses = sn08 ? sn08.split(',').map((resp) => {
          const res = responsesList.find((el) => el.value === resp);
          return res ? res.label : ""
        }).join(", ") : "";
      }

      let sn10 = '';
      if(sn09 && sn09 === "r1") {
        sn10 = this.nutrition && this.nutrition.sn10.response ? this.nutrition.sn10.response : "non renseigné";
      }

      let sn13Value = '';
      let sn12 = null;
      if(sn11 && sn11 === "r1") {
        sn12 = this.nutrition && this.nutrition.sn12.response ? this.nutrition.sn12.response : null;
        const sn13 = this.nutrition && this.nutrition.sn13.response ? this.nutrition.sn13.response : null;
        const responsesList = [{value:'r1', label:'Normale'},
          {value:'r2', label:'Hachée'},
          {value:'r3', label:'Mixée'}
        ];
        sn13Value = sn13 ? (responsesList.find((el) => el.value === sn13) ? responsesList.find((el) => el.value === sn13).label : "") : "";
      }

      if ((imc && imc <= 18.5 || imc > 25) || (mna && mna.score !== "--" && mna.score < 12) || ((sn04 && sn04 !== "r3") || (sn05 && sn05 !== "r4")) || ((sn03 || sn07 || sn09 || sn11) === "r1")){

        data.push({
          ...this.weakness.nutrition.general,
          goal : [
            (imc <= 18.5 || imc > 25) ? this.weakness.nutrition.imc.goal + ',' : '' +
            ((mna && mna.score !== "--" && mna.score < 12) ||
                ((sn04 && sn04 !== "r3") || (sn05 && sn05 !== "r4"))) ? this.weakness.nutrition.mna.goal+ ',' : '' +
            (sn03 && sn03 === "r1") ? this.weakness.nutrition.sonde.goal+ ',' : '' +
            (sn07 && sn07 === 'r1') ? this.weakness.nutrition.regime.goal+ ',' : '' +
            (sn09 && sn09 === "r1") ? this.weakness.nutrition.allergy.goal+ ',' : '' +
            (sn11 && sn11 === "r1") ? this.weakness.nutrition.eating.goal+ ',' : ''
          ],
          problem: ["NUTRITIONNELLES",
            (imc <= 18.5 || imc > 25) ? ("IMC: "+imc+", " + this.getPeopleStr +" "+interpretation) : '',
            (mna && mna.score !== "--" && mna.score < 12) ? "MNA de dépistage : " + mna.score + "/14" : '',
            (mna && mna.score !== "--" && mna.score < 12) ? mna.interpretation : '',
            ((sn04 && sn04 !== "r3") || (sn05 && sn05 !== "r4")) ? "ICOPE NUTRITION :" : '',
            ((sn04 && sn04 !== "r3") || (sn05 && sn05 !== "r4")) ? "Perte d'appétit récente : " + sn04Result : '',
            ((sn04 && sn04 !== "r3") || (sn05 && sn05 !== "r4")) ? "Perte de poids récente : " + sn05Result : '',
            (sn03 && sn03 === "r1") ? 'Alimentation entérale par sonde de nutrition :'+ sn15 : '',
            (sn03 && sn03 === "r1") ? 'Gérée par : '+sn17 : '',
            (sn03 && sn03 === "r1") ? 'Possibilité d\'hydratation par la bouche : ' + sn16 : '',
            (sn07 && sn07 === 'r1') ? 'Régime: '+ responses : '',
            (sn09 && sn09 === "r1") ? 'Allergies: '+ sn10 : '',
            (sn11 && sn11 === "r1") ? "Difficultés de mastication ou déglutition avec l'alimentation de type " + sn13Value + ": " + sn12 : ''
          ],
          affectationType: 'input'
        })
      }

      return data;
    },

    senseRows() {
      const data = [];

      const sos02 = this.sense && this.sense.sos02.response ? this.sense.sos02.response : null;
      const sos05 = this.sense && this.sense.sos05.response ? this.sense.sos05.response : null;
      const sos07 = this.sense && this.sense.sos07.response ? this.sense.sos07.response : null;
      const sos08 = this.sense && this.sense.sos08.response ? this.sense.sos08.response : null;
      const sos08Responses = [
        {value:'r1', label:'Répète correctement'},
        {value:'r2', label:'Répète incorrectement ou ne répète pas'},
        {value:'r3', label:'NSP'},
        {value:'r4', label:'Test non réalisé'},
        {value:'r5', label:'Test non réalisable'},
      ];
      const sos07Result = sos07 ? sos08Responses.find((el) => el.value === sos07).label : "";
      const sos08Result = sos08 ? sos08Responses.find((el) => el.value === sos08).label : "";
      const sos04 = this.sense && this.sense.sos04.response ? this.sense.sos04.response : null;
      const responsesList = [
        {value:'r1', label:'Perte de sensibilité'},
        {value:'r2', label:'Sensation douloureuse'},
        {value:'r3', label:'Les deux'},
        {value:'r4', label:'Aucune perte ou sensation douloureuse au toucher'},
      ];

      const responses = responsesList.find((el) => el.value === sos04) ? responsesList.find((el) => el.value === sos04).label  : this.DEFAULT_VALUE;
      if(responses !== this.DEFAULT_VALUE){
        data.push({
          ...this.weakness.sense.icopeSight,
          ...this.weakness.sense.earing,
          ...this.weakness.sense.icopeEaring,
          ...this.weakness.sense.sensibility,
          ...this.weakness.sense.general,
          goal:[
            (sos02 && sos02 === "r1") ? this.weakness.sense.icopeSight.goal : '',
            ((sos05 && sos05 === "r1") ||
                ((sos07 && sos07 === "r2") || (sos08 && sos08 === "r2"))) ? this.weakness.sense.earing.goal : '' ,
            (sos04 && (sos04 === "r1" || sos04 === "r2" || sos04 === "r3")) ? this.weakness.sense.sensibility.goal : ''
          ],
          problem: [
            "ORGANES DES SENS",
            (sos02 && sos02 === "r1") ? "COPE DEFICIENCE VISUELLE : Oui : Une évaluation complète de la vue est recommandée." : '',
            (sos05 && sos05 === "r1") ? "Perte récente d'audition : Oui" : '',
            (sos07 && sos07 === "r2") ? "Test de WHISPER OD : " + sos07Result : '',
            (sos08 && sos08 === "r2") ? "Test de WHISPER OG : " + sos08Result: '',
            ((sos07 && sos07 === "r2") || (sos08 && sos08 === "r2")) ? "ICOPE DEFICIENCE AUDITIVE :" + getICOPEAudition((this.sense && this.sense.sos07.response ? this.sense.sos07.response : null),sos07, sos08) : '',
            ((sos04 && sos04 === "r1") || (sos04 && sos04 === "r2") || (sos04 && sos04 === "r3")) ? "Troubles de la sensibilité : " + responses : ''
          ],
          affectationType: 'input'
        })
      }

      return data;
    },
    breathingRows() {
      const data = [];
      const src01 = this.breathing && this.breathing.src01.response ? this.breathing.src01.response : null;
      const src02 = this.breathing && this.breathing.src02.response ? this.breathing.src02.response : null;
      const src05 = this.breathing && this.breathing.src05.response ? this.breathing.src05.response : null;

      let formattedAnswers01 = []
      let formattedAnswers02 = []
      let responsesList01 = []
      let responsesList02 = []

      if(src01 && !src01.split(",").includes('r1')){
        formattedAnswers01 = src01.split(",");
        responsesList01 = [
          {value:'r1', label:'Jamais'},
          {value:'r2', label:'A l\'effort'},
          {value:'r3', label:'Au repos'},
          {value:'r4', label:'La journée'},
          {value:'r5', label:'La nuit'},
          {value:'r6', label:'Fumeur(euse) actuel'},
          {value:'r7', label:'Ancien fumeur(euse)'},
        ];
      }
      const answers01 = formattedAnswers01.map((answer) => responsesList01.find((el) => el.value === answer).label);

      if(src02 && !src02.split(",").includes('r1')){
        formattedAnswers02 = src02.split(",");
        responsesList02 = [{value:'r1', label:'Jamais'},
          {value:'r4', label:'La journée'},
          {value:'r5', label:'La nuit'}
        ];
      }

      const answers02 = formattedAnswers02?.map((answer) => responsesList02.find((el) => el.value === answer)?.label);

      if((src01 && !src01.split(",").includes('r1')) || (src02 && !src02.split(",").includes('r1')) || (src05 && !src05.split(",").includes('r1'))){
        data.push({
          ...this.weakness.breathing.breathingDifficulties,
          ...this.weakness.breathing.oxygen,
          ...this.weakness.breathing.member,
          ...this.weakness.breathing.general,
          goal : [
            ((src01 && !src01.split(",").includes('r1')) ||
                (src02 && !src02.split(",").includes('r1'))) ? this.weakness.breathing.breathingDifficulties.goal : '',
            (src05 && !src05.split(",").includes('r1')) ? this.weakness.breathing.member.goal : '',
          ],
          problem: [
            (src01 && !src01.split(",").includes('r1')) || (src05 && !src05.split(",").includes('r1')) ? "RESPIRATION" : '',
            (src01 && !src01.split(",").includes('r1')) ? ("Difficultés pour respirer : " + answers01.join(", ") ): '',
            (src02 && !src02.split(",").includes('r1')) ? "Oxygène médical sur prescription médicale : " + answers02.join(", ") : '',
            (src05 && !src05.split(",").includes('r1')) ? "Oedèmes des membres inférieurs" : ''
          ],
          affectationType: 'input'
        })
      }

      return data;
    },
    eliminationRows() {
      const data = [];

      //Pour les troubles Urinaire
      const sfe07 = this.elimination && this.elimination.sfe07.response ? this.elimination.sfe07.response : null;
      const sfe08 = this.elimination && this.elimination.sfe08.response ? this.elimination.sfe08.response.split(',') : [];

      const sfe08Result = sfe08.reduce((acc, answer) => {
        const sfe08Responses = [
          {value:'r1', label:'Aucune'},
          {value:'r2', label:'Protection anatomiques'},
          {value:'r3', label:'Change complet'},
          {value:'r4', label:'La journée'},
          {value:'r5', label:'La nuit'}
        ];
        const resp = sfe08Responses.find((el) => el.value === answer);
        if(resp) {return [...acc, resp.label]}
        return [...acc];
      },[]).join(", ");

      let formattedAnswers = [];
      let responsesList = [];

      if(sfe07 && !sfe07.split(",").includes('r4')){
        formattedAnswers = sfe07.split(",");
        responsesList = [
          {value:'r1', label:'Difficulté a se retenir'},
          {value:'r2', label:'Incontinence'},
          {value:'r3', label:'Brulures à la miction'},
          {value:'r4', label:'Aucune difficulté urinaire'}
        ];
      }
      const answers = formattedAnswers.map((answer) => responsesList.find((el) => el.value === answer).label);


      const sfe04 = this.elimination && this.elimination.sfe04.response ? this.elimination.sfe04.response : null;

      let formattedAnswers2 = [];
      let responsesList2 = [];

      if(sfe04 && !sfe04.split(",").includes('r7')){
        formattedAnswers2 = sfe04.split(",");
        responsesList2 = [
          {value:'r1', label:'Constipation'},
          {value:'r2', label:'Gaz, flatulences'},
          {value:'r3', label:'Diarrhées'},
          {value:'r4', label:'Maux d\'estomac'},
          {value:'r5', label:'Parfois'},
          {value:'r6', label:'Souvent'},
          {value:'r7', label:'Pas de troubles digestitif'}
        ];
      }

      const answers2 = formattedAnswers2.map((answer) => responsesList2.find((el) => el.value === answer).label);

      const sfe01 = this.elimination && this.elimination.sfe01.response ? this.elimination.sfe01.response : null;
      const sfe02 = this.elimination && this.elimination.sfe02.response ? this.elimination.sfe02.response : null;
      const sfe02Responses = [{value:'r1', label:'Urostomie'},
        {value:'r2', label:'Ileostomie'},
        {value:'r3', label:'Colostomie'},
        {value:'r4', label:'NSP'}];
      const sfe03 = this.elimination && this.elimination.sfe03.response ? this.elimination.sfe03.response : null;
      const sfe03b = this.elimination && this.elimination.sfe03b.response ? this.elimination.sfe03b.response : null;
      const sfe03Responses = [{value:'r1', label:'La personne elle-même'},
        {value:'r2', label:'Sa famille'},
        {value:'r3', label:'Un SAAD ou un aidant professionnel'},
        {value:'r4', label:'Une infirmière libérale ou un Service de soins'}];

      const sfe05 = this.elimination && this.elimination.sfe05.response ? this.elimination.sfe05.response : null;

      if ((sfe07 && sfe07 !== "r4") || (sfe04 && sfe04 !== "r7") || (sfe01 && sfe01 === "r1") || (sfe05 && sfe05 === ("r2" || "r3")) || (sfe08 && sfe08.length !== 0 && sfe08 !== "r1")){
        data.push({
          ...this.weakness.elimination.urineDifficulties,
          ...this.weakness.elimination.transitTroubles,
          ...this.weakness.elimination.stomia,
          ...this.weakness.elimination.sonde,
          ...this.weakness.elimination.protection,
          ...this.weakness.elimination.general,
          goals: [
            (sfe07 && !sfe07.split(",").includes('r4')) ? this.weakness.elimination.urineDifficulties.goal : '',
            (sfe04 && !sfe04.split(",").includes('r7')) ? this.weakness.elimination.transitTroubles.goal : '',
            (sfe01 && sfe01 === "r1") ? this.weakness.elimination.stomia.goal : '',
            (sfe05 && (sfe05.split(",").includes('r2') || sfe05.split(",").includes('r3'))) ? this.weakness.elimination.sonde.goal : '',
            (sfe08 && !sfe08.includes("r1")) ? this.weakness.elimination.protection.goal : ''
          ],
          problem: [
            "FONCTIONS D'ELIMINATION",
            (sfe07 && !sfe07.split(",").includes('r4')) ? "Troubles pour uriner : " + answers.join(", ") : '',
            (sfe07 && !sfe07.split(",").includes('r4')) ? "Port de protection : " + sfe08Result : '',
            (sfe04 && !sfe04.split(",").includes('r7')) ? "Troubles digestifs : " + answers2.join(", ") : '',
            (sfe01 && sfe01 === "r1") ? ("Stomie : " + (sfe02 ? sfe02.split(",").map((answer) => sfe02Responses.find((el) => el.value === answer).label).join(", ") : "")) : '',
            (sfe01 && sfe01 === "r1") ? ("Gestion poche : " + (sfe03 ? sfe03.split(",").map((answer) => sfe03Responses.find((el) => el.value === answer).label).join(", ") : "")) : '',
            (sfe01 && sfe01 === "r1") ? ("Gestion support : " + (sfe03b ? sfe03b.split(",").map((answer) => sfe03Responses.find((el) => el.value === answer).label).join(", ") : "")) : '',
            (sfe05 && (sfe05.split(",").includes('r2') || sfe05.split(",").includes('r3'))) ? ("Dispositif urinaire : " + (sfe02 ? sfe02.split(",").map((answer) => sfe02Responses.find((el) => el.value === answer).label).join(", ") : "")) : '',
            (sfe05 && (sfe05.split(",").includes('r2') || sfe05.split(",").includes('r3'))) ? ("Gestion collecteur : " + (sfe03 ? sfe03.split(",").map((answer) => sfe03Responses.find((el) => el.value === answer).label).join(", ") : "")) : '',
            (sfe08 && !sfe08.includes("r1")) ? "Protections hygièniques : " + sfe08Result : '',
          ],
          affectationType: 'input'
        })
      }
      return data;
    },

    motorRows() {
      const data = [];
      const sfm01 = this.motor && this.motor.sfm01.response ? this.motor.sfm01.response.split(',') : [];
      const sfm03 = this.motor && this.motor.sfm03.response ? this.motor.sfm03.response : null;
      const sfm04 = this.motor && this.motor.sfm04.response ? this.motor.sfm04.response : null;
      const bal = balance(this.getPeopleStr, this.motor.sfm101.response, this.motor.sfm102.response, this.motor.sfm103.response);
      const stateResponse = {
          "r1": "Non",
          "r2": "A droite",
          "r3": "A gauche",
          "r4": "Membre supérieur",
          "r5": "Membre inférieur",
          "r6": "Faciale",
          "r7": "Complet",
          "r8": "Incomplet",
          "r9": "Origine vasculaire",
          "r10": "Origine neurologique",
          "r11": "Origine inconnue ou autre"
        }

      const sfm01Result = sfm01.reduce((acc, answer) => {
        if(stateResponse[answer]) {
          return [...acc, stateResponse[answer]]
        }
        return acc;
      },[]).join(", ");

      data.push({
        ...this.weakness.motor.general,
        goal : [
          ((sfm01 && !sfm01.includes('r1')) || (sfm03 && parseFloat(sfm03) > 13.7) || (bal && bal.total !== 4) || (sfm04 && parseFloat(sfm04) > 6.21)) ? this.weakness.motor.general.goal : '',
        ],
        problem: [
          "MOTRICITE",
          sfm01 && !sfm01.includes('r1') ? "Déficit moteur : " + sfm01Result : '',
          sfm03 && parseFloat(sfm03) > 13.7 ? "Troubles de la mobilité : Test du lever de chaise: " + sfm03 : '',
          (bal && bal.total !== 4) ? "Troubles de l'équilibre: Test d'équilibre : " + bal.interpretation : '',
          sfm04 && parseFloat(sfm04) > 6.21 ? "ICOPE MOBILITE : Troubles de la marche: Test de la marche: " + sfm04 + "s" : ''
        ],
        affectationType: 'input'
      })
      return data;
    },

    skinRows() {
      const data = [];
      const norton = nortonCalcul(
            this.getPeopleStr,
            this.evaluation.gir,
            this.getScoreQuestionAggirOrientationTemps,
            this.getScoreQuestionAggirOrientationEspace,
            this.getScoreQuestionAggirCoherenceComportement,
            this.getScoreQuestionAggirCoherenceCommunication,
            this.getScoreQuestionAggirMoveIn,
            this.getScoreQuestionAggirTransfert,
            this.getScoreQuestionAggirEliminationFecale,
            this.getScoreQuestionAggirEliminationUrinaire);
      const spt04 = this.skin && this.skin.spt04.response ? this.skin.spt04.response : null;
      const spt05 = this.skin && this.skin.spt05.response ? this.skin.spt05.response : null;

      if((norton.total < 17) || (spt04 && spt04 === "r1")){
        data.push({
          ...this.weakness.skin.norton,
          ...this.weakness.skin.other,
          ...this.weakness.skin.general,
          goal: [
            (norton.total < 17) ? this.weakness.skin.norton.goal : '',
            (spt04 && spt04 === "r1") ? this.weakness.skin.other.goal : ''
          ],
          problem: [
            "PEAU",
            (norton.total < 17) ? "Echelle de NORTON : " + norton.total : '',
            (spt04 && spt04 === "r1")? "Autres problèmes cutanés : " + (spt05 ? spt05 : "") : ''
          ],
          affectationType: 'input'
        })
      }
      return data;
    },

    painRows() {
      const data = [];
      const sd02 = this.pain && this.pain.sd02.response ? this.pain.sd02.response : null;
      if(sd02 && sd02 === "r1") {

        const sd03 = this.pain && this.pain.sd03.response ? this.pain.sd03.response : null;
        const sd05 = this.pain && this.pain.sd05.response ? this.pain.sd05.response : null;

        data.push({
          ...this.weakness.pain.pain,
          problem: [
            "DOULEUR",
            "Douleur : " + (sd05 ? (sd05 === "r1" ? "Oui" : "Non" ) : ""),
            `Echelle EVA de la douleur :  ${sd03 ? sd03[1] + "/10" : ""}`
          ],
          affectationType: 'input'
        })
      }

      return data;
    },

    brainRows() {
      const data = [];

      const miniCog = miniCogCalcul(this.getPeopleStr, this.brain.smd03.response, this.brain.smd02.response);
      const miniGds = miniGdsCalcul(this.getPeopleStr, this.brain.smd04.response, this.brain.smd05.response, this.brain.smd06.response, this.brain.smd07.response)
      const smd04 = this.brain && this.brain.smd04.response ? this.brain.smd04.response : null;
      const smd05 = this.brain && this.brain.smd05.response ? this.brain.smd05.response : null;
      data.push({
        ...this.weakness.brain.miniCog,
        problem: [
          (miniCog && miniCog.total < 4)?"COGNITION & MEMOIRE" : "",
          (miniCog && miniCog.total < 4)?"MINI COG : " + miniCog.total : '',
          (miniCog && miniCog.total < 4)?"ICOPE COGNITION : "+ getICOPECognition(this.brain.smd03.response, this.getScoreQuestionAggirOrientationTemps, this.getScoreQuestionAggirOrientationEspace) :'',
          (miniCog && miniCog.total < 4)?"MINI GDS : " + miniGds.interpretation :'',
          ((smd04 && (smd04 === "r1")) || (smd05 && (smd05 === "r1")) ? "ICOPE DEPRESSION : " + getRSMD05(this.brain.smd04.response, this.brain.smd05.response) : '')
        ],
        affectationType: 'input'
      })
      return data;
    },

    treatmentRows() {
      const data = [];
      const stsm01 = this.treatment
      && this.treatment.stsm01.response
      && this.treatment.stsm01.response !== 'r8' ? (parseInt(this.treatment.stsm01.response.replace('r', '')) -1) : null;
      const stsm07 = this.treatment && this.treatment.stsm07.response ? this.treatment.stsm07.response : null;

      if ((stsm01 !== null && stsm01 > 4) || (stsm07 && (stsm07 === "r2" || stsm07 === "r3")) ) {
        data.push({
          ...this.weakness.treatment.drugs,
          ...this.weakness.treatment.doctor,
          ...this.weakness.treatment.general,
          goal : [
            (stsm01 !== null && stsm01 > 4) ? this.weakness.treatment.drugs.goal : '',
            (stsm07 && (stsm07 === "r2" || stsm07 === "r3")) ? this.weakness.treatment.doctor.goal : '',
          ],
          problem: [
            "TRAITEMENT & SUIVI",
            (stsm01 !== null && stsm01 > 4) ? "Nombre de médicaments différents pris par jour : " + stsm01 : '',
            (stsm07 && (stsm07 === "r2" || stsm07 === "r3")) ? "Médecin traitant déclaré: " + (stsm07 === "r2" ? "Non" : "NSP") : ''
          ],
          affectationType: 'input'
        });
      }
      return data;
    },
    risksRows() {
      const data = [];
      for(let riskLink of this.evaluation.risks) {
        const existingValue = this.risks.find((el) => el.item === ("risks-" + riskLink.id));
        if(existingValue) {
          data.push({
            ...existingValue,
            problem: riskLink.risk.name
          })
        } else if (riskLink.checked) {
          data.push({
            item: "risks-" + riskLink.id,
            active: true,
            problem: riskLink.risk.name,
            goal: "",
            actions: "",
            affectation: {
              choices: [
                {value: 'c1', text: "Personne elle-même"},
                {value: 'c2', text: "Conjoint/Famille"},
                {value: 'c3', text: "Responsable secteur SAAD"},
                {value: 'c4', text: "Aide à domicile"},
                {value: 'c5', text: "Psychologue"},
                {value: 'c6', text: "Travailleur social"},
                {value: 'c7', text: "Tuteur/curateur"},
                {value: 'c8', text: "Care Manager"},
              ],
              selected: []
            }
          })
        }
      }
      return data;
    }

  }
};
</script>
