import gql from 'graphql-tag'

export const CONTRACT_LIST = gql`
    query contractList(
        $filters: [String!]!,
        $sortColumn: String!,
        $sortDirection: String!,
        $limit: Int!,
        $offset: Int!
    ) {
        contractList(
            filters: $filters,
            sortColumn: $sortColumn,
            sortDirection: $sortDirection,
            limit: $limit,
            offset: $offset
        ) {
            items {
                id,
                client {
                    id,
                    erpId
                    statusSync
                    folderNumber
                    lastname
                    firstname
                    birthName
                    sex
                    address
                    city
                    postalCode
                    sector
                    housingAccess
                    birthDate
                    age
                    socialSecurityNumber
                    digicode
                    sitePicture
                    homePhoneNumber
                    portablePhoneNumber
                    girNumber
                    missing
                    familyStatus
                    csp
                    protectiveMeasure
                    additionalInformations
                },
                agency {
                    id,
                    companyName,
                    structure {
                        id,
                        name
                    }
                }
                dateStart,
                dateEnd,
                statusErp, 
                updatedAt,
                priceByMonth,
                nbHourByMonth,
                createdBy{
                    id,
                    firstName,
                    lastName,
                    email,
                    agency {
                        id,
                        companyName,
                        structure {
                            id,
                            name
                        }
                    }
                },
                updatedBy{
                    id,
                    firstName,
                    lastName
                }
            },
            total
        }
    }
`