<template>
    <custom-modal :active="active" @close="close" :bgDark="true" :id="'custom-modal-materials'">
        <div class="id-modal">
            <div class="id-modal--header">
                <h2 class="id-modal--title">Paramétrage des pièces justificatives</h2>
            </div>
            <div class="col-12 text-center my-5">
                <button class="btn btn-primary" @click="toggleCreateDocument()">
                    Créer une pièce justificative
                    <img src="@/assets/add.svg" />
                </button>
            </div>
            <div class="document__content" :class="{ 'document__overflow': isOverflow }">
                <table class="document__table" :class="{ 'document__table--overflow': isOverflow }">
                    <thead>
                        <tr>
                            <th scope="col">Code</th>
                            <th scope="col">Libellé</th>
                            <th scope="col" class="text-center">Haut niveau de confiance</th>
                            <th scope="col" class="text-right">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="this.createDocument">
                            <td colspan="1">
                                <input v-if="createDocument" type="text" v-model="code"/>
                            </td>
                            <td colspan="1">
                                <input v-if="createDocument" type="text" v-model="libelle"/>
                            </td>
                            <td colspan="1">
                                <label class="custom-checkbox">
                                    <input type="checkbox" v-model="trusted"/>
                                    <span class="checkmark"></span>
                                </label>
                            </td>
                            <td colspan="1" class="text-right">
                                <button class="document__button" disabled><img src="@/assets/edit.svg"/></button>
                                <button class="document__button" disabled><img src="@/assets/trash.svg"/></button>
                            </td>
                        </tr>
                        <tr v-for="document in getDocuments" :key="document.id">
                            <td colspan="1">
                                <input v-if="editDocument == document.id" type="text" v-model="updateCode"/>
                                {{ editDocument != document.id ? document.code : null }}
                            </td>
                            <td colspan="1">
                                <input v-if="editDocument == document.id" type="text" v-model="updateLibelle"/>
                                {{ editDocument != document.id ? document.libelle : null }}
                            </td>
                            <td colspan="1">
                                <label class="custom-checkbox">
                                    <input v-if="editDocument == document.id" type="checkbox" :checked="document.trusted" :disabled="editDocument != document.id" v-model="updateTrusted"/>
                                    <input v-else type="checkbox" :checked="document.trusted" :disabled="editDocument != document.id"/>
                                    <span class="checkmark"></span>
                                </label>
                            </td>
                            <td colspan="1" class="text-right">
                                <button class="document__button" @click.prevent="edit(document.id)"><img src="@/assets/edit.svg"/></button>
                                <button :hidden="document.canDelete" class="document__button" @click.prevent="trash(document.id)"><img src="@/assets/trash.svg"/></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 text-center">
                <button class="mt-2 btn mt-4 mb-4 btn-outline-primary"
                    @click.prevent="saveDocument"
                >
                    Sauvegarder
                </button>
            </div>
        </div>
    </custom-modal>
</template>
<script>
import CustomModal from "@/components/Util/Modal";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    name: "AddIdentityDocumentModal",
    components: {
        CustomModal
    },
    data() {
        return {
            editDocument: null,
            createDocument: false,
            code: null,
            libelle: null,
            trusted: false,
            updateDocument: [],
            updateTrusted: null,
            updateLibelle: null,
            updateCode: null
        }
    },
    props: {
        active: {
            type: Boolean
        }
    },
    methods: {
        ...mapActions(
            'IdentityDocument',
            [
                'deleteIdentityDocuments',
                'postIdentityDocuments',
                'getIdentityDocuments',
                'putIdentityDocuments'
            ]
        ),
        ...mapMutations('IdentityDocument', 
        [
            'updateIdentityDocuments',
            'documentUpdated'
        ]),
        close() {
            this.$emit("close");
        },
        edit(id) {
            if (this.editDocument == id) {
                if (this.updateCode != null || this.updateLibelle != null || this.updateTrusted != null) {
                    this.update(id);
                }
                return this.editDocument = null;
            }
            this.updateField(id)
            this.editDocument = id;
        },
        toggleCreateDocument() {
            this.createDocument = !this.createDocument;
            this.code = '';
            this.libelle = '';
            this.trusted = false;
        },
        trash(id) {
            this.deleteIdentityDocuments({ id: id });
        },
        saveDocument() {
            if (this.createDocument && this.code && this.libelle) {
                this.postIdentityDocuments({
                    payload: {
                        code: this.code,
                        libelle: this.libelle,
                        trusted: this.trusted
                    }
                }).then(() => {
                    this.toggleCreateDocument();
                    this.getIdentityDocuments()
                });
            } else if (this.updateDocument.length > 0) {
                this.updateDocument.forEach(doc => {
                    this.putIdentityDocuments({
                        id: doc.id,
                        payload: {
                            code: doc.code,
                            libelle: doc.libelle,
                            trusted: doc.trusted
                        }
                    });
                });
                this.updateDocument = [];
            }
            this.$emit("close");
        },
        update(id) {         
            const oldDocument = this.findDocument(id);

            let updateDocument = {
                id: id,
                code: this.updateCode,
                libelle: this.updateLibelle,
                trusted: this.updateTrusted
            };
            
            this.updateIdentityDocuments(
                {
                    id: id,
                    code: this.updateCode != null ? this.updateCode : oldDocument.code,
                    libelle: this.updateLibelle != null ? this.updateLibelle : oldDocument.libelle,
                    trusted: this.updateTrusted != null ? this.updateTrusted : oldDocument.trusted
                }
            );

            this.replaceDocument(updateDocument);
        },
        findDocument(id) {
            return this.getDocuments.find(doc => doc.id === id);
        },
        replaceDocument(updatedDocument) {
            const index = this.updateDocument.findIndex(doc => doc.id === updatedDocument.id);
            if (index !== -1) {
                return this.updateDocument.splice(index, 1, updatedDocument);
            }
            this.updateDocument.push(updatedDocument);
            this.updateCode = null;
            this.updateLibelle = null;
            this.updateTrusted = null;
        },
        updateField(id) {
            const oldDocument = this.findDocument(id);
            this.updateCode = oldDocument.code;
            this.updateLibelle = oldDocument.libelle;
            this.updateTrusted = oldDocument.trusted;
        }
    },
    computed: {
        ...mapState('IdentityDocument', ['documents']),
        getDocuments() {
            return this.documents
        },
        isOverflow() {
            return this.documents?.length > 6;
        }
    }
}
</script>
<style scoped>

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox {
    display: block;
    text-align: center;
}

.custom-checkbox .checkmark {
  position: relative;
  height: 14px;
  width: 14px;
  background-color: #eee;
  border: 2px solid #4164E3;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  cursor: pointer;
}

.custom-checkbox .checkmark:hover {
  background-color: #ddd;
}

.custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    left: 2px;
    top: 0px;
    width: 6px;
    height: 8px;
    border: solid #4164E3;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  border-color: #4164E3;
}
.addDocumentModal {
    background-color: #F5F5F5;
}

td, td, th {
    padding: 10px;
}
th {
    border-bottom: 1px solid;
}
table thead,
table tbody {
    display: contents;
}
tbody > tr:nth-of-type(odd) {
  background-color: #F5F5F5;
}
::-webkit-scrollbar {
  width: 10px;
  height: 700px;
}
::-webkit-scrollbar-track {
  background: #D9D9D9;
}
::-webkit-scrollbar-thumb {
  background: #4164E3;
}
::-webkit-scrollbar-thumb:hover {
  background: #4164e3b3;
}
</style>