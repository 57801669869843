export default {
    saveClientWithIdentityDocument(state, data) {
        state.response = data.response
    },
    getIdentityDocuments(state, data) {
        state.documents = data
    },
    deleteIdentityDocuments(state, documentId) {
        state.documents = state.documents.filter(document => document.id !== documentId);
    },
    updateIdentityDocuments(state, updatedDocument) {
        const index = state.documents.findIndex(doc => doc.id === updatedDocument.id);
        if (index !== -1) {
            state.documents.splice(index, 1, updatedDocument);
        }
    },
    documentUpdated(state, data) {
        state.updatedDocument = data;
    }
};