<template>
  <div>
    <custom-modal :active="insiModal" @close="insiModalView">
      <div class="d-flex mb-3">
        <img width="42" height="42" class="mr-3" src="../../../assets/insi.png" />
        <h3 class="id-modal--title-insi">Téléservice INSI</h3>
      </div>
      <p>Veuillez saisir les traits d’identité du patient</p>
      <p>Le nom, le prénom et le lieu ne doivent contenir que des caractères majuscules, pas d’accent, ni de cédille. <br/>Seuls les espaces, les apostrophes et les tirets (non doublonnés) sont acceptés.</p>
      <div>
        <div class="row">
          <div class="col-4 mt-4">
            <label for="matriculeIns">Matricule INS</label>
            <input class="form-control" id="matriculeIns" name="matriculeIns" disabled v-model="matriculeIns"/>
          </div>
          <div class="col-4 mt-4">
            <label for="oid">OID</label>
            <input class="form-control" id="oid" name="oid" disabled v-model="oid"/>
          </div>
        </div>
        <div class="row">
          <div class="col-4 mt-4">
            <label for="birthName">Nom de naissance</label>
            <input class="form-control" id="birthName" name="birthName" disabled v-model="birthName"/>
          </div>
          <div class="col-4 mt-4">
            <label for="firstName">Prénom(s)</label>
            <input class="form-control" id="firstName" name="firstName" disabled v-model="firstName"/>
          </div>
        </div>
        <div class="row">
          <div class="col-4 mt-4">
            <label for="birthDate">Date de naissance</label>
            <input class="form-control" id="birthDate" name="birthDate" disabled v-model="birthDateFR"/>
          </div>
          <div class="col-4 mt-4">
            <label for="codeInsee">Commune de naissance</label>
            <input class="form-control" id="codeInsee" name="codeInsee" disabled v-model="codeInsee"/>
          </div>
          <div class="col-4 mt-4">
            <label for="birthName">Sexe</label>
            <input class="form-control" id="sex" name="sex" disabled v-model="sex"/>
          </div>
        </div>
        <div class="text-right">
          <b-button :class="{'disabled-btn': (
            codeInsi != null 
            || disabled 
            || oid == ''
            || oid == null
            || matriculeIns == ''
            || matriculeIns == null
            || codeInsee == ''
            || codeInsee == null
            || sex == ''
            || sex == null
            || birthDateFR == ''
            || birthDateFR == null
            || firstName == ''
            || firstName == null
            || birthName == ''
            || birthName == null
            )}" 
            class="mt-4" 
            :disabled="(codeInsi != null
            || disabled
            || oid == ''
            || oid == null
            || matriculeIns == ''
            || matriculeIns == null
            || codeInsee == ''
            || codeInsee == null
            || sex == ''
            || sex == null
            || birthDateFR == ''
            || birthDateFR == null
            || firstName == ''
            || firstName == null
            || birthName == ''
            || birthName == null
            )" variant="btn col-3 btn-primary w-auto" 
            @click.prevent="verify">
            Vérifier INS
          </b-button>
        </div>
        <VerificationIns 
          v-if="oid == ''
            || oid == null
            || matriculeIns == ''
            || matriculeIns == null
            || codeInsee == ''
            || codeInsee == null
            || sex == ''
            || sex == null
            || birthDateFR == ''
            || birthDateFR == null
            || firstName == ''
            || firstName == null
            || birthName == ''
            || birthName == null"
          res='ERROR'
          @close="closeModal"
         />
        <h3 v-if="codeInsi != null" class="col-12 mt-5 font-weight-bold">Résultat de recherche</h3>
        <VerificationIns 
          v-if="verificationIns != null"
          :res='verificationIns'
          @close="closeModal"
         />
      </div>
    </custom-modal>
  </div>
</template>
<script>
    import {API_URL} from '@/components/Util/EnvVariable';
    import { mapActions, mapState } from "vuex";
    import VerificationIns from './VerificationIns.vue';

  export default {
    name: "Teleservice",
    data() {
      return {
        API_URL: API_URL,
        previewURL: '',
        disabled: false,
        verificationIns: null,
      }
    },
    props: {
      insiModal: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
        default: null,
      },
      codeInsi: {
        type: String,
        default: null,
      },
      codeInsee: {
        type: String,
        default: null,
      },
      matriculeIns: {
        type: String,
        default: null,
      },
      oid: {
        type: String,
        default: null,
      },
      sex: {
        type: String,
        default: null,
      },
      birthDateFR: {
        type: String,
        default: null,
      },
      firstName: {
        type: String,
        default: null,
      },
      birthName: {
        type: String,
        default: null,
      },
    },
    components:{
      VerificationIns
    },
    computed: {
      ...mapState('Client', ['client'])
    },
    methods: {
      ...mapActions('Insi', ['verifyInsi']),
      showPreview(url, documentId = null) {
          this.previewURL = url;
          this.documentId = documentId;
          this.$bvModal.show('preview_document_'+this.label);
      },
      insiModalView() {
        this.insiModal = !this.insiModal;
      },
      closeModal() {
        this.insiModal = !this.insiModal;
        window.location.reload();
      },
      verify() {
        this.codeInsi = 'ok';
        this.verifyInsi({
          id: this.id,
          payload: {
            matriculeIns: this.matriculeIns,
            oid: this.oid,
            sex: this.sex.charAt(0) ?? 'I',
            firstName: this.firstName,
            birthName: this.birthName,
            codeInsee: this.codeInsee,
            birthDate: this.birthDateFR,
          }
        }).then((res) => {
          let newStatus = "";
          this.$notify.success('Vérification réussite');
          this.verificationIns = res;
          newStatus = "identité qualifiée";

          if (res == 'KO') {
            newStatus = "identité provisoire";
          }

          this.client.identificationStatus.label = newStatus;

          return res;
        }
        ).catch((err) => {
          this.$notify.error("Echec de vérification")
          throw err;
        });
      },
    },
  }
</script>
<style scoped>
  input.input-split-date{
    max-width: 60px;
    margin-left: 10px;
    margin-right: 10px;
  }
  input.input-split-date:nth-child(1){
    margin-left: 0;
  }
  input.input-split-date-year {
    max-width: 90px;
    margin-left: 10px;
    margin-right: 10px;
  }
  div.display-flex{
    display: flex;
  }
  .disabled-btn {
    background-color: #ffffff !important;
    border-color: #929090 !important;
    color: #929090 !important;
  }
</style>
