import gql from 'graphql-tag'

export const GET_CLIENT = gql`
    query client(
        $id: Int!,
    ) {
        client(
            id: $id
        ) {
            id,
            erpId
            statusSync
            conflictSync
            folderNumber
            lastname
            firstname
            birthName
            nationality
            birthCity
            sex
            birthCountry
            identificationStatus {
                id,
                label
            }
            identityDocument {
                id,
                libelle,
                trusted
            }
            identityFictionnal
            identityDoubtfull
            identityHomonym
            codeInsee
            birthFirstname
            birthFirstnames
            oid
            matriculeIns
            email
            address
            city
            postalCode
            sector
            housingAccess
            birthDate
            age
            socialSecurityNumber
            digicode
            howOpenDoor
            sitePicture
            homePhoneNumber
            portablePhoneNumber
            girNumber
            missing
            familyStatus
            csp
            protectiveMeasure
            additionalInformations
            keyPosition
            familyModuleActive
            qrCode
            reviewDate,
            referent {
                id
            }
            reviewer{
                id,
                firstName,
                lastName
            },
            createdAt,
            createdBy{
                id,
                firstName,
                lastName
            },
            updatedAt,
            updatedBy{
                id,
                firstName,
                lastName
            },
            structure {
                id,
                name,
                logo,
                phoneNumber
            },
            agency {
                id,
                structure {
                    id
                    name
                }
                companyName,
                fax,
                capital,
                rcs,
                siret,
                tvaintercom,
                agsnum,
                agqnumbis,
                certifnf,
                autorisationNum
            },
            clientSupportList {
                financer,
                clientPart,
                financerPart,
                type,
                dateStart,
                dateEnd
            },
            status,
            placePicture,
            familyContact {
                id,
                lastName,
                firstName,
                titre,
                type,
                phone,
                address,
                city,
                postalCode,
                typeErp,
                erpId
            },
            medicalContact {
                id,
                lastName,
                firstName,
                titre,
                type,
                phone,
                address,
                city,
                postalCode,
                typeErp,
                erpId
            },
            contactList {
                id,
                lastName,
                firstName,
                titre,
                type,
                phone,
                address,
                city,
                postalCode,
                typeErp,
                erpId
            },
            pictures {
                id,
                path,
                title,
                description
            },
            documents {
                id,
                path,
                fileFormat
                mainType,
                type,
                description
            },
            intervenantList {
                id,
                erpId,
                dateStart,
                dateEnd,
                createdAt,
                createdBy{
                    id,
                    firstName,
                    lastName
                },
                updatedAt,
                updatedBy{
                    id,
                    firstName,
                    lastName
                },
                intervenant{
                    id,
                    agency {
                        id,
                        companyName
                    },
                    firstName,
                    lastName,
                    email,
                    portableProfesionnalPhoneNumber
                }
            },
            evaluationNumber,
            evaluation {
                id,
                startingDate,
                endingDate,
                createdAt,
                evaluateBy {
                    id,
                    lastName,
                    firstName
                },
                aggirPercentage
            }
        }
    }
`
