import { API_URL } from "@/components/Util/EnvVariable";
import { get } from '@/api/Fetch';

export const getClientHistory = async ({ commit }, { id, offset }) => {
    try {
        const response = await get(`${API_URL}/client-history/${id}&${offset}`);
        const data = await response.json();
        commit("setClientHistory", data);
    } catch (error) {
        console.error(error)
        throw error;
    }
};

export const getClientStatusHistory = async ({ commit }, { id, offset }) => {
    try {
        const response = await get(`${API_URL}/client-status-history/${id}&${offset}`);
        const data = await response.json();
        commit("setClientStatusHistory", data);
    } catch (error) {
        console.error(error)
        throw error;
    }
};

export const getInsiHistory = async ({ commit }, { id, offset }) => {
    try {
        const response = await get(`${API_URL}/insi-history/${id}&${offset}`);
        const data = await response.json();
        commit("setInsiHistory", data);
    } catch (error) {
        console.error(error)
        throw error;
    }
};

export default {
    getClientHistory,
    getClientStatusHistory,
    getInsiHistory
}