<template>
  <eval-wrap class="id-page" title="Identité">
    <template v-slot:title>
      <div class="d-flex align-items-center w-100">
        <div>
          <nav class="nav eval--nav pdv--nav" ref="pdv--nav">
            <a href="#" :class="[activeContent === 'info' ? 'active' : '', 'nav-link']" @click.prevent="activeContent ='info'">Informatif</a>
            <a href="#" :class="[activeContent === 'demand' ? 'active' : '', 'nav-link']" @click.prevent="activeContent ='demand'">Eléments demande</a>
            <a href="#" :class="[activeContent === 'environment' ? 'active' : '', 'nav-link']" @click.prevent="activeContent ='environment'">Habitat</a>
            <a href="#" :class="[activeContent === 'entourage' ? 'active' : '', 'nav-link']" @click.prevent="activeContent ='entourage'">Entourage</a>
            <a href="#" :class="[activeContent === 'pec' ? 'active' : '', 'nav-link']" @click.prevent="activeContent ='pec'">PEC</a>
          </nav>
        </div>
      </div>
    </template>
    <div class="container">
      <info v-show="
        activeContent === 'info' 
        && cgu === false
        && rgpd === false
        && mention === false"
      />
      <environment v-show="
        activeContent === 'environment'
        && cgu === false
        && rgpd === false
        && mention === false"
      />
      <Demand v-show="
        activeContent === 'demand'
        && cgu === false
        && rgpd === false
        && mention === false
      "/>
      <Entourage v-show="
        activeContent === 'entourage'
        && cgu === false
        && rgpd === false
        && mention === false
      "/>
      <PEC v-show="
        activeContent === 'pec'
        && cgu === false
        && rgpd === false
        && mention === false
      "/>
      <Cgu :showHeaders="false" v-show="cgu"/>
      <Mention :showHeaders="false" v-show="mention"/>
      <Rgpd :showHeaders="false" v-show="rgpd"/>
    </div>
  </eval-wrap>
</template>

<script>
  import Info from "@/views/Evaluation/Identity/Info";
  import Environment from "@/views/Evaluation/Identity/Environment";
  import Demand from "@/views/Evaluation/Identity/Demand";
  import Entourage from "@/views/Evaluation/Identity/Entourage";
  import PEC from "@/views/Evaluation/Identity/PEC";
  import Cgu from '@/views/CGU/Cgd';
  import Mention from '@/views/CGU/Mention';
  import Rgpd from '@/views/CGU/Rgpd';
  import { mapState } from 'vuex';

  export default {
    name: "Identity",
    data: () => ({
      activeContent: 'info'
    }),
    computed: {
        ...mapState('Cgu', ['cgu', 'mention', 'rgpd'])
    },
    components: {
      Info,
      Environment,
      Demand,
      Entourage,
      PEC,
      Cgu,
      Rgpd,
      Mention
    }
  };
</script>
