import gql from 'graphql-tag'

export const GET_EVALUATION = gql`
    query evaluation(
        $id: Int!,
    ) {
        evaluation(
            id: $id
        ) {
            id,
            demandDate,
            evaluationDate,
            startingDate,
            endingDate,
            createdAt,
            createdBy{
                id,
                firstName,
                lastName
            },
            updatedAt,
            updatedBy{
                id,
                firstName,
                lastName
            }
            structure{
                id,
                name,
                logo
            },
            agency {
              id,
              structure{
                id,
                name,
                logo
              },
              companyName,
              address,
              city,
              phoneNumber,
              fax,
              capital,
              rcs,
              siret,
              tvaintercom,
              agsnum,
              agqnumbis,
              certifnf,
              autorisationNum,
              finess
            },
            client{
                id,
                firstname,
                lastname,
                portablePhoneNumber,
                sex,
                birthDate,
                status,
                address, 
                city,
                postalCode,
                birthName,
            },
            evaluateBy{
                id,
                firstName,
                lastName,
                portablePhoneNumber,
                portableProfesionnalPhoneNumber,
                homePhoneNumber,
                homeProfesionnalPhoneNumber
                structure {
                  id
                },
                agency {
                  id
                }
            },
            additionalInfos,
            carePlanPrestations {
                id,
                carePlanPrestation{
                id,
                name,
                time,
                }   
            },
            habits {
              id,
              description,
              place,
              period,
              validatedTime,
              evaluationId
            },
            risks{
                id,
                risk{
                    id,
                    name,
                }
                checked
            },
            status,
            answerList {
                id,
                answerStch,
                question {
                   id,
                   item
                },
                itemScore,
                subItemScore
            },
            aggirPercentage,
            materials {
              material {
                id
              },
              existing,
              required,
              suggested
            },
            generalQuestionsAnswers {
                id,
                generalQuestion{
                    id,
                    responses,
                    type,
                    title,
                },
                answers,
                text
            },
            gir,
            materials {
              material {
                id,
                name
              },
              existing
            },
            evaluatorComments,
            contracts {
                id,
                status,
                aivqDetails,
                avqDetails,
                totalAIVQMinutes,
                totalAIVQCost,
                totalAVQMinutes,
                totalAVQCost,
                pecDetails,
                createdAt,
                updatedAt,
                dateClose,
                description,
                displayPeriod,
                documentSyntheseId
            },
            seanceDetails,
            heuresSemaine,
            heuresDimanche
        }
    }
`
