<template>
  <div class="">
    <div class="id--section-title spaced-title">
      <div class="title-and-edit">
        <h1>IDENTIFICATION</h1>
        <button class="btn id--edit-btn" @click.prevent="editInfo">
          <img width="18" src="@/assets/icon-plein-edit.svg" />
        </button>
      </div>
      <button v-if="accessSettingsIdentityDocument" class="btn id-documents--add-btn" @click.prevent="$emit('modalActiveIdentity')">
        <img class="icon-settings" src="@/assets/Icon-settings.svg" alt />
      </button>
    </div>
    <div class="" v-if="!editIdentityDocument">
      <div class="id--section mt-5">
      <div class="id--section-content">
        <div class="id--section-content">
          <div class="row mb-3">
            <div class="col-6">
              <p><span class="detail__label">Identité : </span> {{ getIdentityLabel() }} </p>
              <p><span class="detail__label">Pièce justificative de l'identité : </span> {{ getIdentityDocumentLabel() }} </p>
              <p><span class="detail__label">Pièce à haut niveau de confiance : </span> {{ getIdentityDocumentTrusted() }} </p>
            </div>
            <div class="col-6">
              <p><span class="detail__label">Identité douteuse :</span> {{getIdentityDoubtfull() }} </p>
              <p><span class="detail__label">Identité fictive :</span> {{ getIdentityFictionnal() }} </p>
              <p><span class="detail__label">Identité homonyme :</span> {{ getIdentityHomonym() }} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="" v-if="editIdentityDocument">
      <div class="row mt-4">
        <div class="col-md-8">
          <div class="radio-group">
            <label for="identiteProvisoire">
              <div class="radio-item custom">
                  <label for="identiteProvisoire">
                  <input type="radio" :disabled="this.disabledProvisoire" id="identiteProvisoire" name="identity" value="1" v-model="identity">
                  <span class="checkmark"></span>Identité Provisoire
                </label>
              </div>
            </label>
            <label for="identiteRecuperee">
              <div class="radio-item custom">
                <label for="identiteRecuperee">
                  <input type="radio" :disabled="this.disabledRecuperee" id="identiteRecuperee" name="identity" value="2" v-model="identity">
                  <span class="checkmark"></span>Identité Récupérée
                </label>
              </div>
            </label>
            <label for="identiteValidee">
              <div class="radio-item custom">
                <label for="identiteValidee">
                  <input type="radio" :disabled="this.disabledValidee" id="identiteValidee" name="identity" value="3" v-model="identity">
                  <span class="checkmark"></span>Identité Validée
                </label>
              </div>
            </label>
            <label for="identiteQualifiee">
              <div class="radio-item custom">
                <label for="identiteQualifiee">
                  <input type="radio" :disabled="this.disabledQualifiee" id="identiteQualifiee" name="identity" value="4" v-model="identity">
                  <span class="checkmark"></span>Identité Qualifiée
                </label>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-6">
          <label for="identityDocument">Pièce justificative de l'identité</label>
          <div class="dropdown-wrapper">
            <select class="form-control" id="identityDocument" name="identityDocument" v-model="identityDocuments" @change="displayTrusted(getDocuments, identityDocuments)">
              <option v-for="document in getDocuments" :key="document.id">
                {{ document.libelle }}
              </option>
            </select>
            <span class="clear-selection" @click="clearSelection">&times;</span>
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-end">
          <div class=" d-flex w-100 justify-content-between">
            <label class="custom-checkbox" for="doubtfull">
              <input type="checkbox" id="doubtfull" v-model="doubtfull.checked" @change="disableIdentity(doubtfull.checked)"/>
              <span class="checkmark"></span>
              Identité douteuse
              <div class="wrapper wrapper-doubtfull">
                <div class="icon infobulle">
                  <span class="tooltip tooltip-doubtfull">Cet attribut est à utiliser quand le professionnel a un doute sur l'identité alléguée par l'usager.</span>
                  <span><img src="@/assets/infobulle.svg" alt /></span>
                </div>
              </div>
            </label>
            <label class="custom-checkbox" for="fictionnal">
              <input type="checkbox" id="fictionnal" v-model="fictionnal.checked" @change="disableIdentity(fictionnal.checked)"/>
              <span class="checkmark"></span>
              Identité fictive
              <div class="wrapper">
                <div class="icon infobulle">
                  <span class="tooltip">Cet attribut est à utiliser quand le professionnel crée volontairement une identité imaginaire quand l'identité de l'usager est inconnue (anonymat par exemple).</span>
                  <span><img src="@/assets/infobulle.svg" alt /></span>
                </div>
              </div>
            </label>
            <label class="custom-checkbox" for="homonym">
              <input type="checkbox" id="homonym" v-model="homonym"/>
              <span class="checkmark"></span>
              Identité homonyme
              <div class="wrapper">
                <div class="icon infobulle">
                  <span class="tooltip">Cet attribut est à utiliser quand le professionnel a connaissance de la présence d'identités ressemblantes dans AZUREO ou dans le réferentiel régional d'identité.</span>
                  <span><img src="@/assets/infobulle.svg" alt /></span>
                </div>
              </div>
            </label>
        </div>
      </div>
      <div class="col-md-12">
        <p v-if="trusted"><br /><img class="icon-settings" src="@/assets/info.svg" alt /> Pièce à haut niveau de confiance</p>
      </div>
      <div class="col-md-12">
        <p v-if="documentMissing" class="error"><br />Veuillez choisir un document</p>
      </div>
    </div>
    <div class="col-12 text-center">
      <b-button class="mt-2" variant="btn mt-4 mb-4 btn-outline-primary" @click.prevent="updateIdentityDocument">
        Sauvegarder
      </b-button>
    </div>
  </div>
  <div v-if="isFrench()" class="text-center">
    <b-button variant="btn btn-primary" @click.prevent="insiModalView">
      Vérification INSI
      <img width="24" height="24" class="ml-3" src="../../assets/insi.png" />
    </b-button>
  </div>
  <teleservice
    :insiModal="insiModal"
    :codeInsi=null
    :id="client.id"
    :matriculeIns="client.matriculeIns"
    :oid="client.oid"
    :sex="client.sex"
    :codeInsee="client.codeInsee"
    :birthDateFR="birthDateFR"
    :firstName="client.firstname"
    :birthName="client.birthName"
  ></teleservice>
</div>
</template>
<script>
  import {API_URL} from '@/components/Util/EnvVariable';
  import Moment from "moment";
  import { mapActions, mapState } from "vuex";
  import Teleservice from '../../views/Evaluation/Identity/Teleservice.vue';

  export default {
    name: "Identification",
    data() {
      return {
        API_URL: API_URL,
        previewURL: '',
        documentId: null,
        insiModal: false,
        identity: null,
        doubtfull: {
          checked: null
        },
        fictionnal: {
          checked: null
        },
        homonym: null,
        identityDocuments:  [],
        identityDocument: null,
        trusted: false,
        disabledProvisoire: false,
        disabledRecuperee: false,
        disabledValidee: false,
        disabledQualifiee: false,
        document: null,
        fake: false,
        documentMissing: false,
        editIdentityDocument: false,
        gender: "INDETERMINE",
      }
    },
    components:{
      Teleservice,
    },
    props: {
      dataList: Array,
      label: String,
    },
    computed: {
      ...mapState('Client', ['client']),
      ...mapState('IdentityDocument', ['documents']),
      checkedIdentity() {
        return this.client.identificationStatus?.id || "1";
      },
      getDocuments() {
        return this.documents
      },
      accessSettingsIdentityDocument: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_INSI_CONFIGURE_IDENTITY_DOCUMENT');
        }
      },
      sexInsi: {
        get() {
          var sex = "INDETERMINE";
 
          if (this.client.sex == "M") {
            sex = "HOMME";
          }
 
          if (this.client.sex == "F") {
            sex = "FEMME";
          }
 
          console.log(sex);
          return sex;
        },
      },
      birthDateFR: {
        get() {
          const formatedDate = Moment(this.client.birthDate).format('DD/MM/YYYY');

        if (formatedDate == "Invalid date") {
          return null;
        }

        return formatedDate;
        }
      }
    },
    watch: {
      checkedIdentity(newVal) {
        this.identity = newVal;
      },
    },
    mounted() {
      this.fetchIdentityDocuments();
      this.identity = this.checkedIdentity;
    },
    methods: {
      ...mapActions('IdentityDocument', ['saveClientWithIdentityDocument', 'getIdentityDocuments']),
      showPreview(url, documentId = null) {
          this.previewURL = url;
          this.documentId = documentId;
          this.$bvModal.show('preview_document_'+this.label);
      },
      async fetchIdentityDocuments() {
        try {
          await this.getIdentityDocuments();
        } catch (error) {
          console.error(error);
        }
      },
      isFrench() {
        return this.client.birthCountry == "France" ? true : false;
      },
      clearSelection() {
        this.identityDocuments = null;
        this.trusted = false;
      },
      insiModalView() {
        this.client.sex = this.displaySex();
        this.insiModal = !this.insiModal;
      },
      getIdentityLabel() {
        return this.capitalizeFirstLetter(this.client.identificationStatus?.label);
      },
      getIdentityDocumentLabel() {
        return this.client.identityDocument?.libelle;
      },
      getIdentityDocumentTrusted() {
        return this.client.identityDocument ? (this.client.identityDocument?.trusted ? "Oui" : "Non") : "";
      },
      getIdentityFictionnal() {
        return this.client.identityDocument ? (this.client.identityFictionnal ? "Oui" : "Non") : ""
      },
      getIdentityDoubtfull() {
        return this.client.identityDocument ? (this.client.identityDoubtfull ? "Oui" : "Non") : ""
      },
      getIdentityHomonym() {
        return this.client.identityDocument ? (this.client.identityHomonym ? "Oui" : "Non") : ""
      },
      capitalizeFirstLetter(string) {
        if (!string) {
          return '';
        } 
          return string.charAt(0).toUpperCase() + string.slice(1);
      },
      editInfo() {
        this.editIdentityDocument = !this.editIdentityDocument;
        this.identity = this.client.identificationStatus.id;

        if (this.client.identityDocument) {
          this.identityDocuments = this.client.identityDocument.libelle;
          this.fictionnal.checked = this.client.identityFictionnal;
          this.doubtfull.checked = this.client.identityDoubtfull;
          this.homonym = this.client.identityHomonym;
          this.document = this.client.identityDocument.id;
          this.trusted = this.client.identityDocument.trusted;

          if (this.client.identityFictionnal || this.client.identityDoubtfull) {
            this.disableIdentity(true);
          }
        }
      },
      displaySex() {
        this.gender = "FEMININ";

        if (this.client.sex == 'M') {
          this.gender = "MASCULIN";
        }

        return this.gender;
      },
      disableIdentity(val) {
        this.fake = false;
        this.disabledQualifiee = false;
        this.disabledValidee = false;
        this.disabledRecuperee = false;

        if (val || (this.fictionnal.checked || this.doubtfull.checked)) {
          this.fake = true;
          this.disabledQualifiee = true;
          this.disabledValidee = true;
          this.disabledRecuperee = true;
          this.identity = 1;
        } else if (this.trusted) {
          this.identity = 3;
        }

      },
      displayTrusted(val, value) {
        val.forEach(element => {
          if (element.libelle === value) {

            this.trusted = false;
            this.document = element.id;

            if (!this.doubtfull.checked && !this.fictionnal.checked) {
              this.disabledQualifiee = false;
              this.disabledRecuperee = false;
            }

            if (element.trusted) {
              this.trusted = true;

              if (!this.doubtfull.checked && !this.fictionnal.checked) {
                this.disabledQualifiee = true;
                this.disabledRecuperee = true;
                this.identity = 3;
              }
            }
          }
        });
      },
      updateIdentityDocument() {
        if (this.document == null) {
          return this.documentMissing = true;
        }

        this.saveClientWithIdentityDocument({
          id: this.client.id,
          payload: {
            identityDocumentId: this.document,
            identificationStatusId: this.identity,
            trusted: this.trusted,
            fake: this.fake,
            doubtfull: this.doubtfull.checked ?? false,
            fictionnal: this.fictionnal.checked ?? false,
            homonym: this.homonym ?? false,
          }
        }).then((res) => {
          this.$notify.success('L\'information a été sauvegardée');
          window.location.reload();

          return res;
        }
        ).catch((err) => {
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
          throw err;
        });
      }
    }
  }
</script>
<style scoped>
/* Customize the label (the container) */
.custom {
  display: block;
  position: relative;
  padding: 0;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px
}

.dropdown-wrapper {
  position: relative;
}

.clear-selection {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer;
  color: black;
  font-size: 1.7em;
  background: none;
  border: none;
  padding: 0;
}

.select {
  width: 100%;
  padding-right: 30px;
}


/* Hide the browser's default radio button */
.custom input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.custom {
  height: 17px;
  width: 17px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid #4164E3;
}
.custom label {
  display: flex;
  width: max-content;
  margin-left: 2rem;
}

/* On mouse-over, add a grey background color */
.custom:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.custom input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom .checkmark:after {
  top: 2px;
  left: 2px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #4164E3;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox {
    display: block;
    font-size: 14px;
}

.custom-checkbox .checkmark {
  position: relative;
  height: 14px;
  width: 14px;
  background-color: #eee;
  border: 2px solid #4164E3;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  cursor: pointer;
}

.custom-checkbox .checkmark:hover {
  background-color: #ddd;
}

.custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    left: 2px;
    top: 0px;
    width: 6px;
    height: 8px;
    border: solid #4164E3;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  border-color: #4164E3;
}
.radio-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.radio-item input[type="radio"] {
    margin: 0 5px 0 0;
}

.spaced-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-settings {
    width: 20px !important;
    height: auto;
}

.wrapper {
  display: inline-flex;
  list-style: none;
}

.wrapper .icon {
  margin-left: 5px;
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 17px;
}

.wrapper .tooltip {
  position: absolute;
  top: -150px !important;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 15px 18px;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  width: 250px;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper-doubtfull .tooltip-doubtfull {
  position: absolute;
  top: -108px !important;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 15px 18px;
  border-radius: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  width: 250px;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  background: #ffffff;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .infobulle:hover .tooltip,
.wrapper .infobulle:hover .tooltip::before {
  background: #100249;
  color: #ffffff;
}

.form-group .form-check-input {
    margin-bottom: 0;
}
.form-group .form-check-label {
    margin-bottom: 0;
}
.id--section {
  text-transform: normal !important;
}

.title-and-edit {
  display: flex;
  align-items: center;
}

.title-and-edit h1 {
  margin-right: 10px; /* espace entre le titre et le bouton éditer */
}

.id--edit-btn,
.id-documents--add-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.id--edit-btn img,
.id-documents--add-btn img {
  vertical-align: middle;
}
</style>