<template>
    <div class="bo--agence row mx-0 justify-content-center">
        <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
            <div class="d-flex align-items-center">
                <h1 class="bo--agence-title">Liste des usagers</h1>
                <span class="bo--agence-title-notification error" v-if="!synchronized">Non synchronisé</span>
                <span class="bo--agence-title-notification success" v-else>Synchronisé</span>
                <span class="bo--agence-title-count">{{total}} {{total>1 ? 'éléments' : 'élément'}}</span>
            </div>
            <div class="col-md-12">
                <button class="float-right btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'sync-alt']"/></button>
            </div>
            <Grid id="ClientList"
                  :columns="headerColumns"
                  :dataList="clientList"
                  :sortColumn="sortColumn"
                  :sortDirection="sortDirection"
                  :currentPage="currentPage"
                  :loading="loading"
                  :total="total"
                  :canEditOrDelete="isUserRoleOperationManagerOrAreaManager"
                  @updateCurrentPage="limitCo => limit = limitCo"
                  @dataLoading="dataLoading"
                  @editComponent="editClient"
                  @confirmRemove="confirmRemove"
                  ref="grid"
            />
        </div>
        <b-modal id="archive" hide-footer title="Archive" :centered="true">
            <template v-slot:modal-title>
                Êtes-vous sur de vouloir supprimer cet utilisateur ?
            </template>
            <b-button class="mt-3" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('archive')">Annuler</b-button>
            <b-button class="mt-2" variant="btn offset-5 col-4 btn-outline-danger" @click.prevent="removeClient">Supprimer</b-button>
        </b-modal>
    </div>
</template>

<script>
    import Grid from "@/components/Grid/Grid";
    import {MixinStructureHeader} from "@/components/Util/MixinHeader";
    import {mapState, mapActions, mapGetters} from 'vuex'
    import moment from 'moment'

    export default {
        name: "ClientList",
        mixins: [MixinStructureHeader()],
        components: {
            Grid
        },
        data() {
            return {
                columns: [
                    {name: "Structure", value: 'structureName', column: 'structure.id', sortable: true, list: []},
                    {name: "Agence", value: 'agencyName', column: 'agency.company_name', sortable: true},
                    {name: "Nom", value: 'lastname', column: 'lastname', sortable: true},
                    {name: "Prénom", value: 'firstname', column: 'firstname', sortable: true},
                    /*{name: "Dossier", value: 'folderNumber', column: 'folder_number', sortable: true},*/
                    {name: "Ville", value: 'city', column: 'city', sortable: true},
                    {name: "Code Postal", value: 'postalCode', column: 'postal_code', sortable: true},
                    // {name: "Secteur", value: 'sector', column: 'sector', sortable: true},
                    {name: "Téléphone", value: 'phone', column: 'home_phone_number', sortable: false},
                    /*{name: "GIR", value: 'girNumber', column: 'gir_number', sortable: false},*/
                    /*{name: "Absence", value: 'missing', column: 'missing', sortable: false},*/
                    /*{name: "Date de création", value: 'createdDate', column: 'created_at', sortable: false},*/
                    /*{name: "Créateur", value: 'createdUser', column: 'created_by', sortable: false},*/
                    /*{name: "Date d'évaluation", value: 'reviewAt', column: 'review_date', sortable: false},*/
                    /*{name: "Évaluateur", value: 'reviewUser', column: 'reviewer', sortable: false},*/
                    {name: "Part usager", value: 'clientSupportClientPart', column: 'clientSupport.clientPart', sortable: true},
                    {name: "Part Financer", value: 'clientSupportFinancerPart', column: 'clientSupport.financerPart', sortable: true},
                    {name: "Prise en charge d.fin", value: 'clientSupportDateEnd', column: 'clientSupport.dateEnd', sortable: true},
                    {name: "Sync", value: 'statusSync', column: 'status_sync', sortable: true, list: [{label: 'Tous', value: ""}, {label: 'Synchronié', value: 'synchronised'}, {label: 'Non Synchronisé', value: 'not_synchronised'}]},
                    {name: "Statut", value: 'status', column: 'status', sortable: true, list: [{label: 'Tous', value: ""}, {label: 'Actif', value: 1}, {label: 'Inactif', value: 0}]}
                ],
                sortColumn: 'lastname',
                sortDirection: 'ASC',
                limit: 10,
                currentPage: 1,
                removableClient: null,
            };
        },
        computed: {
            ...mapState("Client", ["loading", "clients", "total", "error"]),
            ...mapState('User', ['connectedUser']),
            ...mapGetters("Notification", ["getNotificationByInterface"]),
            ...mapGetters("User", ["isUserRoleOperationManagerOrAreaManager"]),
            //headerColumns(){},headerColumns is set in mixin MixinStructureHeader
            synchronized() {
                return this.getNotificationByInterface.Personnel && this.getNotificationByInterface.Personnel.reduce((value, line) => {
                    if (line.type !== "success") {
                        value = false;
                    }
                    return value;
                }, true);
            },
            tdStyle() {
                //return `width:${(100 / this.columns.length).toFixed(1)}%`;
                return `width:110px`;
            },
            clientList() {
                return this.clients.map(function (item) {
                    return {
                        ...item,
                        agencyName: item.agency && item.agency.length > 0 ? item.agency.reduce((text, c) => {
                            if (!c.companyName) {
                                return text;
                            }
                            if (!text || text === "") {
                                return c.companyName;
                            }
                            text = `${text} - ${c.companyName}`;
                            return text;
                        }, null) : null,
                        structureName: item.structure && item.structure.length > 0 ? item.structure.reduce((text, c) => {
                            if (!c.name) {
                                return text;
                            }
                            if (!text || text === "") {
                                return c.name;
                            }
                            text = `${text} - ${c.name}`;
                            return text;
                        }, null) : null,
                        phone: (item.homePhoneNumber && item.portablePhoneNumber) ? item.homePhoneNumber + ', ' + item.portablePhoneNumber : null,
                        createdUser: item.createdBy ? item.createdBy.firstName + ' ' + item.createdBy.lastname : null,
                        reviewUser: item.reviewer ? item.reviewer.firstName + ' ' + item.reviewer.lastname : null,
                        createdDate: item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : null,
                        reviewAt: item.reviewDate ? moment(item.reviewDate).format('DD/MM/YYYY') : null,
                        clientSupportClientPart: item.clientSupportList && item.clientSupportList.length > 0 ? item.clientSupportList.reduce((text, c) => {
                            if (!c.clientPart && c.clientPart !== 0) {
                                return text;
                            }
                            if (!text || text === "") {
                                return c.clientPart;
                            }
                            text = `${text} - ${c.clientPart}`;
                            return text;
                        }, null) : null,
                        clientSupportFinancerPart: item.clientSupportList && item.clientSupportList.length > 0 ? item.clientSupportList.reduce((text, c) => {
                            if (!c.financerPart && c.financerPart !== 0) {
                                return text;
                            }
                            if (!text || text === "") {
                                return c.financerPart;
                            }
                            text = `${text} - ${c.financerPart}`;
                            return text;
                        }, null) : null,
                        clientSupportDateEnd: item.clientSupportList && item.clientSupportList.length > 0 ? item.clientSupportList.reduce((text, c) => {
                            if (!c.dateEnd) {
                                return;
                            }
                            let dateEnd = moment(c.dateEnd).format('DD/MM/YYYY');
                            if (!text || text === "") {
                                return dateEnd;
                            }
                            text = `${text} - ${dateEnd}`;
                            return text;
                        }, null) : null,
                        statusSync: item.statusSync === 'not_synchronised' ? 'Non synchronisé' : 'synchronisé',
                        status: item.status ? 'Actif' : 'Inactif'
                    }
                });
            }
        },
        mounted: function () {
            this.limit = window.localStorage.getItem("limit") ? parseInt(window.localStorage.getItem("limit")) : 10;
            if (this.clients.length == 0) {
                this.dataLoading({limit: this.limit, offset: this.limit * (this.currentPage -1)});
            }
        },
        methods: {
            ...mapActions("Client", ["getClientList", "deleteClient"]),
            dataLoading(data) {
                this.getClientList(data);
            },
            refresh() {
                this.currentPage = 1;
                this.sortColumn = "lastname";
                this.sortDirection = "ASC";
                this.getClientList({
                    limit: this.limit,
                    offset: this.limit * (this.currentPage -1),
                    sortColumn: this.sortColumn,
                    sortDirection: this.sortDirection,
                });
                this.$refs.grid.refreshFilter();
            },
            editClient(id) {
                this.$router.push({name: 'client-form', params: {id: id}});
            },
            confirmRemove(client) {
                this.removableClient = client;
                this.$bvModal.show('archive');
            },
            async removeClient() {
                this.$bvModal.hide('archive');
                await this.deleteClient({id: this.removableClient.id});
                if (this.error === null) {
                    this.$notify.success("L'usager a été archivé");
                } else {
                    this.$notify.error("Impossible de supprimer l'utilisateur");
                }
                this.removeableAgency = null;
            }
        }
    };
</script>