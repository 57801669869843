import Moment from 'moment';

export const formatDate = (date) => {
    return Moment(date).format('DD/MM/YYYY');
}

export const getClassicRuleScore = (total) => {
    if (total === 4) {
        return 'C';
    } else if (total === 0) {
        return 'A';
    }
    return 'B';
};

export const getAnswerTotal = (answer) => {
    const response = JSON.parse(answer);
    return Object.keys(response).reduce((acc, item) => {
        return acc + (response[item] ? 1 : 0);
    }, 0)
};

export const dateFormat = (val, hFormat = false) => {
    let m = val % 60;
    let h = (val - m) / 60;

    if (hFormat) {
        return (h < 10 ? "0" : "") + h.toString() + "h" + (m < 10 ? "0" : "") + m.toString();
    }
    return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
}

export const dateToMin = (val) => {
    if (val && isNaN(val)) {
        let timeArr = val.split(":");
        return parseInt(timeArr[0]) * 60 + parseInt(timeArr[1])
    }else{
        return parseInt(val);
    }
}

export const formatNumberCurrency = (number, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
    return parseFloat(new Intl.NumberFormat('fr-FR', { style: 'decimal', minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits }).format(number).replace(/\s+/g, '').replace(',', '.'))
}

export const formatSex = (sex) => {
    if (sex === 'F') {
        return "Madame"
    } else if (sex === 'M') {
        return "Monsieur"
    }
}

export const formatSecuritySocialNumber = (socialSecurityNumber) => {
    let socialSecurityNumberReturn = socialSecurityNumber ? socialSecurityNumber.split('') : [];
    let ssNumber = '';
    for (let i = 0; i < socialSecurityNumberReturn.length; i++) {
        ssNumber += (socialSecurityNumberReturn[i].trim() ? socialSecurityNumberReturn[i] : '');
        let k = ssNumber.length;
        if (k == 1 || k == 4 || k == 7 || k == 10 || k == 14 || k == 18) {
            ssNumber += ' ';
        }
    }
    return ssNumber;
}

export const formatFirstCharToUpperCase = (value) => {
    if(value === '' || value === undefined || value === null) {
        return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export default {
    formatDate,
    getClassicRuleScore,
    getAnswerTotal,
    dateFormat,
    formatNumberCurrency,
    dateToMin,
    formatSex,
    formatSecuritySocialNumber,
    formatFirstCharToUpperCase
}