export default {
    getRoles(state, data) {
        state.roles = data;
    },
    getRights(state, data) {
        state.rights = data;
    },
    getRole(state, data) {
        state.role = data;
    },
    setPostRolesLoading(state) {
        state.success = false;
        state.loading = true;
    },
    setPostRolesSuccess(state) {
        state.success = true;
        state.loading = false;
    },
    setPostRolesError(state) {
        state.loading = false;
    }
}