<template>
  <div class="navbar">
    <div class="nav__content">
      <img class="avatar" src="@/assets/avatar.png" width="52" />
        <!-- <img src="@/assets/pro_sante_connect.svg" /> -->
    </div>
    <img class="logo cursor-pointer" src="@/assets/logo.png" @click.prevent="gotToHome"/>
    <div class="eval--header-btn-wrap">
      <a class="btn" v-if="accessRollback" @click.prevent="gotToHome">
        <img src="@/assets/bouton-primaire-home.svg" />
      </a>
      <router-link 
        v-if="
          $route.name !== 'cr'
          && $route.name !== 'devis'
          && $route.name !== 'login'
          && $route.name !== 'loginPsc'
          && connectedUser
          && accessCr
        "
        :to="{name:'cr'}" 
        class="btn" 
        data-prefix="2"
      >
        <img src="@/assets/bouton-primaire-cr.svg" />
      </router-link>
      <router-link v-else :to="{name:'project-life'}" class="btn" data-prefix="2">
        <img src="@/assets/bouton-primaire-q.svg" />
      </router-link>
      <router-link
        v-if="accessBackoffice"
        :to="{name:'client-list'}" 
        class="btn" 
        data-prefix="2"
      >
        <img src="@/assets/bouton-primaire-switch.svg" />
      </router-link>
      <a class="btn" @click.prevent="$bvModal.show('quit-evaluation')">
        <img src="@/assets/bouton-primaire-power-off.svg" width="32" />
      </a>
    </div>
  </div>
</template>
<script>
  import {LOCAL_STORAGE_EVALUATION_ID} from "@/components/Util/EnvVariable";
  import {mapGetters, mapState} from "vuex";

  export default {
    computed: {
      ...mapGetters('Evaluation', ['calculateTime', 'getAggirPercentage']),
      ...mapGetters('User', ['isUserRoleIntervenant', 'isUserRoleOperationManagerOrAreaManager']),
      ...mapState("User", ['connectedUser']),
      hasLoader(){
        return this.$store.state.User.loading
            || this.$store.state.Prestation.loading
            || this.$store.state.Agency.loading
            || this.$store.state.Structure.loading
            || this.$store.state.Notification.loading
            || this.$store.state.Tarification.loading
            || this.$store.state.Contract.loading
            || this.$store.state.Filter.loading
            || this.$store.state.Client.loading
            || this.$store.state.Evaluation.loading
            || this.$store.state.Act.loading
            || this.$store.state.AggirQuestion.loading
            || this.$store.state.Material.loading
            || this.$store.state.Contact.loading;
      },
      accessBackoffice: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_BACK_OFFICE');
        }
      },
      accessCr: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_VIEW_CR');
        }
      },
      accessRollback: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_ROLLBACK_LIST_CLIENT');
        }
      }
    },
    methods: {
      gotToHome() {
        window.sessionStorage.removeItem(LOCAL_STORAGE_EVALUATION_ID);
        this.$router.push({name: "accueil"});
      }
    }
  }
</script>