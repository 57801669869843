<template>
  <div class="bo--container">
    <template>
      <div class="container">
        <loader :loader="loading"/>
        <div>
          <bo-card :title="isEdit ? '&lt; Modifier l’évaluation' : '&lt; Créer l’évaluation'">
            <template slot="title">
              <h1 class="bo-card--title" v-if="isUserRoleOperationManager">
                <a href="#" @click.prevent="cancel">&lt; </a>
                {{ isEdit ? "Modifier l’évaluation" : "Créer une évaluation" }}
              </h1>
            </template>
            <div class="row">
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-3">
                <FormDatePicker
                    :value="evaluationDate"
                    label="Date d'évaluation *"
                    :disabled="true"
                >
                </FormDatePicker>
              </div>
              <div class="col-sm-12 col-md-3">
                <FormDatePicker
                    :value="startingDate"
                    label="Date de début *"
                    :disabled="true"
                >
                </FormDatePicker>
              </div>
              <div class="col-sm-12 col-md-3">
                <FormDatePicker
                    :value="endingDate"
                    label="Date de fin *"
                    :disabled="true"
                >
                </FormDatePicker>
              </div>
              <div class="col-sm-12 col-md-3">
                <form-input :value="evaluateById"
                            label="Evaluateur *"
                            :login-input="true"
                            :disabled="true"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-3">
                <form-input :value="estimatedTime"
                            label="Temps estimé conseillé"
                            :login-input="true"
                            :disabled="true"
                />
              </div>
              <div class="col-sm-12 col-md-3">
                <form-input :value="estimatedCost + ' €'"
                            label="Prix estimé"
                            :login-input="true"
                            :disabled="true"
                />
              </div>
              <div class="col-sm-12 col-md-3">
                <form-input :value="validatedTime"
                            label="Temps validé"
                            :login-input="true"
                            :disabled="true"
                />
              </div>
              <div class="col-sm-12 col-md-3">
                <form-input :value="validatedCost + ' €'"
                            label="Prix validé"
                            :login-input="true"
                            :disabled="true"
                />
              </div>
            </div>


            <div class="row">
              <div class="col-sm-12 col-md-3">
                <form-input :value="houseAccess"
                            label="Accès au tiers aidants"
                            :login-input="true"
                            :disabled="true"
                />
              </div>
            </div>
            <h2>Client</h2>
            <div class="col-sm-12 col-md-3">
              <form-select v-model="clientId"
                           v-if="!isEdit"
                           :options="clientList"
                           label="Client *"
                           :login-input="true"
                           :disabled="true">
                <template v-slot:first>
                  <option value="" selected></option>
                </template>
              </form-select>
            </div>

            <div class="row" v-if="isEdit">
              <div class="col-sm-12 col-md-3">
                <div class="col-sm-12">
                  <Radio
                      :label="['M.','Mme.']"
                      :values="['male','female']"
                      :value="clientSex"
                      :disabled="true"
                  >
                  </Radio>
                </div>
              </div>
              <div class="col-sm-12 col-md-9 row">
                <div class="col-sm-4">
                  <form-input :value="clientFirstName"
                              label="Nom *"
                              :login-input="true"
                              :disabled="true"
                  />
                </div>
                <div class="col-sm-4">
                  <form-input :value="clientLastName"
                              label="Prénom *"
                              :login-input="true"
                              :disabled="true"
                  />
                </div>
                <div class="col-sm-4">
                  <form-input :value="clientPhoneNumber"
                              label="Téléphone *"
                              :login-input="true"
                              :disabled="true"
                  />
                </div>
                <div class="col-sm-4">
                  <FormDatePicker
                      label="Date de naissance *"
                      :value="clientBirthDate"
                      :disabled="true"
                      :has-bg="true"
                      >
                  </FormDatePicker>
                </div>
                <div class="col-sm-4">
                  <form-input :value="clientBirthName"
                              label="Nom de naissance"
                              :login-input="true"
                              :disabled="true"
                  />
                </div>
                <div class="col-sm-4"></div>
                <div class="col-sm-4">
                  <form-input :value="clientAddress"
                              label="Adresse"
                              :login-input="true"
                              :disabled="true"
                  />
                </div>
                <div class="col-sm-4">
                  <form-input :value="clientPostalCode"
                              label="Code postal"
                              :login-input="true"
                              :disabled="true"
                  />
                </div>
                <div class="col-sm-4">
                  <form-input :value="clientCity"
                              label="Ville"
                              :login-input="true"
                              :disabled="true"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-lg-12 mt-3" v-if="isEdit">
              <GridLight
                  :disabled="!isUserRoleOperationManager"
                  label="Liste des prestations du plan d'aide"
                  :columns="evaluationPrestationColumns"
                  :dataList="evaluationPrestationsList"
                  :loading="loading"
                  :assignable="false"
                  :canDelete="false"
              />
            </div>
            <div class="col-md-12 col-sm-12 col-lg-12 mt-3" v-if="isEdit">
              <GridLight
                  :disabled="!isUserRoleOperationManager"
                  label="Liste des habitudes"
                  :columns="evaluationHabitsColumns"
                  :dataList="evaluationHabitsList"
                  :loading="loading"
                  :assignable="false"
                  :canDelete="false"
              />

            </div>
            <div class="col-md-12 col-sm-12 col-lg-12 mt-3" v-if="isEdit">
              <GridLight
                  :disabled="!isUserRoleOperationManager"
                  label="Liste des risques"
                  :columns="evaluationRisksColumns"
                  :dataList="evaluationRisksList"
                  :loading="loading"
                  :assignable="false"
                  :canDelete="false"
              />
            </div>
            <div class="col-md-12 col-sm-12 col-lg-12 mt-3" v-if="isEdit">
              <GridLight
                  :disabled="!isUserRoleOperationManager"
                  label="Liste des matériels"
                  :columns="evaluationMaterialsColumns"
                  :dataList="evaluationMaterialsList"
                  :loading="loading"
                  :assignable="false"
                  :canDelete="false"
              />
            </div>
            <div class="col-md-12 col-sm-12 col-lg-12 mt-3" v-if="isEdit">
              <GridLight
                  :disabled="!isUserRoleOperationManager"
                  label="Liste des réponses aux questions générales"
                  :columns="evaluationGeneralQuestionsAnswerColumns"
                  :dataList="evaluationGeneralQuestionsList"
                  :loading="loading"
                  :assignable="false"
                  :canDelete="false"
              />
            </div>
            <div class="col-md-12 col-sm-12 col-lg-12 mt-3" v-if="isEdit">
              <GridLight
                  :disabled="!isUserRoleOperationManager"
                  label="Liste des informations à conserver"
                  :columns="evaluationAdditionalInfos"
                  :dataList="evaluationAdditionalInfosList"
                  :loading="loading"
                  :assignable="false"
                  :canDelete="false"
              />
            </div>
            <div class="col-md-12 col-sm-12 col-lg-12 mt-3" v-if="isEdit">
              <GridLight
                  :disabled="!isUserRoleOperationManager"
                  label="Liste des contrats"
                  :columns="evaluationAdditionalColumns"
                  :dataList="evaluationContractsList"
                  :loading="loading"
                  :assignable="false"
                  :canDelete="false"
                  :canDownload="true"
                  @download="downloadContract"
              />
            </div>
            <template slot="footer">
              <button class="btn btn-outline-primary" @click.prevent="cancel">Annuler</button>
            </template>
          </bo-card>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import FormDatePicker from "@/components/Form/DatePicker";
import Radio from "@/components/Form/Radio";
import GridLight from "@/components/Grid/GridLight";
import moment from 'moment';
import {API_URL} from "@/components/Util/EnvVariable";

export default {
  name: "BOEvaluationForm",
  components: {
    FormDatePicker,
    Radio,
    GridLight,
  },
  data() {
    return {
      bo: {
        active: true
      },
      saveLoading: false,
      habit: null,
      newHabit: {
        id: null,
        period: null,
        place: null,
        description: null
      },
      risk: null,
      actId: null,
      carePlanPrestation: null,
      evaluationPrestationColumns: [
        {name: "Nom de prestation", value: 'name'},
        {name: "Temps validé", value: 'time'},
      ],
      evaluationHabitsColumns: [
        {name: "Habitude", value: 'description'},
        {name: "Moment de la journée", value: 'period'},
        {name: "Lieu de vie", value: 'place'},
      ],
      evaluationRisksColumns: [
        {name: "Risque", value: 'risk'}
      ],
      evaluationMaterialsColumns: [
        {name: "Nom", value: "name"},
        {name: "Statut", value: "status"},
      ],
      evaluationGeneralQuestionsAnswerColumns: [
        {name: "Questions", value: "question"},
        {name: "Réponses", value: "answer"},
        {name: "Informations complémentaires", value: "text"},
      ],
      evaluationAdditionalColumns: [
        {name: "Crée le", value: "createdAt"},
        {name: "Signé le", value: "dateClose"},
        {name: "ÀIVQ Heure", value: "totalAIVQHours"},
        {name: "AVIQ €/sem", value: "totalAIVQCost"},
        {name: "AVQ Heure", value: "totalAVQHours"},
        {name: "AVQ €/sem", value: "totalAVQCost"},
        {name: "devis/contrat signé", value: "status"},
      ],
      evaluationAdditionalInfos: [
        {name: "Informations", value: "info"},
        {name: "Destinée à", value: "assignment"},
      ],
      removeableHabit: null,
      removeableAct: null,
      isHabitSubmitAssign: false
    };
  },
  computed: {
    ...mapState('Evaluation', ['evaluation', 'error', 'loading', 'generalQuestions']),
    ...mapState('Structure', ['structures']),
    ...mapState('Prestation', ['prestations']),
    ...mapState('Act', ['acts']),
    ...mapState('User', ['users']),
    ...mapState('Client', ['client', 'clients']),
    ...mapGetters('User', ['isUserRoleOperationManager']),
    ...mapGetters('Evaluation', ['getEstimatedTimeSum', 'getValidatedTimeSum', 'getSumOfPrestationCost']),
    ...mapState('AggirQuestion', ['placeRelatedList', 'itemPeriodList']),
    description() {
      return this.newHabit.description;
    },
    placeRelated() {
      return this.newHabit.place;
    },
    itemPeriod() {
      return this.newHabit.period;
    },
    structureId() {
      return this.evaluation.structureId;
    },
    evaluateById() {
      return this.evaluation.evaluateBy ? this.evaluation.evaluateBy.firstName + ' ' + this.evaluation.evaluateBy.lastName : '';
    },
    clientId() {
      return this.evaluation.clientId;
    },
    evaluationDate() {
      return this.evaluation.evaluationDate ? new Date(this.evaluation.evaluationDate) : null;
    },
    startingDate() {
      return this.evaluation.startingDate ? new Date(this.evaluation.startingDate) : null;
    },
    endingDate() {
      return this.evaluation.endingDate ? new Date(this.evaluation.endingDate) : null;
    },
    additionalInfos() {
      return this.evaluation.additionalInfos ? this.evaluation.additionalInfos : '';
    },
    estimatedTime() {
      return this.getEstimatedTimeSum;
    },
    estimatedCost() {
      return this.getSumOfPrestationCost('estimated', this.prestations)
    },
    validatedTime() {
      return this.getValidatedTimeSum
    },
    validatedCost() {
      return this.getSumOfPrestationCost('validated', this.prestations)
    },
    houseAccess() {
      return this.client.howOpenDoor ? this.client.howOpenDoor : null
    },
    clientFirstName() {
      return this.evaluation.client ? this.evaluation.client.firstname : null;
    },
    clientLastName() {
      return this.evaluation.client ? this.evaluation.client.lastname : null;
    },
    clientPhoneNumber() {
      return this.evaluation.client ? this.evaluation.client.portablePhoneNumber : null;
    },
    clientBirthDate() {
      return this.evaluation.client ? new Date(this.evaluation.client.birthDate) : null;
    },
    clientStatus() {
      return this.evaluation.client ? !!this.evaluation.client.status : null;
    },
    clientSex() {
      return this.client ? this.client.sex : null;
    },
    clientAddress() {
      return this.client ? this.client.address : null
    },
    clientPostalCode() {
      return this.client ? this.client.postalCode : null
    },
    clientCity() {
      return this.client ? this.client.city : null
    },
    clientBirthName() {
      return this.client ? this.client.birthName : null
    },
    isEdit() {
      return (this.$route.params.id !== undefined && this.$route.params.id !== null)
    },
    structureList() {
      if (this.structures.length === 0) {
        return [];
      }
      return this.structures.reduce((list, structure) => {
        list.push({value: structure.id, text: structure.name});
        return list;
      }, []);
    },
    evaluationPrestationsList() {
      return this.evaluation.carePlanPrestations ? this.evaluation.carePlanPrestations.map(function (item) {
        return {
          name: item.carePlanPrestation.name,
          time: item.carePlanPrestation.time + 'min'
        }
      }) : [];
    },
    actList() {
      if (this.acts.length === 0) {
        return [];
      }
      const currentActIds = this.evaluation.acts_ids;
      return this.acts.reduce((list, act) => {
        if (!currentActIds.includes(act.id)) {
          list.push({value: act.id, label: act.actName});
        }
        return list;
      }, []);
    },
    clientList() {
      if (this.clients.length === 0) {
        return [];
      }
      const clients = this.clients.reduce((list, client) => {
        list.push({value: client.id, text: `${client.firstname} ${client.lastname}`});
        return list;
      }, []);

      return clients;
    },
    evaluationHabitsList() {
      return this.evaluation.habits ? this.evaluation.habits : [];
    },
    evaluationRisksList() {
      return this.evaluation.risks ? this.evaluation.risks.map(function (item) {
        return {
          risk: item.risk.name
        }
      }) : [];
    },
    evaluationGeneralQuestionsList() {
      return this.evaluation.generalQuestionsAnswers ? this.evaluation.generalQuestionsAnswers.map((item) => {
        let answer = item.generalQuestion.responses.filter((response, index) => {
          return item.answers.includes(index)
        })
        return {
          question: item.generalQuestion ? item.generalQuestion.title.toLowerCase() : null,
          answer: answer ? answer.join(', ') : null,
          text: item.text ? item.text : null,
        }
      }) : []
    },
    evaluationMaterialsList() {
      if(this.evaluation.materials) {
        return this.evaluation.materials.reduce((acc, link) => {
          if(link.existing || link.required) {
            return [...acc, {
              name: link.material.name,
              status: link.required ? "A prévoir" : "Existant"
            }];
          }
          return acc;
        }, [])
      }
      return [];
    },
    evaluationAdditionalInfosList() {
      return this.evaluation.additionalInfos ? JSON.parse(this.evaluation.additionalInfos) : [];
    },
    evaluationContractsList() {
      return this.evaluation.contracts ? this.evaluation.contracts.map(function (item) {
        return {
          ...item,
          createdAt: item.createdAt ? moment(item.createdAt).format('DD/MM/YYYYY') : null,
          dateClose: item.dateClose ? moment(item.dateClose).format('DD/MM/YYYYY') : null,
        }
      }) : [];
    }
  },
  mounted: async function () {
    this.setInitEvaluation();
    if (this.isEdit) {
      await this.getEvaluation({id: this.$route.params.id})
      await this.getClient({id: this.evaluation.clientId})
    }
    this.getPrestationList({
      filters: [
        JSON.stringify({
          column: 'name',
          val: 'Actes'
        })
      ]
    });
  },
  methods: {
    ...mapMutations('Evaluation', ['setEvaluationProperty', 'setInitEvaluation', 'setRisk']),
    ...mapMutations('Client', ['setClientProperty', 'setInitClient']),
    ...mapActions('Evaluation', ['postEvaluation', 'putEvaluation', 'getEvaluation', 'postAssignHabit', 'postAssignRisk', 'deleteRisk']),
    ...mapActions('Structure', ['getStructureList']),
    ...mapActions('Prestation', ['getPrestationList']),
    ...mapActions('Act', ['getActList', 'getAct']),
    ...mapActions('CarePlanPrestation', ['getCarePlanPrestationList']),
    ...mapActions("Client", ["putClient", "getClient", "getClientList"]),
    cancel() {
      return this.$router.push({name: 'evaluation-list'});
    },
    downloadContract(contract) {
      window.open(`${API_URL}/download/devis/${contract.id}`, '_blank');
      window.open(`${API_URL}/download/synthese/${contract.documentSyntheseId}`, '_blank');
    }
  }
};
</script>