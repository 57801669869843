import BO from "@/views/BO/Index";
import BOTarificationList from "@/views/BO/Tarification/List";
import BOTarificationForm from "@/views/BO/Tarification/Form";
import BOPrestationList from "@/views/BO/Prestation/List";
import BOPrestationForm from "@/views/BO/Prestation/Form";
import BOAgencyList from "@/views/BO/Agency/List";
import BOAgencyForm from "@/views/BO/Agency/Form";
import BOUserList from "@/views/BO/User/List";
import BOUserForm from "@/views/BO/User/Form";
import BOStructureList from "@/views/BO/Structure/List";
import BOStructureForm from "@/views/BO/Structure/Form";
import BOContractList from "@/views/BO/Contract/List";
import BOContractForm from "@/views/BO/Contract/Form";
import BOClientList from "@/views/BO/Client/List";
import BOClientCalendar from "@/views/BO/Client/Calendar";
import BOClientForm from "@/views/BO/Client/Form";
import Notification from "@/views/BO/Notification/Notification";
import BOEvaluationList from "@/views/BO/Evaluation/List";
import BOEvaluationForm from "@/views/BO/Evaluation/Form";
import BOAggirQuestionList from "@/views/BO/AggirQuestion/List";
import BOAggirQuestionForm from "@/views/BO/AggirQuestion/Form";
import BOMaterialList from "@/views/BO/Material/List";
import BOMaterialForm from "@/views/BO/Material/Form";
import BOInterventionList from "@/views/BO/Intervention/List";
import BOInterventionForm from "@/views/BO/Intervention/Form";
import BOStatistiquesChart from "@/views/BO/Statistiques/Chart";
import BORoleList from "@/views/BO/Role/List";
import BORoleForm from "@/views/BO/Role/Form";
import BOAlertist from "@/views/BO/Alert/List";
import Cgu from '@/views/CGU/Cgd';
import Rgpd from '@/views/CGU/Rgpd';
import Mention from '@/views/CGU/Mention';
import state from "../store/Modules/User/state";

export default [
    {
        path: '/bo',
        name: "bo",
        component: BO,
        children: [
            {path: 'liste-tarification', name: "tarification-list", component: BOTarificationList},
            {path: 'tarification/:id?', name: "tarification-form", component: BOTarificationForm},
            {path: 'liste-prestation', name: "prestation-list", component: BOPrestationList},
            {path: 'prestation/:id?', name: "prestation-form", component: BOPrestationForm},
            {path: 'liste-agence', name: "agency-list", component: BOAgencyList},
            {path: 'agence/:id?', name: "agency-form", component: BOAgencyForm},
            {path: 'liste-personnel', name: "user-list", component: BOUserList},
            {path: 'personnel/:id?', name: "user-form", component: BOUserForm},
            {path: 'liste-structure', name: "structure-list", component: BOStructureList},
            {path: 'structure/:id?', name: "structure-form", component: BOStructureForm},
            {path: 'liste-contract', name: "contract-list", component: BOContractList},
            {path: 'contract/:id?', name: "contract-form", component: BOContractForm},
            {path: 'liste-client', name: "client-list", component: BOClientList},
            {path: 'planning-client/:id', name: "calendar-client", component: BOClientCalendar},
            {path: 'client/:id?', name: "client-form", component: BOClientForm},
            {path: "notification", name: "notification", component: Notification},
            {path: 'liste-evaluation', name: "evaluation-list", component: BOEvaluationList},
            {path: 'evaluation/:id', name: "evaluation-form", component: BOEvaluationForm},
            {path: 'liste-aggir-question', name: "aggir-question-list", component: BOAggirQuestionList},
            {path: 'aggir-question/:id?', name: "aggir-question-form", component: BOAggirQuestionForm},
            {path: 'liste-material', name: "material-list", component: BOMaterialList},
            {path: 'material/:id?', name: "material-form", component: BOMaterialForm},
            {path: 'liste-intervention', name: "intervention-list", component: BOInterventionList},
            {path: 'profil-list', name: "profil-list", component: BORoleList},
            {path: 'profil-form', name: "profil-form", component: BORoleForm},
            {path: 'edit-form/:id', name: "edit-form", component: BORoleForm},
            {path: 'intervention/:id?', name: "intervention-form", component: BOInterventionForm},
            {path: 'statistiques-chart', name: "statistiques-chart", component: BOStatistiquesChart},
            {path: 'alert', name: "alert", component: BOAlertist},
            {path: 'cgu', name: 'cgu', component: Cgu, props: { showHeaders: false }},
            {path: 'mentions', name: 'mentions', component: Mention, props: { showHeaders: false }},
            {path: 'rgpd', name: 'rgpd', component: Rgpd, props: { showHeaders: false }}
        ],
        beforeEnter: (to, from, next) => {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

            if (state.connectedUser && state.connectedUser.id !== null && permissionsArray.includes('CAN_BACK_OFFICE')) {
              next();
            } else {
              //next({ name: 'login' });
            }
        },
    }
];
