<template>
  <div class="container pt-4">
    <h5 class="eval-item--title">Etat de santé</h5>
    <div class="eval-item--card cr--context">
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Recueil de la personne sur son état de santé :</p>
          <p class="response" v-html="clientHealth"></p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p class="mb-0">
            Jugement sur son état de santé par rapport aux autres personnes du
            même âge :
          </p>
          <p class="response">{{ healthJugement }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Taille :</p>
          <p class="response">{{ clientDimensions.size }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Poids :</p>
          <p class="response">{{ clientDimensions.weight }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Hospitalisation au cours des 6 derniers mois:</p>
          <p class="response">{{ hospital }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Statut vaccinal :</p>
          <p class="response">{{ vaccin.status }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Libellé :</p>
          <p class="response">{{ vaccin.status_label }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Signes d'une infection en cours:</p>
          <p class="response">{{ vaccin.infection }}</p>
        </div>

        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Description des symptômes:</p>
          <p class="response">{{ vaccin.infection_description }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-8 cr_entry">
          <p>
            Maladie aigüe ou stress psychologique au cours des 3 derniers mois:
          </p>
          <p class="response">{{ previousStress }}</p>
        </div>
      </div>

      <h5 class="cr-health--title">NUTRITION</h5>
      <div class="row">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Indice de Masse Corporelle (I.M.C.) :</p>
          <p class="response" v-html="nutritionalState.imc"></p>
        </div>
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Interprétation de l'IMC :</p>
          <p class="response">{{ nutritionalState.interpretation }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 cr_entry">
          <p>Type alimentation :</p>
          <p class="response">{{ alimentationType.value }}</p>
        </div>
      </div>
      <div v-if="!alimentationType.usingSonde">
        <div class="row">
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Prises alimentaires au cours des 3 derniers mois :</p>
            <p class="response">{{ lastMonthsEatStatus.aliment }}</p>
          </div>
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Perte de poids au cours des 3 derniers mois :</p>
            <p class="response">{{ lastMonthsEatStatus.weight }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Résultat du MNA de dépistage :</p>
            <p class="response">{{ mnaTest }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Régime médical :</p>
            <p class="response">{{ medicalStatus.regime }}</p>
          </div>
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Allergies, intolérances :</p>
            <p class="response">{{ medicalStatus.allergy }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Difficultés de Mastication et/ou déglutition :</p>
            <p class="response">{{ medicalStatus.eatingDifficulties }}</p>
          </div>
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Texture :</p>
            <p class="response">{{ medicalStatus.texture }}</p>
          </div>
        </div>
      </div>
      <div v-if="alimentationType.usingSonde">
        <div class="row">
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Nature du dispositif :</p>
            <p class="response">{{ sondeRelatedAnswer.sn15 }}</p>
          </div>
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Hydratation possible par la bouche :</p>
            <p class="response">{{ sondeRelatedAnswer.hydratation }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Qui s'occupe de l'alimentation entérale ?</p>
            <p class="response">{{ sondeRelatedAnswer.caregiver }}</p>
          </div>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Appareil dentaire :</p>
            <p class="response">{{ medicalStatus.brace }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Résultat du MNA de dépistage :</p>
            <p class="response">{{ mnaTest }}</p>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">ORGANES DES SENS</h5>
      <div class="row">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Port de lunettes :</p>
          <p class="response">{{ wearGlasses }}</p>
        </div>
        <div class="col-sm-12 col-md-6 cr_entry" v-if="eyeAdaptation.display">
          <p>
            Difficultés pour voir de loin ou lire ? maladies occulaires ?
            Diabète ? HTA? :
          </p>
          <p class="response">{{ eyeAdaptation.resp }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Troubles de l'audition :</p>
          <p class="response">{{ hearingProblems }}</p>
        </div>
        <div class="col-sm-12 col-md-6 cr_entry" v-if="hearingAid.display">
          <p>Appareillage auditif :</p>
          <p class="response">{{ hearingAid.resp }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Ouïe – Test de Whisper (test du chuchotement) OG :</p>
          <p class="response">{{ icopeAudition.og }}</p>
        </div>
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Ouïe – Test de Whisper (test du chuchotement) OD :</p>
          <p class="response">{{ icopeAudition.od }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Gout, Odorat :</p>
          <p class="response">{{ tasteSmell }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 cr_entry">
          <p>Sensibilité, toucher :</p>
          <p class="response">{{ sensibility }}</p>
        </div>
      </div>

      <h5 class="cr-health--title">FONCTIONS RESPIRATOIRES ET CIRCULATOIRES</h5>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Essouflement :</p>
          <p class="response">{{ panting }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Position pour dormir :</p>
          <p class="response">{{ sleepingPosition }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 cr_entry">
          <p>Oxygène médical :</p>
          <p class="response">{{ medicalOxygen }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Stimulateur cardiaque :</p>
          <p class="response">{{ pacemaker }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Œdème des membres inférieurs :</p>
          <p class="response">{{ lowerEdema }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Bas de contention et/ou Jambes surélevées pour dormir:</p>
          <p class="response">{{ raisedLegs }}</p>
        </div>
      </div>
      <h5 class="cr-health--title">FONCTIONS D'ELIMINATION</h5>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Rappel autonomie pour la gestion de l’élimination urinaire :</p>
          <p class="response">{{ eliminationAggir.urine }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Rappel autonomie pour la gestion de l’élimination fécale :</p>
          <p class="response">{{ eliminationAggir.faecal }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Difficultés pour uriner :</p>
          <p class="response">{{ sfe07 }}</p>
        </div>
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Troubles ressentis du transit intestinal :</p>
          <p class="response">{{ faecalElimination.troubles }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Port de protection pour incontinence :</p>
          <p class="response">{{ sfe08 }}</p>
        </div>
        <div class="col-sm-12 col-md-6 cr_entry" v-if="stomia.display">
          <p>Existence d’une stomie et type :</p>
          <p class="response">{{ stomia.stomia }}, {{ stomia.type }}</p>
        </div>
      </div>
      <div class="row" v-if="stomia.display">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Gestion de la poche de stomie :</p>
          <p class="response">{{ stomia.gestion }}</p>
        </div>
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Gestion du support de stomie :</p>
          <p class="response">{{ stomia.support }}</p>
        </div>
      </div>
      <div class="row" v-if="urineElimination.display">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Présence d’une sonde vésicale à demeure ou d’un étui pénien :</p>
          <p class="response">{{ urineElimination.sonde }}</p>
        </div>
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Gestion du collecteur à urine :</p>
          <p class="response">{{ urineElimination.gestion }}</p>
        </div>
      </div>

      <h5 class="cr-health--title">
        FONCTIONS MOTRICES, EQUILIBRE, RISQUE DE CHUTE
      </h5>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Déficit moteur :</p>
          <p class="response">{{ motorFailure.state }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Aphasie :</p>
          <p class="response">{{ sfm11 }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Prothèse, orthèse :</p>
          <p class="response">{{ motorEquipment.prothesis }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry" v-if="motorEquipment.display">
          <p>Description :</p>
          <p class="response">{{ motorEquipment.description }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Chaussures orthopédiques :</p>
          <p class="response">{{ motorEquipment.shoes }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Déplacements :</p>
          <p class="response">{{ deplacements }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Difficultés a certains efforts ou au port de charges :</p>
          <p class="response">{{ effortDifficulties }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Mobilité- Test du lever de chaise :</p>
          <p class="response">{{ motorTest.chairLiftTest }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Equilibre – Test d’équilibre :</p>
          <p class="response">{{ motorTest.equilibreTest }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Marche – Test de marche :</p>
          <p class="response">{{ motorTest.walkTest }}</p>
        </div>
      </div>

      <h5 class="cr-health--title">PEAU, TEGUMENTS</h5>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>ATCD d'escarre :</p>
          <p class="response">{{ escarus.atcd }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Escarres actuels :</p>
          <p class="response">{{ escarus.currentStatus }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry" v-if="escarus.display">
          <p>Localisation :</p>
          <p class="response">{{ escarus.localisation }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 cr_entry">
          <p>Risque d'escarre Echelle de norton :</p>
          <p class="response">{{ norton }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Autres problèmes de peau :</p>
          <p class="response">{{ otherSkinProblems.state }}</p>
        </div>
        <div
          class="col-sm-12 col-md-4 cr_entry"
          v-if="otherSkinProblems.display"
        >
          <p>Description :</p>
          <p class="response">{{ otherSkinProblems.description }}</p>
        </div>
      </div>

      <h5 class="cr-health--title">DOULEUR</h5>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Capacité a communiquer sur ses douleurs :</p>
          <p class="response">{{ painDetails.communication }}</p>
        </div>
        <div
          class="col-sm-12 col-md-4 cr_entry"
          v-if="painDetails.communication === 'Oui'"
        >
          <p>Echelle EVA de la douleur (0 à 10) :</p>
          <p class="response">{{ painDetails.eva }}</p>
        </div>
        <div
          class="col-sm-12 col-md-4 cr_entry"
          v-if="painDetails.communication === 'Oui'"
        >
          <p>Nature des douleurs :</p>
          <p class="response">{{ painDetails.nature }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Manifestations somatiques de la douleur :</p>
          <p class="response">{{ painDetails.manifestation }}</p>
        </div>
        <div
          class="col-sm-12 col-md-4 cr_entry"
          v-if="painDetails.manifestation === 'Oui'"
        >
          <p>Description :</p>
          <p class="response">{{ painDetails.description }}</p>
        </div>
      </div>

      <h5 class="cr-health--title">COGNITION, MEMOIRE, DEPRESSION</h5>
      <div class="row">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Résultat du test mini Cog :</p>
          <p class="response">{{ miniCogTest.total }}</p>
        </div>
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Interprétation Mini Cog :</p>
          <p class="response">{{ miniCogTest.interpretation }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Résultat AGGIR orientation dans le temps :</p>
          <p class="response">{{ brainAggir.timeOrientation }}</p>
        </div>
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Résultat AGGIR orientation dans l'espace :</p>
          <p class="response">{{ brainAggir.spaceOrientation }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Résultat AGGIR communication :</p>
          <p class="response">{{ brainAggir.communication }}</p>
        </div>
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Résultat AGGIR comportement :</p>
          <p class="response">{{ brainAggir.comportement }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Résultat du test mini GDS (gériatric Depressive syndrome) :</p>
          <p class="response">{{ miniGdsTestTotal }}</p>
        </div>
        <div class="col-sm-12 col-md-6 cr_entry">
          <p>Interprétation test Mini GDS :</p>
          <p class="response">{{ miniGdsTest }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 cr_entry">
          <p>Résultat test ICOPE Cognition :</p>
          <p class="response">{{ icopeBrain.cognition }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 cr_entry">
          <p>Résultat test ICOPE Dépression :</p>
          <p class="response">{{ icopeBrain.depression }}</p>
        </div>
      </div>

      <h5 class="cr-health--title">TRAITEMENT, SUIVI MEDICAL</h5>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Nombre de médicaments différents pris par jour :</p>
          <p class="response">{{ drugsDetails.number }}</p>
        </div>
        <div
          class="col-sm-12 col-md-4 cr_entry"
          v-if="
            drugsDetails.number !== 'NSP' &&
            drugsDetails.number !== this.DEFAULT_VALUE
          "
        >
          <p>Répartition des prises sur la journée :</p>
          <p class="response">{{ drugsDetails.moments }}</p>
        </div>
        <div
          class="col-sm-12 col-md-4 cr_entry"
          v-if="
            drugsDetails.number !== 'NSP' &&
            drugsDetails.number !== this.DEFAULT_VALUE
          "
        >
          <p>Gestion de la préparation et de la prise :</p>
          <p class="response">{{ drugsDetails.gestion }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Résultat AGGIR traitement :</p>
          <p class="response">{{ aggirTraitement }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Autres modes d'administration des médicaments :</p>
          <p class="response">{{ drugsDetails.otherDrugs }}</p>
        </div>
        <div
          class="col-sm-12 col-md-4 cr_entry"
          v-if="drugsDetails.otherDrugs === 'Oui'"
        >
          <p>Description voie d'administation et fréquence :</p>
          <p class="response">{{ drugsDetails.description }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Fréquence de consultations du médecin :</p>
          <p class="response">{{ doctorDetails.frequency }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Le médecin habituel est-il référencé comme Médecin traitant :</p>
          <p class="response">{{ doctorDetails.isTraitant }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Spécialistes consultés au décours des 12 derniers mois :</p>
          <p class="response">{{ doctorDetails.lastDoctor }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Visites infimière, SSIAD, HAD :</p>
          <p class="response">{{ healthWorkers.visits }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Type de soins infirmiers et fréquence :</p>
          <p class="response">{{ healthWorkers.careType }}</p>
        </div>
        <div class="col-sm-12 col-md-4 cr_entry">
          <p>Autres intervenants réguliers de santé :</p>
          <p class="response">{{ healthWorkers.otherIntervenant }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <p class="mb-0">Commentaires de l'évaluateur sur l'état de santé :</p>

          <form-textarea
            v-model="healthComment"
            :rows="3"
            class="w-100"
            :login-input="true"
            :disabled="false"
            @blur="changeCommentaryValue()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CRComponent from "@/views/Evaluation/CR/CRComponent";
import { formatSex } from "@/components/Util/UtilFunction";
import { mapGetters, mapMutations, mapState } from "vuex";
import {
  imcCalcul,
  mnaCalcul,
  chairLift,
  timeUpAndGo,
  movingInterpretation,
  nortonCalcul,
  miniCogCalcul,
  miniGdsCalcul,
  getICOPECognition,
  getRSMD05,
  balance,
  getICOPENutritionSn04,
  getICOPENutritionSn05,
  DEFAULT_AGGIR_VALUE,
} from "@/components/Eval/SoinCalcul";

export default {
  name: "CR",
  extends: CRComponent,
  created() {
    this.DEFAULT_VALUE = "--";
  },
  methods: {
    ...mapMutations("Evaluation", ["setEvaluationProperty"]),
    formatSex,
    exportSynthesis() {
      const sfe07 = this.sfe07;
      const sfe08 = this.sfe08;
      const sfm10 = this.motorTest.walkTest;
      const sfm11 = this.sfm11;
      return {
        sg01: this.general.sg01.response
          ? this.general.sg01.response
          : this.DEFAULT_VALUE,
        healthJugement: this.healthJugement,
        clientSize: this.clientDimensions.size,
        clientWeight: this.clientDimensions.weight,
        hospital: this.hospital,
        vaccinStatus: this.vaccin.status,
        vaccinLabel: this.vaccin.status_label,
        signeInfection: this.vaccin.infection,
        infectionDescription: this.vaccin.infection_description,
        previousStress: this.previousStress,
        imc: this.imc ? this.imc : this.DEFAULT_VALUE,
        imcInterpretation: this.nutritionalState.interpretation,
        alimentationType: this.alimentationType.value,
        isNotFedBySonde: !this.alimentationType.usingSonde,
        isFedBySonde: this.alimentationType.usingSonde,
        lastMonthsAliment: this.lastMonthsEatStatus.aliment,
        lastMonthsWeight: this.lastMonthsEatStatus.weight,
        mna: this.mnaTest,
        medicalStatusRegime: this.medicalStatus.regime,
        medicalStatusAllergy: this.medicalStatus.allergy,
        medicalStatusEatingDifficulties: this.medicalStatus.eatingDifficulties,
        medicalStatusTexture: this.medicalStatus.texture,
        medicalStatusBrace: this.medicalStatus.brace,
        sondeType: this.sondeRelatedAnswer.sn15,
        sondeHydratation: this.sondeRelatedAnswer.hydratation,
        sondeCaregiver: this.sondeRelatedAnswer.caregiver,
        wearGlasses: this.wearGlasses,
        eyeAdaptation: this.eyeAdaptation.display
          ? this.eyeAdaptation.resp
          : "Non concerné",
        hearingProblems: this.hearingProblems,
        hearingAid: this.hearingAid.display
          ? this.hearingAid.resp
          : "Non concerné",
        whisperOg: this.icopeAudition.og,
        whisperOd: this.icopeAudition.od,
        tasteSmell: this.tasteSmell,
        sensibility: this.sensibility,
        panting: this.panting,
        sleepingPosition: this.sleepingPosition,
        medicalOxygen: this.medicalOxygen,
        pacemaker: this.pacemaker,
        lowerEdema: this.lowerEdema,
        raisedLegs: this.raisedLegs,
        limbParalysis: this.limbParalysis,
        aphasia: this.aphasia,
        stomia: this.stomia.stomia,
        stomiaSupport: this.stomia.support,
        stomiaType:
          this.stomia.stomia === "Oui" ? this.stomia.type : "Non concerné",
        stomiaGestion:
          this.stomia.stomia === "Oui" ? this.stomia.gestion : "Non concerné",
        aggirUrine: this.eliminationAggir.urine,
        aggirFaecal: this.eliminationAggir.faecal,
        faecalEliminationTroubles: this.faecalElimination.troubles,
        faecalEliminationMasteries: this.faecalElimination.masteries,
        urineEliminationTroubles: this.urineElimination.troubles,
        urineEliminationSonde: this.urineElimination.sonde,
        urineEliminationGestion: this.urineElimination.gestion,
        motorFailureState: this.motorFailure.state,
        motorFailureType: this.motorFailure.type,
        motorFailureDescription: this.motorFailure.description,
        motorEquipmentProthesis: this.motorEquipment.prothesis,
        motorEquipmentDescription: this.motorEquipment.description,
        motorEquipmentShoes: this.motorEquipment.shoes,
        deplacements: this.deplacements,
        effortDifficulties: this.effortDifficulties,
        chairLiftTest: this.motorTest.chairLiftTest,
        timeUpTest: this.motorTest.equilibreTest,
        escarusATCD: this.escarus.atcd,
        escarusStatus: this.escarus.currentStatus,
        escarusLocalisation: this.escarus.localisation,
        norton: this.norton,
        otherSkinsProblems: this.otherSkinProblems.state,
        otherSkinsProblemsDescription: this.otherSkinProblems.description,
        painCommunication: this.painDetails.communication,
        painEva: this.painDetails.eva,
        painNature: this.painDetails.nature,
        painManifestation: this.painDetails.manifestation,
        painDescription: this.painDetails.description,
        miniCogTotal: this.miniCogTest.total,
        miniCogInterpretation: this.miniCogTest.interpretation,
        brainTimeOrientation: this.brainAggir.timeOrientation,
        brainSpaceOrientation: this.brainAggir.spaceOrientation,
        brainCommunication: this.brainAggir.communication,
        brainComportement: this.brainAggir.comportement,
        drugsDetailsNumber: this.drugsDetails.number,
        drugsDetailsMoments: this.drugsDetails.moments,
        drugsDetailsGestion: this.drugsDetails.gestion,
        otherDrugs: this.drugsDetails.otherDrugs,
        drugsDescription: this.drugsDetails.description,
        doctorFrequency: this.doctorDetails.frequency,
        doctorIsTraitant: this.doctorDetails.isTraitant,
        doctorLastDoctor: this.doctorDetails.lastDoctor,
        healthWorkersVisits: this.healthWorkers.visits,
        healthWorkersCareType: this.healthWorkers.careType,
        healthWorkersOthers: this.healthWorkers.otherIntervenant,
        healthComment: this.healthComment,
        aggirTraitement: this.aggirTraitement,
        miniGdsTestTotal: this.miniGdsTestTotal,
        miniGdsTest: this.miniGdsTest,
        sfe07: sfe07 ? sfe07 : "",
        sfe08: sfe08 ? sfe08 : "",
        sfm10: sfm10 ? sfm10 : "",
        sfm11: sfm11 ? sfm11 : "",
      };
    },
  },
  computed: {
    ...mapState("Evaluation", [
      "evaluation",
      "general",
      "nutrition",
      "sense",
      "breathing",
      "elimination",
      "motor",
      "skin",
      "pain",
      "brain",
      "treatment",
      "loading",
    ]),
    ...mapGetters("Material", ["getMaterials"]),
    ...mapGetters("AggirQuestion", [
      "getScoreQuestionAggirMoveOut",
      "getScoreQuestionAggirMoveIn",
      "getScoreQuestionAggirTransfert",
      "getScoreQuestionAggirOrientationTemps",
      "getScoreQuestionAggirOrientationEspace",
      "getScoreQuestionAggirCoherenceComportement",
      "getScoreQuestionAggirCoherenceCommunication",
      "getScoreQuestionAggirEliminationFecale",
      "getScoreQuestionAggirEliminationUrinaire",
    ]),
    clientHealth() {
      const sex = this.client ? this.client.sex : this.DEFAULT_VALUE;
      let formattedSex = formatSex(sex);
      return `Sur son état de santé, <span class="cr--response">${formattedSex}, ${
        this.client.firstname
      } ${
        this.client.lastname
      }</span>, nous indique que <span class="cr--response">${
        this.general.sg01.response
          ? this.general.sg01.response
          : this.DEFAULT_VALUE
      }</span>.`;
    },
    healthJugement() {
      const cond = this.general.sg02.response;
      const sex = this.client ? this.client.sex : this.DEFAULT_VALUE;
      let formattedSex = formatSex(sex);

      if (cond && cond === "r4") {
        return `Par rapport aux personnes du même âge, ${formattedSex}, ${this.client.firstname} ${this.client.lastname}, ne sait pas comparer son état de santé`;
      } else if (cond) {
        const resp =
          cond === "r1"
            ? "Equivalent"
            : cond === "r2"
            ? "Plutôt meilleur"
            : "Plutôt moins bon";
        return `Par rapport aux personnes du même âge, ${formattedSex}, ${this.client.firstname} ${this.client.lastname} considère que son état de santé est ${resp}.`;
      }
      return this.DEFAULT_VALUE;
    },
    clientDimensions() {
      return {
        size:
          this.nutrition && this.nutrition.sn01size.response
            ? this.nutrition.sn01size.response
            : this.DEFAULT_VALUE,
        weight:
          this.nutrition && this.nutrition.sn01weight.response
            ? this.nutrition.sn01weight.response
            : this.DEFAULT_VALUE,
      };
    },
    hospital() {
      const resp = this.general.sg03.response;
      return resp
        ? resp === "r1"
          ? "Oui"
          : resp === "r2"
          ? "Non"
          : "NSP"
        : this.DEFAULT_VALUE;
    },
    vaccin() {
      const sg05 = this.general.sg05.response
        ? this.general.sg05.response
        : this.DEFAULT_VALUE;
      const status = {
        r1: "Grippe",
        r2: "Covid",
      };
      const infection = {
        r1: "Oui",
        r2: "Non",
        r3: "Ne sais pas",
      };
      return {
        status:
          sg05 !== this.DEFAULT_VALUE
            ? this.stringifyCheckboxValues(sg05, status)
            : sg05,
        status_label: this.general.sg06.response
          ? this.general.sg06.response
          : this.DEFAULT_VALUE,
        infection: this.general.sg04.response
          ? this.stringifyCheckboxValues(this.general.sg04.response, infection)
          : this.DEFAULT_VALUE,
        infection_description: this.general.sg07.response
          ? this.general.sg07.response
          : this.DEFAULT_VALUE,
      };
    },
    previousStress() {
      const resp = this.nutrition.sn06.response;
      return resp ? (resp === "r1" ? "Oui" : "Non") : this.DEFAULT_VALUE;
    },
    imc() {
      return imcCalcul(
        this.nutrition.sn01weight.response,
        this.nutrition.sn01size.response
      );
    },
    nutritionalState() {
      const sex = this.client ? this.client.sex : this.DEFAULT_VALUE;
      let formattedSex = formatSex(sex);
      const imc = this.imc ? this.imc : this.DEFAULT_VALUE;
      const interpretation =
        imc && imc <= 16
          ? "est en dénutrition sévère"
          : imc > 16 && imc <= 18
          ? "est en dénutrition modérée"
          : imc > 18 && imc <= 25
          ? "est de poids normal"
          : imc > 25 && imc <= 30
          ? "est en surpoids"
          : imc > 30 && imc <= 40
          ? "présente une obésité modérée"
          : imc > 40
          ? "présente une obésité sévère"
          : this.DEFAULT_VALUE;
      return {
        imc: `L'IMC est de <span class="cr--response">${imc}</span>. `,
        interpretation: `l'IMC de ${imc} indique que ${formattedSex}, ${this.client.firstname} ${this.client.lastname}, ${interpretation}".`,
      };
    },
    alimentationType() {
      let value = this.DEFAULT_VALUE;
      let usingSonde = null;
      const cond = this.nutrition.sn03 ? this.nutrition.sn03 : null;

      if (cond && cond.response && cond.response === "r1") {
        value = "Nutrition par sonde";
        usingSonde = true;
      } else if (cond && cond.response && cond.response !== "r1") {
        value = "Per os";
        usingSonde = false;
      }
      return {
        value: value,
        usingSonde: usingSonde,
      };
    },
    lastMonthsEatStatus() {
      return {
        aliment: this.nutrition.sn04.response
          ? getICOPENutritionSn04(this.nutrition.sn04.response)
          : this.DEFAULT_VALUE,
        weight: this.nutrition.sn05.response
          ? getICOPENutritionSn05(this.nutrition.sn05.response)
          : this.DEFAULT_VALUE,
      };
    },
    mnaTest() {
      const sn03 = this.nutrition.sn03.response;
      const sn04 = this.nutrition.sn04.response;
      const sn05 = this.nutrition.sn05.response;
      const indoorDeplacement = this.getAggirAnswerScore(2);
      const coherence = this.getAggirAnswerScore(23);
      const sn06 = this.nutrition.sn06.response;

      let score = this.DEFAULT_VALUE;
      let interpretation = "en attente d'éléments";

      const mna = mnaCalcul(
        this.getPeopleStr,
        this.imc,
        sn03,
        sn04,
        sn05,
        sn06,
        indoorDeplacement,
        coherence
      );
      if (mna) {
        score = mna.score;
        interpretation = mna.interpretation;
      }

      return `Le résultat du MNA de dépistage est ${score}. ${interpretation}.`;
    },
    medicalStatus() {
      const regime = {
        r1: "Anti cholestérol",
        r2: "Anti triglycérides",
        r3: "Anti diarrhéique",
        r4: "Diabétique",
        r5: "Epargne colique, constipation",
        r6: "Hypo protidique",
        r7: "Hyper protidique",
        r8: "Sans sel",
        r9: "Hypo sodé",
      };
      const sn07 = this.nutrition.sn07.response
        ? this.nutrition.sn07.response
        : this.DEFAULT_VALUE;
      let sn08 = this.nutrition.sn08.response
        ? this.stringifyCheckboxValues(this.nutrition.sn08.response, regime)
        : null;
      const sn09 = this.nutrition.sn09.response
        ? this.nutrition.sn09.response
        : this.DEFAULT_VALUE;
      const sn10 = this.nutrition.sn10.response
        ? this.nutrition.sn10.response
        : null;
      const sn11 = this.nutrition.sn11.response
        ? this.nutrition.sn11.response
        : this.DEFAULT_VALUE;
      const sn12 = this.nutrition.sn12.response
        ? this.nutrition.sn12.response
        : null;
      const sn13 = this.nutrition.sn13.response
        ? this.nutrition.sn13.response
        : this.DEFAULT_VALUE;
      const sn14 = this.nutrition.sn14.response
        ? this.nutrition.sn14.response
        : this.DEFAULT_VALUE;

      return {
        regime:
          (sn07 === "r1"
            ? "Oui"
            : sn07 === "r2"
            ? "Non"
            : sn07 === "r3"
            ? "NSP"
            : sn07) + (sn08 ? ", " + sn08 : ""),
        allergy:
          (sn09 === "r1"
            ? "Oui"
            : sn09 === "r2"
            ? "Non"
            : sn09 === "r3"
            ? "NSP"
            : sn09) + (sn10 ? ", " + sn10 : ""),
        eatingDifficulties:
          (sn11 === "r1"
            ? "Oui"
            : sn11 === "r2"
            ? "Non"
            : sn11 === "r3"
            ? "NSP"
            : sn11) + (sn12 ? ", " + sn12 : ""),
        texture:
          sn13 === "r1"
            ? "Normale"
            : sn13 === "r2"
            ? "Hachée"
            : sn13 === "r3"
            ? "Mixée"
            : sn13,
        brace:
          sn14 === "r1"
            ? "Oui"
            : sn14 === "r2"
            ? "Non"
            : sn14 === "r3"
            ? "NSP"
            : sn14,
      };
    },
    sondeRelatedAnswer() {
      const sn15 = this.nutrition.sn15.response
        ? this.nutrition.sn15.response
        : this.DEFAULT_VALUE;
      const sn16 = this.nutrition.sn16.response
        ? this.nutrition.sn16.response
        : this.DEFAULT_VALUE;
      const sn17 = this.nutrition.sn17.response
        ? this.nutrition.sn17.response
        : this.DEFAULT_VALUE;

      const sondeType = {
        r1: "Sonde naso-gastrique/naso-jejunale",
        r2: "Sonde de gastrostomie/jejunostomie",
      };

      const caregiver = {
        r1: "Personne elle-même",
        r2: "Conjoint/famille",
        r3: "Infirmière/service de soin",
        r4: "NSP",
      };

      return {
        sn15: this.alimentationType.usingSonde
          ? `${this.getPeopleStr}, est nourrie par une ${
              sn15 !== this.DEFAULT_VALUE ? sondeType[sn15] : sn15
            }.`
          : this.DEFAULT_VALUE,
        hydratation: this.alimentationType.usingSonde
          ? sn16 === "r1"
            ? "Oui"
            : sn16 === "r2"
            ? "Non"
            : sn16 === "r3"
            ? "NSP"
            : sn16
          : this.DEFAULT_VALUE,
        caregiver: this.alimentationType.usingSonde
          ? sn17 !== this.DEFAULT_VALUE
            ? this.stringifyCheckboxValues(sn17, caregiver)
            : sn17
          : this.DEFAULT_VALUE,
      };
    },
    wearGlasses() {
      const sos01 = this.sense.sos01.response
        ? this.sense.sos01.response
        : this.DEFAULT_VALUE;
      return sos01 === "r1"
        ? "Oui"
        : sos01 === "r2"
        ? "Non"
        : sos01 === "r3"
        ? "NSP"
        : sos01;
    },
    eyeAdaptation() {
      const cond = this.wearGlasses;
      if (cond && cond === "Oui") {
        const sos02 = this.sense.sos02.response
          ? this.sense.sos02.response
          : this.DEFAULT_VALUE;
        let text = "";
        if (sos02 === "r1") {
          text = "Une évaluation complète de la vue est recommandée.";
        }
        if (sos02 === "r2") {
          text = "Pas d'anomalie visuelle déclarée.";
        }
        if (sos02 === "r3") {
          text = "La fonction visuelle n'a pas pu être explorée.";
        }

        return {
          resp: text,
          display: true,
        };
      }
      return {
        resp: null,
        display: false,
      };
    },
    hearingProblems() {
      const sos05 = this.sense.sos05.response
        ? this.sense.sos05.response
        : this.DEFAULT_VALUE;
      return sos05 === "r1"
        ? "Oui"
        : sos05 === "r2"
        ? "Non"
        : sos05 === "r3"
        ? "NSP"
        : sos05;
    },
    hearingAid() {
      const cond = this.hearingProblems;
      if (cond && cond === "Oui") {
        const sos06 = this.sense.sos06.response
          ? this.sense.sos06.response
          : this.DEFAULT_VALUE;
        return {
          resp:
            sos06 === "r1"
              ? "Oui"
              : sos06 === "r2"
              ? "Non"
              : sos06 === "r3"
              ? "NSP"
              : sos06,
          display: true,
        };
      }
      return {
        resp: null,
        display: false,
      };
    },
    icopeAudition() {
      const sos08Responses = [
        { value: "r1", label: "Répète correctement" },
        { value: "r2", label: "Répète incorrectement ou ne répète pas" },
        { value: "r3", label: "NSP" },
        { value: "r4", label: "Test non réalisé" },
        { value: "r5", label: "Test non réalisable" },
      ];
      const sos07 =
        this.sense && this.sense.sos07.response
          ? this.sense.sos07.response
          : null;
      const sos08 =
        this.sense && this.sense.sos08.response
          ? this.sense.sos08.response
          : null;

      const sos07Result = sos07
        ? sos08Responses.find((el) => el.value === sos07).label
        : this.DEFAULT_VALUE;
      const sos08Result = sos08
        ? sos08Responses.find((el) => el.value === sos08).label
        : this.DEFAULT_VALUE;

      return {
        og: sos07Result,
        od: sos08Result,
      };
    },
    tasteSmell() {
      const cond =
        this.general.sg04 && this.general.sg04.response
          ? this.general.sg04.response.split(",")
          : null;

      if (cond && cond.includes("r6") && cond.includes("r7")) {
        return "Perte de goût et perte d'odorat.";
      } else if (cond && cond.includes("r6")) {
        return "Perte de goût. Pas de perte d'odorat. ";
      } else if (cond && cond.includes("r7")) {
        return "Perte d'odorat. Pas de perte de goût.";
      } else if (cond) {
        return "Pas de perte de goût ni d'odorat.";
      }
      return this.DEFAULT_VALUE;
    },
    lostSmellSince() {
      const sos03 = this.sense.sos03.response
        ? this.sense.sos03.response
        : this.DEFAULT_VALUE;
      const cond = this.general.sg04.response;

      if (cond && (cond === "r6" || cond === "r7")) {
        return sos03;
      }

      return "";
    },
    sensibility() {
      const sos04 = this.sense.sos04.response
        ? this.sense.sos04.response
        : this.DEFAULT_VALUE;

      const sensibility = {
        r1: "Perte de sensibilité",
        r2: "Sensation douloureuse",
        r3: "Perte de sensibilité et Sensation douloureuse",
        r4: "Aucune perte ou sensation douloureuse au toucher",
      };

      return sos04 !== this.DEFAULT_VALUE ? sensibility[sos04] : sos04;
    },
    panting() {
      const src01 = this.breathing.src01.response
        ? this.breathing.src01.response
        : this.DEFAULT_VALUE;

      const panting = {
        r1: "Jamais",
        r2: "A l'effort",
        r3: "Au repos",
        r4: "La journée",
        r5: "La nuit",
      };

      return src01 !== this.DEFAULT_VALUE
        ? this.stringifyCheckboxValues(src01, panting)
        : src01;
    },
    sleepingPosition() {
      const src03 = this.breathing.src03.response
        ? this.breathing.src03.response
        : this.DEFAULT_VALUE;
      const position = {
        r1: "Position demi-assise",
        r2: "Pas de position demi-assise",
        r3: "Ne sait pas",
      };
      return src03 !== this.DEFAULT_VALUE ? position[src03] : src03;
    },
    medicalOxygen() {
      const src02 = this.breathing.src02.response
        ? this.breathing.src02.response
        : this.DEFAULT_VALUE;
      const oxygen = {
        r1: "Jamais",
        r2: "La journée",
        r3: "La nuit",
      };
      return src02 !== this.DEFAULT_VALUE
        ? this.stringifyCheckboxValues(src02, oxygen)
        : src02;
    },
    pacemaker() {
      const src04 = this.breathing.src04.response
        ? this.breathing.src04.response
        : this.DEFAULT_VALUE;

      return src04 === "r1"
        ? "Oui"
        : src04 === "r2"
        ? "Non"
        : src04 === "r3"
        ? "NSP"
        : src04;
    },
    lowerEdema() {
      const src05 = this.breathing.src05.response
        ? this.breathing.src05.response
        : this.DEFAULT_VALUE;
      const edema = {
        r1: "Non",
        r2: "En station debout",
        r3: "Au repos",
      };
      return src05 !== this.DEFAULT_VALUE
        ? this.stringifyCheckboxValues(src05, edema)
        : src05;
    },
    raisedLegs() {
      const src06 = this.breathing.src06.response
        ? this.breathing.src06.response
        : this.DEFAULT_VALUE;
      const reponse = {
        r1: "Non",
        r2: "Jambes surelevées",
        r3: "Bas de contention",
        r4: "La journée",
        r5: "La nuit",
      };
      return src06 !== this.DEFAULT_VALUE
        ? this.stringifyCheckboxValues(src06, reponse)
        : src06;
    },
    limbParalysis() {
      const src07 = this.breathing.src07.response
        ? this.breathing.src07.response
        : this.DEFAULT_VALUE;
      const paralysis = {
        r1: "Non",
        r2: "Hémicorps droit",
        r3: "Hémicorps  gauche",
        r4: "Membre supérieur",
        r5: "Membre inférieur",
      };
      return src07 !== this.DEFAULT_VALUE
        ? this.stringifyCheckboxValues(src07, paralysis)
        : src07;
    },
    aphasia() {
      const src08 = this.breathing.src08.response
        ? this.breathing.src08.response
        : this.DEFAULT_VALUE;

      return src08 === "r1"
        ? "Oui"
        : src08 === "r2"
        ? "Non"
        : src08 === "r3"
        ? "NSP"
        : src08;
    },
    stomia() {
      const cond = this.elimination.sfe01.response;
      const sfe01 = cond ? cond : this.DEFAULT_VALUE;
      const sfe02 = this.elimination.sfe02.response
        ? this.elimination.sfe02.response
        : this.DEFAULT_VALUE;
      const sfe03 = this.elimination.sfe03.response
        ? this.elimination.sfe03.response
        : this.DEFAULT_VALUE;
      const sfe03b = this.elimination.sfe03b.response
        ? this.elimination.sfe03b.response
        : this.DEFAULT_VALUE;

      const type = {
        r1: "Urostomie",
        r2: "Ileostomie",
        r3: "Colostomie",
        r4: "NSP",
      };
      const poche = {
        r1: "La personne elle-même",
        r2: "Sa famille",
        r3: "Un SAAD ou un aidant professionnel",
        r4: "Une infirmière libérale ou un Service de soins",
      };

      return {
        display: sfe01 === "r1",
        stomia:
          sfe01 === "r1"
            ? "Oui"
            : sfe01 === "r2"
            ? "Non"
            : sfe01 === "r3"
            ? "NSP"
            : sfe01,
        type:
          cond === "r1"
            ? sfe02 !== this.DEFAULT_VALUE
              ? this.stringifyCheckboxValues(sfe02, type)
              : sfe02
            : null,
        gestion:
          cond === "r1"
            ? `Poche: ${
                sfe03 !== this.DEFAULT_VALUE
                  ? this.stringifyCheckboxValues(sfe03, poche)
                  : this.DEFAULT_VALUE
              };`
            : null,
        support:
          cond === "r1"
            ? `Support:  ${this.stringifyCheckboxValues(sfe03b, poche)} `
            : null,
      };
    },
    sfe07() {
      const sfe07 = this.elimination.sfe07
        ? this.elimination.sfe07.response
        : null;
      const responsesList = [
        { value: "r1", label: "Difficulté a se retenir" },
        { value: "r2", label: "Incontinence" },
        { value: "r3", label: "Brulures à la miction" },
        { value: "r4", label: "Aucun de ces signes" },
      ];
      const sfe07Result = sfe07
        ? responsesList.find((el) => el.value === sfe07).label
        : this.DEFAULT_VALUE;

      return sfe07Result;
    },
    sfe08() {
      const sfe08 =
        this.elimination.sfe08 && this.elimination.sfe08.response
          ? this.elimination.sfe08.response
          : "--";
      const sfe08Result = sfe08.split(",").reduce((acc, answer) => {
        const sfe08Responses = [
          { value: "r1", label: "Aucune" },
          { value: "r2", label: "Protection anatomiques" },
          { value: "r3", label: "Change complet" },
          { value: "r4", label: "La journée" },
          { value: "r5", label: "La nuit" },
        ];
        const resp = sfe08Responses.find((el) => el.value === answer);
        if (resp) {
          return [...acc, resp.label];
        }
        return [...acc];
      }, []);
      return sfe08Result.join(", ");
    },
    sfm11() {
      const sfm11 =
        this.motor.sfm11 && this.motor.sfm11.response
          ? this.motor.sfm11.response
          : "--";
      const responses = {
        r1: "Oui",
        r2: "Non",
        r3: "NSP",
        r4: "Origine vasculaire",
        r5: "Origine neurologique",
        r6: "Origine inconnue ou autre",
      };

      const sfm11Result = sfm11
        .split(",")
        .reduce((acc, answer) => {
          if (responses[answer]) {
            return [...acc, responses[answer]];
          }
          return acc;
        }, [])
        .join(", ");

      return sfm11Result.length > 0 ? sfm11Result : this.DEFAULT_VALUE;
    },
    habitatComment: {
      get() {
        return this.evaluation.evaluatorComments
          ? this.evaluation.evaluatorComments[0]
          : "";
      },
      set(value) {
        let currentState = [...this.evaluation.evaluatorComments];
        currentState[0] = value;
        this.setEvaluationProperty({
          name: "evaluatorComments",
          value: currentState,
        });
      },
    },
    entourageComment: {
      get() {
        return this.evaluation.evaluatorComments
          ? this.evaluation.evaluatorComments[1]
          : "";
      },
      set(value) {
        let currentState = [...this.evaluation.evaluatorComments];
        currentState[1] = value;
        this.setEvaluationProperty({
          name: "evaluatorComments",
          value: currentState,
        });
      },
    },
    autonomyComment: {
      get() {
        return this.evaluation.evaluatorComments
          ? this.evaluation.evaluatorComments[2]
          : "";
      },
      set(value) {
        let currentState = [...this.evaluation.evaluatorComments];
        currentState[2] = value;
        this.setEvaluationProperty({
          name: "evaluatorComments",
          value: currentState,
        });
      },
    },
    aggirTraitement() {
      return this.getAggirAnswerScore(13)
        ? this.getAggirAnswerScore(13)
        : this.DEFAULT_VALUE;
    },
    healthComment: {
      get() {
        return this.evaluation.evaluatorComments
          ? this.evaluation.evaluatorComments.healthComment
          : "";
      },
      set(value) {
        this.evaluation.evaluatorComments.healthComment = value;
      },
    },
    eliminationAggir() {
      const urine = this.evaluation.answerList.find(
        (ans) => ans.question.id === 5
      );
      const faecal = this.evaluation.answerList.find(
        (ans) => ans.question.id === 6
      );

      const descriptionUrine =
        urine && urine.subItemScore
          ? DEFAULT_AGGIR_VALUE.find((c) => c.id === urine.subItemScore)
          : null;
      const descriptionFecal =
        faecal && faecal.subItemScore
          ? DEFAULT_AGGIR_VALUE.find((c) => c.id === faecal.subItemScore)
          : null;
      return {
        urine: urine
          ? `${urine.subItemScore} ${
              descriptionUrine ? `(${descriptionUrine.name})` : ""
            }`
          : this.DEFAULT_VALUE,
        faecal: faecal
          ? `${faecal.subItemScore} ${
              descriptionFecal ? `(${descriptionFecal.name})` : ""
            }`
          : this.DEFAULT_VALUE,
      };
    },
    faecalElimination() {
      const sfe04 = this.elimination.sfe04.response
        ? this.elimination.sfe04.response
        : this.DEFAULT_VALUE;

      const masteries = this.evaluation.answerList
        ? this.evaluation.answerList.find((ans) => ans.question.id === 6)
        : null;
      const stchItems = masteries ? JSON.parse(masteries.answerStch) : null;

      const troubles = {
        r1: "Constipation",
        r2: "Gaz, flatulences",
        r3: "Diarrhées",
        r4: "Maux d'estomac",
        r5: "Parfois",
        r6: "Souvent",
        r7: "Pas de troubles",
      };

      return {
        troubles:
          sfe04 !== this.DEFAULT_VALUE
            ? this.stringifyCheckboxValues(sfe04, troubles)
            : sfe04,
        masteries: `La personne assure ${
          stchItems
            ? stchItems.s
              ? "Non spontanément"
              : "Spontanément"
            : this.DEFAULT_VALUE
        }, T ${
          stchItems
            ? stchItems.t
              ? "Non totalement"
              : "Totalement"
            : this.DEFAULT_VALUE
        }${
          stchItems
            ? stchItems.c
              ? "Non correctement"
              : "Correctement"
            : this.DEFAULT_VALUE
        },${
          stchItems
            ? stchItems.h
              ? "Non habituellement"
              : "Habituellement"
            : this.DEFAULT_VALUE
        }, l'hygiène de son élimination fécale.`,
      };
    },
    urineElimination() {
      const sfe07 = this.elimination.sfe07.response
        ? this.elimination.sfe07.response
        : this.DEFAULT_VALUE;
      const sfe05 = this.elimination.sfe05.response
        ? this.elimination.sfe05.response
        : this.DEFAULT_VALUE;
      const sfe06 = this.elimination.sfe06.response
        ? this.elimination.sfe06.response
        : this.DEFAULT_VALUE;

      const troubles = {
        r1: "Difficulté à se retenir",
        r2: "Incontinence",
        r3: "Brulures à la miction",
        r4: "Aucun de ces signes",
      };

      const gestion = {
        r1: "La personne elle-même",
        r2: "Sa famille",
        r3: "Un SAAD ou un aidant professionnel",
        r4: "Une infirmière libérale ou un Service de soins",
      };

      return {
        troubles: sfe07 !== this.DEFAULT_VALUE ? troubles[sfe07] : sfe07,
        display: sfe05 === "r2" || sfe05 === "r3",
        sonde:
          sfe05 === "r1"
            ? "Non"
            : sfe05 === "r2"
            ? "Sonde vésicale"
            : sfe05 === "r3"
            ? "Étui pénien"
            : "NSP",
        gestion:
          sfe06 !== this.DEFAULT_VALUE
            ? this.stringifyCheckboxValues(sfe06, gestion)
            : sfe06,
      };
    },
    motorFailure() {
      const sfm01 =
        this.motor.sfm01 && this.motor.sfm01.response
          ? this.motor.sfm01.response.split(",")
          : [];
      const stateResponse = {
        r1: "Non",
        r2: "A droite",
        r3: "A gauche",
        r4: "Membre supérieur",
        r5: "Membre inférieur",
        r6: "Faciale",
        r7: "Complet",
        r8: "Incomplet",
        r9: "Origine vasculaire",
        r10: "Origine neurologique",
        r11: "Origine inconnue ou autre",
      };

      const sfm01Result = sfm01
        .reduce((acc, answer) => {
          if (stateResponse[answer]) {
            return [...acc, stateResponse[answer]];
          }
          return acc;
        }, [])
        .join(", ");

      const src07 = this.breathing.src07.response
        ? this.breathing.src07.response
        : this.DEFAULT_VALUE;
      const sfm02 = this.motor.sfm02.response
        ? this.motor.sfm02.response
        : this.DEFAULT_VALUE;

      const paralysis = {
        r1: "Non",
        r2: "Hémicorps droit",
        r3: "Hémicorps  gauche",
        r4: "Membre supérieur",
        r5: "Membre inférieur",
      };

      return {
        state: sfm01Result.length > 0 ? sfm01Result : this.DEFAULT_VALUE,
        type:
          src07 !== this.DEFAULT_VALUE
            ? this.stringifyCheckboxValues(src07, paralysis)
            : src07,
        description: sfm02,
      };
    },
    motorEquipment() {
      const sfm07 = this.motor.sfm07.response
        ? this.motor.sfm07.response
        : this.DEFAULT_VALUE;
      const sfm08 = this.motor.sfm08.response
        ? this.motor.sfm08.response
        : this.DEFAULT_VALUE;
      const sfm06 = this.motor.sfm06.response
        ? this.motor.sfm06.response
        : this.DEFAULT_VALUE;

      return {
        prothesis:
          sfm07 === "r1"
            ? "Oui"
            : sfm07 === "r2"
            ? "Non"
            : sfm07 === "r3"
            ? "NSP"
            : sfm07,
        description: sfm08,
        shoes:
          sfm06 === "r1"
            ? "Oui"
            : sfm06 === "r2"
            ? "Non"
            : sfm06 === "r3"
            ? "NSP"
            : sfm06,
        display: sfm07 === "r1",
      };
    },
    deplacements() {
      const deplInt = this.getAggirAnswerScore(2);
      const deplExt = this.getAggirAnswerScore(16);

      const interpretation = movingInterpretation(
        this.getPeopleStr,
        deplInt,
        deplExt,
        this.getMaterials,
        [19, 20, 21, 22, 23, 24, 25, 26]
      );
      return interpretation ? interpretation : this.DEFAULT_VALUE;
    },
    effortDifficulties() {
      const sfm05 = this.motor.sfm05.response
        ? this.motor.sfm05.response
        : this.DEFAULT_VALUE;
      const difficulties = {
        r1: "Pas du tout",
        r2: "Un peu",
        r3: "Assez",
        r4: "Beaucoup",
        r5: "NSP",
      };
      return sfm05 !== this.DEFAULT_VALUE ? difficulties[sfm05] : sfm05;
    },
    motorTest() {
      const chairTest = chairLift(this.getPeopleStr, this.motor.sfm03.response);
      const equilibreTest = balance(
        this.getPeopleStr,
        this.motor.sfm101.response,
        this.motor.sfm102.response,
        this.motor.sfm103.response
      ).interpretation;
      const walkTest = timeUpAndGo(
        this.getPeopleStr,
        this.motor.sfm04.response
      );

      return {
        chairLiftTest: chairTest,
        equilibreTest: equilibreTest,
        walkTest: walkTest,
      };
    },
    escarus() {
      const spt01 = this.skin.spt01.response
        ? this.skin.spt01.response
        : this.DEFAULT_VALUE;
      const spt02 = this.skin.spt02.response
        ? this.skin.spt02.response
        : this.DEFAULT_VALUE;
      const spt03 = this.skin.spt03.response
        ? this.skin.spt03.response
        : this.DEFAULT_VALUE;

      return {
        atcd:
          spt01 === "r1"
            ? "Oui"
            : spt01 === "r2"
            ? "Non"
            : spt01 === "r3"
            ? "NSP"
            : spt01,
        currentStatus:
          spt02 === "r1"
            ? "Oui"
            : spt02 === "r2"
            ? "Non"
            : spt02 === "r3"
            ? "NSP"
            : spt02,
        localisation: spt03,
        display: spt01 === "r1",
      };
    },
    norton() {
      const norton = nortonCalcul(
        this.getPeopleStr,
        this.getScoreQuestionAggirOrientationTemps,
        this.getScoreQuestionAggirOrientationEspace,
        this.getScoreQuestionAggirCoherenceComportement,
        this.getScoreQuestionAggirCoherenceCommunication,
        this.getScoreQuestionAggirMoveIn,
        this.getScoreQuestionAggirTransfert,
        this.getScoreQuestionAggirEliminationFecale,
        this.getScoreQuestionAggirEliminationUrinaire
      ).interpretation;
      if (norton) {
        return norton;
      }
      return this.DEFAULT_VALUE;
    },
    otherSkinProblems() {
      const spt04 = this.skin.spt04.response
        ? this.skin.spt04.response
        : this.DEFAULT_VALUE;
      const spt05 = this.skin.spt05.response
        ? this.skin.spt05.response
        : this.DEFAULT_VALUE;

      return {
        state:
          spt04 === "r1"
            ? "Oui"
            : spt04 === "r2"
            ? "Non"
            : spt04 === "r3"
            ? "NSP"
            : spt04,
        description: spt05,
        display: spt04 === "r1",
      };
    },
    painDetails() {
      const sd01 = this.pain.sd01.response
        ? this.pain.sd01.response
        : this.DEFAULT_VALUE;
      const sd03 = this.pain.sd03.response
        ? this.pain.sd03.response
        : this.DEFAULT_VALUE;
      const sd04 = this.pain.sd04.response
        ? this.pain.sd04.response
        : this.DEFAULT_VALUE;
      const sd05 = this.pain.sd05.response
        ? this.pain.sd05.response
        : this.DEFAULT_VALUE;
      const sd06 = this.pain.sd06.response
        ? this.pain.sd06.response
        : this.DEFAULT_VALUE;

      const nature = {
        r1: "Occasionnelles",
        r2: "Intermittentes",
        r3: "Permanentes",
        r4: "Le jour",
        r5: "La nuit",
        r6: "Au repos",
        r7: "Lors de la mobilisation",
      };

      return {
        communication: sd01 === "r1" ? "Oui" : sd01 === "r2" ? "Non" : sd01,
        eva: sd03 !== this.DEFAULT_VALUE ? sd03[1] : sd03,
        nature:
          sd04 !== this.DEFAULT_VALUE
            ? this.stringifyCheckboxValues(sd04, nature)
            : sd04,
        manifestation: sd05 === "r1" ? "Oui" : sd05 === "r2" ? "Non" : sd05,
        description: sd06,
      };
    },
    miniCogTest() {
      const miniCog = miniCogCalcul(
        this.getPeopleStr,
        this.brain.smd03.response,
        this.brain.smd02.response
      );
      let total = this.DEFAULT_VALUE;
      let testResult = this.DEFAULT_VALUE;

      if (miniCog) {
        total = miniCog.total !== 0 ? miniCog.total : this.DEFAULT_VALUE;
        testResult = miniCog.interpretation;
      }
      return {
        total: `${total}/5`,
        interpretation: testResult,
      };
    },
    miniGdsTest() {
      return miniGdsCalcul(
        this.getPeopleStr,
        this.brain.smd04 ? this.brain.smd04.response : "",
        this.brain.smd05 ? this.brain.smd05.response : "",
        this.brain.smd06 ? this.brain.smd06.response : "",
        this.brain.smd07 ? this.brain.smd07.response : ""
      ).interpretation;
    },
    miniGdsTestTotal() {
      // Combine the conditionals to check the properties on the brain object
      const smd04Response = this.brain.smd04 ? this.brain.smd04.response : "";
      const smd05Response = this.brain.smd05 ? this.brain.smd05.response : "";
      const smd06Response = this.brain.smd06 ? this.brain.smd06.response : "";
      const smd07Response = this.brain.smd07 ? this.brain.smd07.response : "";

      // Call miniGdsCalcul function with the necessary parameters
      const miniGdsTotal = miniGdsCalcul(
        this.getPeopleStr,
        smd04Response,
        smd05Response,
        smd06Response,
        smd07Response
      ).total;

      // Return the formatted string
      return `${miniGdsTotal}/4`;
    },
    icopeBrain() {
      const icopeDepression = getRSMD05(
        this.brain.smd04.response,
        this.brain.smd05.response
      );

      return {
        cognition: getICOPECognition(
          this.brain.smd03.response,
          this.getScoreQuestionAggirOrientationTemps,
          this.getScoreQuestionAggirOrientationEspace
        ),
        depression: icopeDepression
          ? icopeDepression
          : "Le test n'a pas pu être réalisé",
      };
    },
    brainAggir() {
      const timeOrientation = this.evaluation.answerList.find(
        (ans) => ans.question.id === 21
      );
      const spaceOrientation = this.evaluation.answerList.find(
        (ans) => ans.question.id === 22
      );
      const communication = this.evaluation.answerList.find(
        (ans) => ans.question.id === 23
      );
      const comportement = this.evaluation.answerList.find(
        (ans) => ans.question.id === 24
      );

      const detailTimeOrientation =
        timeOrientation && timeOrientation.subItemScore
          ? DEFAULT_AGGIR_VALUE.find(
              (c) => c.id === timeOrientation.subItemScore
            )
          : null;
      const detailSpaceOrientation =
        spaceOrientation && spaceOrientation.subItemScore
          ? DEFAULT_AGGIR_VALUE.find(
              (c) => c.id === spaceOrientation.subItemScore
            )
          : null;
      const detailCommunication =
        communication && communication.subItemScore
          ? DEFAULT_AGGIR_VALUE.find((c) => c.id === communication.subItemScore)
          : null;
      const detailComportement =
        comportement && comportement.subItemScore
          ? DEFAULT_AGGIR_VALUE.find((c) => c.id === comportement.subItemScore)
          : null;

      return {
        timeOrientation: timeOrientation
          ? `${timeOrientation.subItemScore} ${
              detailTimeOrientation ? `(${detailTimeOrientation.name})` : ""
            }`
          : this.DEFAULT_VALUE,
        spaceOrientation: spaceOrientation
          ? `${spaceOrientation.subItemScore} ${
              detailSpaceOrientation ? `(${detailSpaceOrientation.name})` : ""
            }`
          : this.DEFAULT_VALUE,
        communication: communication
          ? `${communication.subItemScore} ${
              detailCommunication ? `(${detailCommunication.name})` : ""
            }`
          : this.DEFAULT_VALUE,
        comportement: comportement
          ? `${comportement.subItemScore} ${
              detailComportement ? `(${detailComportement.name})` : ""
            }`
          : this.DEFAULT_VALUE,
      };
    },
    drugsDetails() {
      const stsm01 = this.treatment.stsm01.response
        ? this.treatment.stsm01.response
        : this.DEFAULT_VALUE;
      /*const stsm01_bis = this.treatment.stsm01_bis.response ? this.treatment.stsm01_bis.response : this.DEFAULT_VALUE;*/
      const stsm02 = this.treatment.stsm02.response
        ? this.treatment.stsm02.response
        : this.DEFAULT_VALUE;
      const stsm03 = this.treatment.stsm03.response
        ? this.treatment.stsm03.response
        : this.DEFAULT_VALUE;
      const stsm04 = this.treatment.stsm04.response
        ? this.treatment.stsm04.response
        : this.DEFAULT_VALUE;
      const stsm05 = this.treatment.stsm05.response
        ? this.treatment.stsm05.response
        : this.DEFAULT_VALUE;

      const moments = {
        r1: "Matin",
        r2: "Midi",
        r3: "Après midi",
        r4: "Soir",
        r5: "Au coucher",
        r6: "La nuit",
        r7: "NSP",
      };

      const gestion = {
        r1: "Utilisation d'un semainier",
        r2: "Préparé par la personne elle-même",
        r3: "Préparé par une infirmière",
        r4: "Préparé par un membre de la famille",
        r5: "Médicaments pris seul(e)",
        r6: "Aide à la prise par une infirmière",
        r7: "Aide à la prise par un membre de la famille",
        r8: "Aide a la prise par un aidant professionnel",
      };

      const drugs = {
        r1: "0",
        r2: "1",
        r3: "2",
        r4: "3",
        r5: "4",
        r6: "5",
        r7: "5+",
        r8: "NSP",
      };

      return {
        number:
          stsm01 !== this.DEFAULT_VALUE
            ? this.stringifyCheckboxValues(stsm01, drugs)
            : "--",
        moments:
          stsm02 !== this.DEFAULT_VALUE
            ? this.stringifyCheckboxValues(stsm02, moments)
            : stsm02,
        gestion:
          stsm03 !== this.DEFAULT_VALUE
            ? this.stringifyCheckboxValues(stsm03, gestion)
            : stsm03,
        otherDrugs:
          stsm04 === "r1"
            ? "Oui"
            : stsm04 === "r2"
            ? "Non"
            : stsm04 === "r3"
            ? "NSP"
            : stsm04,
        description: stsm05,
      };
    },
    doctorDetails() {
      const stsm06 = this.treatment.stsm06.response
        ? this.treatment.stsm06.response
        : this.DEFAULT_VALUE;
      const stsm07 = this.treatment.stsm07.response
        ? this.treatment.stsm07.response
        : this.DEFAULT_VALUE;
      const stsm08 = this.treatment.stsm08.response
        ? this.treatment.stsm08.response
        : this.DEFAULT_VALUE;

      const frequency = {
        r1: "Chaque semaine",
        r2: "Chaque mois",
        r3: "Plusieurs fois par an",
        r4: "Une fois par an",
        r5: "Jamais",
        r6: "NSP",
      };

      const lastDoctor = {
        r1: "Dentiste",
        r2: "Ophtalmologue",
        r3: "ORL",
        r4: "Autre spécialiste",
        r5: "NSP",
      };

      return {
        frequency: stsm06 !== this.DEFAULT_VALUE ? frequency[stsm06] : stsm06,
        isTraitant:
          stsm07 === "r1"
            ? "Oui"
            : stsm07 === "r2"
            ? "Non"
            : stsm07 === "r3"
            ? "NSP"
            : stsm07,
        lastDoctor:
          stsm08 !== this.DEFAULT_VALUE
            ? this.stringifyCheckboxValues(stsm08, lastDoctor)
            : stsm08,
      };
    },
    healthWorkers() {
      const stsm09 = this.treatment.stsm09.response
        ? this.treatment.stsm09.response
        : this.DEFAULT_VALUE;
      const stsm10 = this.treatment.stsm10.response
        ? this.treatment.stsm10.response
        : this.DEFAULT_VALUE;
      const stsm11 = this.treatment.stsm11.response
        ? this.treatment.stsm11.response
        : this.DEFAULT_VALUE;

      const otherIntervenant = {
        r1: "Pédicure",
        r2: "Kinésithérapeute",
        r3: "Orthophoniste",
        r4: "Psychologue",
        r5: "Aucun de tout cela",
      };

      return {
        visits:
          stsm09 === "r1"
            ? "Oui"
            : stsm09 === "r2"
            ? "Non"
            : stsm09 === "r3"
            ? "NSP"
            : stsm09,
        careType: stsm10,
        otherIntervenant:
          stsm11 !== this.DEFAULT_VALUE
            ? this.stringifyCheckboxValues(stsm11, otherIntervenant)
            : stsm11,
      };
    },
  },
};
</script>
